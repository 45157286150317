const DEFAULT_MEDIA_TYPE = 'application/octet-stream';
const mediaTypes = {
    AUDIO: 'audio/mp4',
    EVENT: 'text/plain',
    GIF: 'image/gif',
    MEME: 'image/jpeg',
    PROMO: 'text/html',
    VIDEO: 'video/mp4',
}

const getMediaTypeFor = (experienceType) => (mediaTypes[experienceType] || DEFAULT_MEDIA_TYPE);

export default {
    getMediaTypeFor,
};
