import React from 'react';
import LatLngField from "../../component/LatLngField";
import StationShowActions from './StationShowActions';
import StationTitle from './StationTitle.js';
import {
    DateField,
    SelectField,
    Show,
    SimpleShowLayout,
    TextField
} from 'react-admin';
import ScalarArrayField from "../../component/ScalarArrayField";

const StationShow = (props) => (
    <Show actions={<StationShowActions/>} title={<StationTitle />} {...props}>
        <SimpleShowLayout>
            <TextField source="code" />
            <SelectField source="type" translateChoice={false} choices={[
                { id: 'BUS', name: 'BUS' },
                { id: 'METRO', name: 'METRO' },
            ]} />
            <TextField source="station" />
            <TextField source="description" />
            <LatLngField source="coordinates" expanded={true} />
            <TextField source="address" />
            <TextField source="location" />
            <TextField source="district" />
            <TextField source="line" />
            <ScalarArrayField source="lines" />
            <ScalarArrayField label="codes" source="codes" code={true} />
            <DateField source="createdAt" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}  />
            <DateField source="updatedAt" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}  />
        </SimpleShowLayout>
    </Show>
);

export default StationShow;
