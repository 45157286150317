import React from 'react';
import IngestShowActions from './IngestShowActions.js';
import IngestTitle from './IngestTitle.js';
import {
    ChipField,
    Show,
    SimpleShowLayout,
    TextField
} from 'react-admin';

const IngestShow = function(props) {
    return (
        <Show actions={<IngestShowActions />} title={<IngestTitle />} {...props}>
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="name" />
                <ChipField source="type" />
                <TextField source="size" />
            </SimpleShowLayout>
        </Show>
    );
};

export default IngestShow;
