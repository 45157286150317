import React from 'react';
import enums from './enums';
import {
    Filter,
    SearchInput,
    SelectInput
} from 'react-admin';

// You can choose whether you use conventional filters or quick filters.
// Users usually dislike using their keyboard to filter a list (especially
// on mobile). A good way to satisfy this user requirement is to turn
// filters into quick filter. A Quick filter is a filter with a non-
// editable defaultValue. Users can only enable or disable them.

// Note it's not possible to use two quick filters for the same source.
// So don't try something like:
//
//  <Filter ...>
//    ...
//    <StatusRejectedQuickFilter />
//    <StatusSuspendedQuickFilter />
//  </Filter>

const ProviderFilter = function(props) {
    return (
        <Filter {...props}>
            <SearchInput source="q" alwaysOn />
            <SelectInput source="type" choices={ enums.experienceType } />
            <SelectInput source="status" choices={ enums.experienceStatus } />
            <SelectInput source="updateMode" choices={ enums.updateMode } />
            <SelectInput source="lastUpdateMode" choices={ enums.updateMode } />
        </Filter>
    );
};

export default ProviderFilter;
