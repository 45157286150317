import React from 'react';
import enums from './enums';
import experienceEnums from '../experience/enums';
import {
    DateTimeInput,
    SelectInput,
    SimpleForm,
    TextInput,
    required,
    minLength,
    maxLength,
    useTranslate, AutocompleteArrayInput
} from 'react-admin';
import LatLngInput from "../../component/LatLngInput";

const validateExternalId = [required(), maxLength(64)];
const validateCode = [required(), minLength(2), maxLength(64)];
const validateStation = [required(), minLength(2), maxLength(127)];
const validateDescription = [maxLength(255)];
const validateAddress = [maxLength(512)];
const validateLocation = [required()];
const validateDistrict = [required()];

const knownTags = [];
const tagChoices = [];
const isTag = tag => tag && (/^[a-zA-Z0-9]{6}$/).test(tag.trim());
const isTagKnown = tag => -1 < knownTags.indexOf(tag);
const tagMatcher = (filter) => isTag(filter) && isTagKnown(filter);
const tagFilter = (filter) => {
    if (isTag(filter) && ! isTagKnown(filter)) {
        knownTags.push(filter);
        tagChoices.push({ id: filter, name: filter });
    }
};

const StationForm = (props) => {
    const translate = useTranslate();

    return (
        <SimpleForm redirect="show" {...props}>
            <TextInput
                source="externalId"
                type="text"
                fullWidth
                validate={validateExternalId}
            />
            <TextInput
                source="code"
                type="text"
                fullWidth
                validate={validateCode}
            />
            <SelectInput
                source="type"
                fullWidth
                choices={ enums.stationType }
            />
            <TextInput
                source="station"
                type="text"
                fullWidth
                helperText={translate('station.help.station')}
                validate={validateStation}
            />
            <TextInput
                source="description"
                type="text"
                fullWidth
                helperText={translate('station.help.station')}
                validate={validateDescription}
            />
            <TextInput
                source="line"
                type="text"
                fullWidth
                helperText={translate('station.help.line')}
            />
            <AutocompleteArrayInput
                source="codes"
                choices={tagChoices}
                setFilter={tagFilter}
                matchSuggestion={tagMatcher}
                fullWidth
                label={translate('station.field.codes')}
                helperText={translate('station.help.codes')}
            />
            <TextInput
                source="address"
                type="text"
                fullWidth
                helperText={translate('station.help.station')}
                validate={validateAddress}
            />
            <TextInput
                source="location"
                type="text"
                fullWidth
                helperText={translate('station.help.location')} validate={validateLocation}
            />
            <SelectInput
                source="district"
                choices={experienceEnums.bcnDistrict}
                fullWidth
                helperText={translate('station.help.district')} validate={validateDistrict}
            />
            <LatLngInput
                source="coordinates"
                type="text"
                fullWidth
                helperText={translate('station.help.station')}
            />
            <DateTimeInput
                source="updatedAt"
                options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}
                helperText={translate('station.help.updatedAt')}
            />
        </SimpleForm>
    );
};

export default StationForm;
