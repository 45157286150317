import React from 'react';
import { useForm } from 'react-final-form';
import { SelectInput } from 'react-admin';
import mediaType from '../../util/mediaType';
import enums from './enums';

const ExperienceTypeInput = () => {
    const form = useForm();
    const onSelectType = (value) => form.change('mediaType', mediaType.getMediaTypeFor(value));

    return (
        <SelectInput source="type" fullWidth choices={ enums.experienceType } onChange={onSelectType} />
    );
};

export default ExperienceTypeInput;
