import React from 'react';
import {
    ListButton,
    RefreshButton,
    TopToolbar,
} from 'react-admin';

const ScanShowActions = ({ basePath }) => (
    <TopToolbar>
        <RefreshButton />
        <ListButton basePath={basePath} />
    </TopToolbar>
);

export default ScanShowActions;
