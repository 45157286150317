import ExperienceIcon from '@material-ui/icons/Label';
import ExperienceCreate from '../experience/ExperienceCreate';
import ExperienceEdit from "../experience/ExperienceEdit";
import ExperienceTabbedShow from "../experience/ExperienceTabbedShow";
import PromoList from "./PromoList";

export default {
    create: ExperienceCreate,
    edit: ExperienceEdit,
    list: PromoList,
    show: ExperienceTabbedShow,
    icon: ExperienceIcon
};
