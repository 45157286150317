import React from 'react';
import { Edit } from 'react-admin';
import SettingTitle from './SettingTitle';
import SettingEditActions from './SettingEditActions';
import SettingForm from './SettingForm';

const SettingEdit = (props) => (
    <Edit actions={<SettingEditActions />} title={<SettingTitle />} {...props}>
        <SettingForm {...props } />
    </Edit>
);

export default SettingEdit;
