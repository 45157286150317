import config from '../config/config';
import fingerprintProvider from './fingerprint';
import { resolveURL, serviceURL } from '../util/uri';
import { fetchJson } from "../util/fetch";

const STORE_KEY_JWT = 'authn.jwt';

const httpClient = (url, options) => {
    const token = localStorage.getItem(STORE_KEY_JWT);

    if (! options.headers) {
        options.headers = new Headers();
    }

    if (token) {
        options.headers.set('Authorization', `JWT ${token}`);
    }

    options.mode = 'cors';

    return fingerprintProvider().then((fingerprint) => {
        options.headers.set('Fingerprint', fingerprint);

        return fetchJson(url, options);
    }).catch(() => {
        return fetchJson(url, options);
    });
};

const getCollectionData = (response) => {
    const { json } = response;

    if (response.status === 204) {
        return {
            data: [],
            total: 0,
        };
    }

    const { items, cursor } = json;

    return {
        data: items,
        total: cursor.total,
    };
};

const getInstanceData = (response = {}) => {
    const { json } = response;

    return { data: json };
};

const initOptions = (resource, params) => {
    const url = serviceURL(resource, params);
    const options = { headers: new Headers() };

    return { url, options };
}

const dataProvider = {
    getList: (resource, params) => {
        if (resource === 'station' && params.filter && params.filter.q) {
            params.filter.description = params.filter.q;
            delete params.filter.q;
        }

        const { url, options } = initOptions(resource, params);

        options.headers.set('Accept', config.negotiation.accept);
        options.headers.set('Accept-Language', config.negotiation.acceptLanguage);
        options.headers.set('Cache-Control', 'no-cache');

        return httpClient(url, options).then((response) => {
            return getCollectionData(response);
        });
    },
    getOne: (resource, params) => {
        const { url, options } = initOptions(resource, params);

        options.headers.set('Accept', config.negotiation.accept);
        options.headers.set('Accept-Language', config.negotiation.acceptLanguage);

        return httpClient(url, options).then((response) => {
            return getInstanceData(response);
        });
    },
    getMany: (resource, params) => {
        const { url, options } = initOptions(resource, params);

        options.headers.set('Accept', config.negotiation.accept);
        options.headers.set('Accept-Language', config.negotiation.acceptLanguage);

        return httpClient(url, options).then((response) => {
            return getCollectionData(response);
        });
    },
    getManyReference: (resource, params) => {
        const { url, options } = initOptions(resource, params);

        options.headers.set('Accept', config.negotiation.accept);
        options.headers.set('Accept-Language', config.negotiation.acceptLanguage);

        return httpClient(url, options).then((response) => {
            return getCollectionData(response);
        });
    },
    create: (resource, params) => {
        const { url, options } = initOptions(resource, params);

        options.method = 'POST';
        options.headers.set('Content-Type', 'application/json');
        options.body = JSON.stringify(params.data);

        return httpClient(url, options).then((response) => {
            const location = response.headers.get('Location');

            if (! location) {
                throw new Error(
                    'Cannot retrieve the Location for the newly created ' +
                    'item, as either the response does not include a ' +
                    '"Location" header, or the CORS policy does not ' +
                    'allow the application to access the "Location" ' +
                    'header.'
                );
            }

            const options = { headers: new Headers() };

            return httpClient(resolveURL(location), options).then((response) => {
                return getInstanceData(response);
            });
        });
    },
    update: (resource, params) => {
        const { url, options } = initOptions(resource, params);

        options.method = 'PUT';
        options.headers.set('Content-Type', 'application/json');
        options.body = JSON.stringify(params.data);

        return httpClient(url, options).then((response) => {
            const options = { headers: new Headers() };

            return httpClient(url, options).then((response) => {
                return getInstanceData(response);
            });
        });
    },
    updateMany: (resource, params) => {
        // Simple-rest doesn't handle filters on UPDATE route, so we
        // fallback to calling UPDATE n times instead
        return Promise.all(params.ids.map((id) => {
            return httpClient(serviceURL(resource, { id, ...params }), {
                method: 'PATCH',
                body: JSON.stringify(params.data),
            });
        })).then((responses) => {
            return {
                data: []
            };
        });
    },
    delete: (resource, params) => {
        const { url, options } = initOptions(resource, params);

        options.method = 'DELETE';

        return httpClient(url, options).then((response) => {
            return getInstanceData(response);
        });
    },
    deleteMany: (resource, params) => {
        // Simple-rest doesn't handle filters on DELETE route, so we
        // fallback to calling DELETE n times instead
        return Promise.all(params.ids.map((id) => {
            return httpClient(serviceURL(resource, { id, ...params }), {
                method: 'DELETE',
            });
        })).then((responses) => {
            return {
                data: responses.map(response => response.json)
            };
        });
    }
};

export default dataProvider;
