import config from "./config";

export default {
    // Use a different root document to set your resource collections,
    // by default it uses the root collections of firestore
    rootRef: '/',

    // Enable logging of react-admin-firebase
    logging: config.debug,

    // Resources to watch for realtime updates, will implicitly watch
    // all resources by default, if not set
    watch: ['posts'],

    // Resources you explicitly dont want realtime updates for
    dontwatch: ['comments']
};
