const experienceType = [
    { id: 'AUDIO', name: 'AUDIO' },
    { id: 'EVENT', name: 'EVENT' },
    { id: 'GIF', name: 'GIF' },
    { id: 'MEME', name: 'MEME' },
    { id: 'PROMO', name: 'PROMO' },
    { id: 'VIDEO', name: 'VIDEO' },
];

const experienceStatus = [
    { id: 'PENDING_MODERATION', name: 'PENDING_MODERATION' },
    { id: 'APPROVED', name: 'APPROVED' },
    { id: 'REJECTED', name: 'REJECTED' },
    { id: 'SUSPENDED', name: 'SUSPENDED' },
];

const updateMode = [
    { id: 'AUTO', name: 'AUTO' },
    { id: 'MANUAL', name: 'MANUAL' },
];

export default {
    experienceType,
    experienceStatus,
    updateMode,
};
