import React from 'react';
import CommentEmpty from './CommentEmpty';
import CommentFilter from './CommentFilter';
import AvatarField from '../../component/AvatarField';
import {
    ChipField,
    Datagrid,
    EditButton,
    List,
    ReferenceField,
    Responsive,
    ShowButton,
    SimpleList,
    TextField
} from 'react-admin';

const defaults = {
    offset: 0,
    limit: 10,
    sort: {
        field: 'id',
        order: 'DESC'
    }
};

const CommentList = (props) => (
    <List empty={<CommentEmpty/>} filters={<CommentFilter />} perPage={defaults.limit} sort={defaults.sort} {...props}>
        <Responsive
            small={
                <SimpleList
                    primaryText={ record => record ? record.id : null }
                    secondaryText={ record => record ? record.id : null }
                    tertiaryText={ record => record ? record.id : null }
                />
            }
            medium={
                <Datagrid>
                    <ReferenceField label="user" source="user.id" reference="user" link="show">
                        <AvatarField source="username" />
                    </ReferenceField>
                    <ReferenceField label="experience" source="experience.id" reference="experience" link="show">
                        <TextField source="id" />
                    </ReferenceField>
                    <TextField source="subject" />
                    <ChipField source="status" />
                    <EditButton />
                    <ShowButton />
                </Datagrid>
            }
        />
    </List>
);

export default CommentList;
