import React from 'react';
import enums from './enums';
import {
    RadioButtonGroupInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    required,
    minLength,
    maxLength,
    useTranslate
} from 'react-admin';

const validateUser = [required()];
const validateExperience = [required()];
const validateSubject = [required(), minLength(2), maxLength(255)];
const validateComment = [required(), maxLength(255)];

const CommentForm = (props) => {
    const translate = useTranslate();

    return (
        <SimpleForm redirect="show" {...props}>
            <ReferenceInput source="user.id" reference="user" isRequired={true}>
                <SelectInput optionText="username" validate={validateUser} />
            </ReferenceInput>
            <ReferenceInput source="experience.id" reference="experience" isRequired={true}>
                <SelectInput optionText="id" validate={validateExperience} />
            </ReferenceInput>
            <TextInput source="subject" type="text" fullWidth helperText={translate('comment.help.subject')} validate={validateSubject} />
            <TextInput source="comment" type="text" fullWidth multiline resettable validate={validateComment} />
            <RadioButtonGroupInput source="status" fullWidth choices={ enums.commentStatus } />
        </SimpleForm>
    );
}

export default CommentForm;
