import React from 'react';
import SurveyEmpty from './SurveyEmpty';
import SurveyFilter from './SurveyFilter';
import {
    ChipField,
    Datagrid,
    List,
    Responsive,
    ShowButton,
    SimpleList,
    TextField
} from 'react-admin';

const defaults = {
    offset: 0,
    limit: 10,
    sort: {
        field: 'id',
        order: 'DESC'
    }
};

const SurveyList = (props) => (
    <List empty={<SurveyEmpty/>} filters={<SurveyFilter />} perPage={defaults.limit} sort={defaults.sort} {...props}>
        <Responsive
            small={
                <SimpleList
                    primaryText={ record => record ? record.title : null }
                    secondaryText={ record => record ? record.hash : null }
                    tertiaryText={ record => record ? record.updatedAt : null }
                />
            }
            medium={
                <Datagrid>
                    <TextField source="title" />
                    <ChipField source="hash" />
                    <ShowButton />
                </Datagrid>
            }
        />
    </List>
);

export default SurveyList;
