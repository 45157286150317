import React from 'react';
import { RefreshButton, TopToolbar } from 'react-admin';

const OptionEditActions = () => (
    <TopToolbar>
        <RefreshButton />
    </TopToolbar>
);

export default OptionEditActions;
