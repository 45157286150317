import React from 'react';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {useListContext} from 'react-admin';
import ScanExperienceCard from "./ScanExperienceCard";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '1em 0',
    },
}));

const ScanGridList = (props) => {
    const classes = useStyles();

    const { ids, data, basePath } = useListContext(props);

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                {
                    ids.map((id) => (
                        <Grid item xs={6}>
                            <ScanExperienceCard record={data[id]} basePath={basePath} />
                        </Grid>
                    ))
                }
            </Grid>
        </div>
    );
};

export default ScanGridList;
