import React from 'react';
import ExperienceShowActions from './ExperienceShowActions';
import ExperienceTitle from './ExperienceTitle.js';
import {
    BooleanField,
    ChipField,
    DateField,
    ImageField,
    NumberField,
    ReferenceField,
    ReferenceArrayField,
    RichTextField,
    SelectField,
    SingleFieldList,
    Show,
    Tab,
    TabbedShowLayout,
    TextField,
    UrlField,
    useTranslate,
} from 'react-admin';
import LatLngField from "../../component/LatLngField";
import ExperienceMediaField from "./ExperienceMediaField";
import ShareLinkField from "../../component/ShareLinkField";
import ScalarArrayField from "../../component/ScalarArrayField";
import DualLangLink from "../../component/DualLangLink";

const ExperienceTabbedShow = (props) => {
    const { record } = props;
    const translate = useTranslate();

    return (
        <Show actions={<ExperienceShowActions/>} title={<ExperienceTitle />} {...props}>
            <TabbedShowLayout>
                <Tab label={translate('experience.group.metadata')}>
                    <ReferenceField label="provider" source="provider.id" reference="provider" link="show">
                        <TextField source="name" />
                    </ReferenceField>
                    <ChipField source="type" />
                    <ChipField source="status" />
                    <TextField source="providerId" label="experience.field.providerId" />
                    <TextField source="phone" />
                    <TextField source="author" />
                    <UrlField source="license" />
                    <DateField source="createdAt" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}  />
                    <DateField source="updatedAt" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}  />
                </Tab>
                <Tab label={translate('experience.group.links')}>
                    <DualLangLink source="link" label="experience.field.link" />
                    <DualLangLink source="externalLink" label="experience.field.externalLink" />
                    <ShareLinkField lang="ca" record={record} />
                    <ShareLinkField lang="es" record={record} />
                </Tab>
                <Tab label={translate('experience.group.content')}>
                    <BooleanField source="topRated" />
                    <BooleanField source="commentsOpen" />
                    <NumberField source="numLikes" />
                    <NumberField source="numDislikes" />
                    <ReferenceField label="category" source="category.id" reference="setting" link="show">
                        <TextField source="key" />
                    </ReferenceField>
                    <TextField source="nameCA" label="experience.field.nameCA" />
                    <TextField source="nameES" label="experience.field.nameES" />
                    <RichTextField source="descriptionCA" label="experience.field.descriptionCA" />
                    <RichTextField source="descriptionES" label="experience.field.descriptionES" />
                    <TextField source="linkLabelCA" label="experience.field.linkLabelCA" />
                    <TextField source="linkLabelES" label="experience.field.linkLabelES" />
                    <ImageField source="posterCA" label="experience.field.posterCA" />
                    <ImageField source="posterES" label="experience.field.posterES" />
                    <TextField source="mediaType" />
                    <ExperienceMediaField source="media" />
                    <ExperienceMediaField source="mediaES" />
                </Tab>
                <Tab label={translate('experience.group.availability')}>
                    <DateField source="notBefore" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}  />
                    <DateField source="expiresAt" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}  />
                    <TextField source="weekdays" />
                    <TextField source="monthdays" />
                    <DateField source="nextDate" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}  />
                </Tab>
                <Tab label={translate('experience.group.release')}>
                    <BooleanField source="forceRelease" />
                    <BooleanField source="releaseConstrained" />
                    <ScalarArrayField source="releaseWithTags" />
                    <ScalarArrayField source="releaseInDistricts" />
                    <ReferenceArrayField reference="releaseInStations" source="station">
                        <SingleFieldList>
                            <ChipField source="description" />
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <ScalarArrayField source="releaseInLines" />
                </Tab>
                <Tab label={translate('experience.group.location')}>
                    <LatLngField label="Coordinates" source="coordinates" expanded={true} />
                    <NumberField source="zoomLevel" textAlign="right" />
                    <TextField source="venue" />
                    <TextField source="address" />
                    <SelectField source="district" translateChoice={false} choices={[
                        { id: 'CIUTAT_VELLA', name: 'CIUTAT_VELLA' },
                        { id: 'EIXAMPLE', name: 'EIXAMPLE' },
                        { id: 'GRACIA', name: 'GRACIA' },
                        { id: 'HORTA_GUINARDO', name: 'HORTA_GUINARDO' },
                        { id: 'LES_CORTS', name: 'LES_CORTS' },
                        { id: 'NOU_BARRIS', name: 'NOU_BARRIS' },
                        { id: 'SANT_ANDREU', name: 'SANT_ANDREU' },
                        { id: 'SANT_MARTI', name: 'SANT_MARTI' },
                        { id: 'SANTS_MONTJUIC', name: 'SANTS_MONTJUIC' },
                        { id: 'SARRIA_SANT_GERVASI', name: 'SARRIA_SANT_GERVASI' },
                    ]} />
                    <TextField source="city" />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export default ExperienceTabbedShow;
