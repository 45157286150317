import React from 'react';
import {
    Filter,
    ReferenceInput,
    SearchInput,
    SelectInput
} from 'react-admin';
import enums from "./enums";

const PosterFilter = function(props) {
    return (
        <Filter {...props}>
            <SearchInput source="q" alwaysOn />
            <SelectInput source="type" choices={ enums.mediaTypes } />
            <ReferenceInput label="user" source="user" reference="user" allowEmpty>
                <SelectInput optionText="id" />
            </ReferenceInput>
        </Filter>
    );
};

export default PosterFilter;
