import React from 'react';
import UserRolesField from './UserRolesField';
import UserShowActions from './UserShowActions';
import UserTitle from './UserRolesField';
import {
    EmailField,
    Show,
    SimpleShowLayout,
    TextField
} from 'react-admin';

const UserShow = function(props) {
    return (
        <Show actions={<UserShowActions />} title={<UserTitle />} {...props}>
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="username" />
                <TextField source="canonicalUsername" />
                <EmailField source="email" />
                <EmailField source="canonicalEmail" />
                <UserRolesField source="roles" />
            </SimpleShowLayout>
        </Show>
    );
};

export default UserShow;
