import ExperienceIcon from '@material-ui/icons/Label';
import EventList from "./EventList";
import ExperienceCreate from '../experience/ExperienceCreate';
import ExperienceEdit from "../experience/ExperienceEdit";
import ExperienceTabbedShow from "../experience/ExperienceTabbedShow";

export default {
    edit: ExperienceEdit,
    list: EventList,
    show: ExperienceTabbedShow,
    create: ExperienceCreate,
    icon: ExperienceIcon
};
