import ScanIcon from '@material-ui/icons/CropFree';
import ScanCreate from './ScanCreate';
import ScanEdit from './ScanEdit';
import ScanList from './ScanList';
import ScanTabbedShow from './ScanTabbedShow';

export default {
    create: ScanCreate,
    edit: ScanEdit,
    list: ScanList,
    show: ScanTabbedShow,
    icon: ScanIcon
};
