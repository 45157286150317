import React from 'react';
import {
    AutocompleteArrayInput,
    BooleanInput,
    DateTimeInput,
    FileField,
    FileInput,
    FormTab,
    FormDataConsumer,
    ImageField,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    SelectArrayInput,
    SelectInput,
    TabbedForm,
    TextInput,
    required,
    minValue,
    maxLength,
    maxValue,
    useTranslate,
} from 'react-admin';
import config from '../../config/config';
import enums from './enums';
import LatLngInput from "../../component/LatLngInput";
import ExperienceTypeInput from "./ExperienceTypeInput";
import RichTextInput from "ra-input-rich-text";
import WeekdaysInput from "../../component/WeekdaysInput";
import SelectLineArrayInput from "../station/SelectLineArrayInput";

const validateProvider = [required()];
const validateProviderId = [required(), maxLength(64)];
const validateMediaType = [required(), maxLength(255)];
const validatePhone = [maxLength(12)];
const validateLink = [maxLength(1024)];
const validateLinkLabel = [maxLength(127)];
const validateAuthor = [maxLength(255)];
const validateLicense = [maxLength(255)];
const validateNameCA = [required(), maxLength(255)];
const validateName = [maxLength(255)];
const validateDescription = [maxLength(1024)];
const validateCategory = [required()];
const validatePoster = [maxLength(1024)];
const validateMedia = [maxLength(1024)];
const validateZoomLevel = [minValue(0), maxValue(25)];
const validateAddressComp = [maxLength(127)];
const validateLikes = [minValue(0)];

const knownTags = [];
const tagChoices = [];
const isTag = tag => tag && (/^[a-zA-Z0-9]{6}$/).test(tag.trim());
const isTagKnown = tag => -1 < knownTags.indexOf(tag);
const tagMatcher = (filter, choice) => isTag(filter) && isTagKnown(filter);
const tagFilter = (filter) => {
    if (isTag(filter) && ! isTagKnown(filter)) {
        knownTags.push(filter);
        tagChoices.push({ id: filter, name: filter });
    }
};

const generateStationLabel = station => {
    if (! station) {
        return '';
    }

    const label = [station.type];

    if (station.line && station.line.length) {
        label.push(`${station.description} (${station.line})`);
    } else if (station.lines && station.lines.length) {
        label.push(`${station.description} (${station.lines.slice(0, 3).join(',')})`);
    } else {
        label.push(station.description);
    }

    if (station.location) {
        label.push(station.location);
    }

    return label.join(', ');
};

const storageUrlToFile = (src) => typeof src === 'string' ? ({ src, title: src }) : src;

const ExperienceTabbedForm = (props) => {
    const translate = useTranslate();

    return (
        <TabbedForm redirect="show" {...props}>
            <FormTab label={translate('experience.group.metadata')}>
                <ExperienceTypeInput />
                <SelectInput source="status" choices={ enums.experienceStatus } />
                <TextInput source="mediaType" type="text" fullWidth helperText={translate('experience.help.mediaType')} validate={validateMediaType} />
                <ReferenceInput source="provider.id" reference="provider" isRequired={true}>
                    <SelectInput optionText="name" validate={validateProvider} />
                </ReferenceInput>
                <TextInput source="providerId" type="text" fullWidth helperText={translate('experience.help.providerId')} label={translate('experience.field.providerId')} validate={validateProviderId} />
                <TextInput source="phone" type="text" fullWidth helperText={translate('experience.help.phone')} validate={validatePhone} />
                <TextInput source="author" type="text" fullWidth helperText={translate('experience.help.author')} validate={validateAuthor} />
                <TextInput source="license" type="text" fullWidth helperText={translate('experience.help.license')} validate={validateLicense} />
                <DateTimeInput source="createdAt" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}  />
                <DateTimeInput source="updatedAt" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}  />
                <BooleanInput source="commentsOpen" />
            </FormTab>
            <FormTab label={translate('experience.group.content')}>
                <BooleanInput source="topRated" />
                <NumberInput source="numLikes" helperText={translate('experience.help.numLikes')} label={translate('experience.field.numLikes')} resettable validate={validateLikes} />
                <NumberInput source="numDislikes" helperText={translate('experience.help.numDislikes')} label={translate('experience.field.numDislikes')} resettable validate={validateLikes} />
                <ReferenceInput source="category.id" reference="setting" isRequired={true}>
                    <SelectInput optionText="key" validate={validateCategory} />
                </ReferenceInput>
                <TextInput source="nameCA" type="text" fullWidth helperText={translate('experience.help.nameCA')} label={translate('experience.field.nameCA')} resettable validate={validateNameCA} />
                <TextInput source="nameES" type="text" fullWidth helperText={translate('experience.help.nameES')} label={translate('experience.field.nameES')} resettable validate={validateName} />
                <RichTextInput source="descriptionCA" helperText={translate('experience.help.descriptionCA')} label={translate('experience.field.descriptionCA')} resettable validate={validateDescription} />
                <RichTextInput source="descriptionES" helperText={translate('experience.help.descriptionES')} label={translate('experience.field.descriptionES')} resettable validate={validateDescription} />
                <TextInput source="link" type="text" fullWidth helperText={translate('experience.help.link')} validate={validateLink} />
                <TextInput source="externalLink" type="text" fullWidth helperText={translate('experience.help.externalLink')} validate={validateLink} />
                <TextInput source="linkLabelCA" type="text" fullWidth helperText={translate('experience.help.linkLabelCA')} label={translate('experience.field.linkLabelCA')} resettable validate={validateLinkLabel} />
                <TextInput source="linkLabelES" type="text" fullWidth helperText={translate('experience.help.linkLabelES')} label={translate('experience.field.linkLabelES')} resettable validate={validateLinkLabel} />
                <FileInput source="posterCA" {...config.image} helperText={translate('experience.help.poster')} label={translate('experience.field.posterCA')} validate={validatePoster} format={storageUrlToFile}>
                    <ImageField source="src" title="title" />
                </FileInput>
                <FileInput source="posterES" {...config.image} helperText={translate('experience.help.poster')} label={translate('experience.field.posterES')} validate={validatePoster} format={storageUrlToFile}>
                    <ImageField source="src" title="title" />
                </FileInput>
                <FileInput source="media" {...config.media} helperText={translate('experience.help.media')} validate={validateMedia} format={storageUrlToFile}>
                    <FileField source="src" title="title" />
                </FileInput>
                <FileInput source="mediaES" {...config.media} helperText={translate('experience.help.mediaES')} validate={validateMedia} format={storageUrlToFile}>
                    <FileField source="src" title="title" />
                </FileInput>
            </FormTab>
            <FormTab label={translate('experience.group.availability')}>
                <DateTimeInput source="notBefore" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />
                <DateTimeInput source="expiresAt" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />
                <WeekdaysInput source="weekdays" fullWidth helperText={translate('experience.help.weekdays')} />
                <TextInput source="monthdays" type="text" fullWidth helperText={translate('experience.help.monthdays')} />
                <DateTimeInput source="nextDate" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} helperText={translate('experience.help.nextDate')} />
            </FormTab>
            <FormTab label={translate('experience.group.release')}>
                <BooleanInput
                    source="forceRelease"
                    fullWidth
                    label={translate('experience.field.forceRelease')}
                    helperText={translate('experience.help.forceRelease')}
                />
                <FormDataConsumer subscription={{ values: true }}>
                    {
                        ({ formData, ...rest }) => (! formData.forceRelease) && (
                            <BooleanInput
                                source="releaseConstrained"
                                fullWidth
                                label={translate('experience.field.releaseConstrained')}
                                helperText={translate('experience.help.releaseConstrained')}
                            />
                        )
                    }
                </FormDataConsumer>
                <FormDataConsumer subscription={{ values: true }}>
                    {
                        ({ formData, ...rest }) => formData.releaseConstrained && (
                            <AutocompleteArrayInput
                                source="releaseWithTags"
                                choices={tagChoices}
                                setFilter={tagFilter}
                                matchSuggestion={tagMatcher}
                                fullWidth
                                label={translate('experience.field.releaseWithTags')}
                                helperText={translate('experience.help.releaseWithTags')}
                            />
                        )
                    }
                </FormDataConsumer>
                <FormDataConsumer subscription={{ values: true }}>
                    {
                        ({ formData, ...rest }) => formData.releaseConstrained && (
                            <SelectArrayInput
                                source="releaseInDistricts"
                                fullWidth
                                choices={enums.bcnDistrict}
                                label={translate('experience.field.releaseInDistricts')}
                                helperText={translate('experience.help.releaseInDistricts')}
                            />
                        )
                    }
                </FormDataConsumer>
                <FormDataConsumer subscription={{ values: true }}>
                    {
                        ({ formData, ...rest }) => formData.releaseConstrained && (
                            <ReferenceArrayInput
                                source="releaseInStations"
                                reference="station"
                                label={translate('experience.field.releaseInStations')}
                                helperText={translate('experience.help.releaseInStations')}
                                fullWidth
                            >
                                <AutocompleteArrayInput
                                    optionText={record => generateStationLabel(record)}
                                />
                            </ReferenceArrayInput>
                        )
                    }
                </FormDataConsumer>
                <FormDataConsumer subscription={{ values: true }}>
                    {
                        ({ formData, ...rest }) => formData.releaseConstrained && (
                            <SelectLineArrayInput
                                source="releaseInLines"
                                fullWidth
                                label={translate('experience.field.releaseInLines')}
                                helperText={translate('experience.help.releaseInLines')}
                            />
                        )
                    }
                </FormDataConsumer>
            </FormTab>
            <FormTab label={translate('experience.group.location')}>
                <LatLngInput source="coordinates" type="text" fullWidth />
                <NumberInput source="zoomLevel" textAlign="right" min={0} max={25} helperText={translate('experience.help.zoomLevel')} validate={validateZoomLevel} />
                <TextInput source="venue" type="text" fullWidth validate={validateAddressComp} />
                <TextInput source="address" type="text" fullWidth validate={validateAddressComp} />
                <SelectInput source="district" choices={ enums.bcnDistrict } />
                <TextInput source="city" type="text" fullWidth validate={validateAddressComp} />
            </FormTab>
        </TabbedForm>
    );
};

export default ExperienceTabbedForm;
