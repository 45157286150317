import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import {useTranslate} from "react-admin";
import {makeStyles} from "@material-ui/core/styles";
import {IconButton, Typography} from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
    },
    field: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
    },
});

const LinkField = ({ label, externalUrl }) => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <div className={classes.root}>
            <Typography
                color="textSecondary"
                variant="caption"
            >
                {translate(label)}
            </Typography>
            {
                externalUrl ? (
                    <div className={classes.field}>
                        <IconButton
                            aria-label={translate('experience.field.externalLink')}
                            href={externalUrl}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <LaunchIcon/>
                        </IconButton>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                            >
                            {externalUrl}
                        </Typography>
                    </div>
                ) : (
                    <Typography
                        color="textPrimary"
                        variant="body1"
                    >
                        {translate('experience.field.noExternalUrl')}
                    </Typography>
                )
            }
        </div>
    );
};

LinkField.propTypes = {
    label: PropTypes.string.isRequired,
    externalUrl: PropTypes.string.isRequired,
};

const DualLangLink = function({ record = {}, label, source }) {
    const classes = useStyles();

    const externalUrl = get(record, source);
    const externalUrlCA = externalUrl && externalUrl.replace('{lang}', 'ca');
    const externalUrlES = externalUrl && externalUrl.replace('{lang}', 'es');

    return record ? (
        <div className={classes.root}>
            <LinkField label={label} externalUrl={externalUrlCA} />
            <LinkField label={label} externalUrl={externalUrlES} />
        </div>
    ) : null;
};

DualLangLink.propTypes = {
    label: PropTypes.string,
    source: PropTypes.string,
};

DualLangLink.defaulProps = {
    label: "resource.field.link",
    source: "link"
};

export default DualLangLink;
