import React from 'react';
import AvatarField from '../../component/AvatarField';
import UpdateShowActions from './UpdateShowActions';
import UpdateTitle from './UpdateTitle.js';
import {
    ChipField,
    DateField,
    NumberField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField
} from 'react-admin';

const UpdateShow = (props) => (
    <Show actions={<UpdateShowActions/>} title={<UpdateTitle />} {...props}>
        <SimpleShowLayout>
            <ReferenceField label="user" source="user.id" reference="user" link="show" allowEmpty>
                <AvatarField source="username" />
            </ReferenceField>
            <ReferenceField label="provider" source="provider.id" reference="provider" link="show">
                <TextField source="name" />
            </ReferenceField>
            <DateField source="startedAt" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}  />
            <DateField source="endAt" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}  />
            <ChipField source="mode" />
            <ChipField source="status" />
            <TextField source="statusText" />
            <NumberField source="experienceCount" textAlign="right" />
        </SimpleShowLayout>
    </Show>
);

export default UpdateShow;
