import React from 'react';
import {
    CloneButton,
    EditButton,
    ListButton,
    RefreshButton,
    TopToolbar,
} from 'react-admin';

const RatingShowActions = ({ basePath, data }) => (
    <TopToolbar>
        <RefreshButton />
        <EditButton basePath={basePath} record={data} />
        <CloneButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} />
    </TopToolbar>
);

export default RatingShowActions;
