const mediaTypes = [
    { id: 'audio/mp3',  name: 'AUDIO' },
    { id: 'image/gif',  name: 'MEME' },
    { id: 'image/jpeg', name: 'IMAGE' },
    { id: 'video/mp4',  name: 'VIDEO' },
];

export default {
    mediaTypes,
};
