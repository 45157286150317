import React from 'react';
import {
    DateTimeInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
} from 'react-admin';

const validatePoster = function(values) {
    const errors = {};

                    
    return errors;
};

const PosterForm = (props) => (
    <SimpleForm redirect="show" validate={ validatePoster } {...props}>
        <ReferenceInput source="user.id" reference="user" allowEmpty>
            <SelectInput optionText="username" resettable />
        </ReferenceInput>
        <TextInput source="src" type="text" fullWidth />
        <TextInput source="type" type="text" fullWidth />
        <NumberInput source="size" textAlign="right"  />
        <TextInput source="name" type="text" fullWidth />
        <TextInput source="keywords" type="text" fullWidth />
        <DateTimeInput source="createdAt" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}  />
    </SimpleForm>
);

export default PosterForm;
