import React from 'react';
import StationCreateToolbar from './StationCreateToolbar';
import StationForm from './StationForm';
import {
    Create
} from 'react-admin';

const stationDefaultValue = {
    id: null,
    code: null,
    type: null,
    station: null,
    description: null,
    coordinates: null,
    createdAt: new Date(),
    updatedAt: new Date(),
};

const StationCreate = (props) => (
    <Create {...props}>
        <StationForm initialValues={stationDefaultValue} toolbar={<StationCreateToolbar />} { ...props } />
    </Create>
);

export default StationCreate;
