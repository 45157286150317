import React from 'react';
import { List } from 'react-admin';
import GridList from '../../component/GridList';

const defaultLimit = 10;
const defaultSort = {
    field: 'id',
    order: 'DESC'
};

const IngestList = function(props) {
    return (
        <List  perPage={defaultLimit} sort={defaultSort} {...props}>
            <GridList/>
        </List>
    );
};

export default IngestList;
