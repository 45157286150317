const a11yBase = [
    { id: 0, name: 'a11y.none' },
    { id: 1, name: 'a11y.hearing' },
    { id: 2, name: 'a11y.visual' },
    { id: 3, name: 'a11y.all' },
];

const a11yLevel = [
    { id: 0, name: 'a11yLevel.none' },
    { id: 1, name: 'a11yLevel.hearing' },
    { id: 2, name: 'a11yLevel.visual' },
    { id: 3, name: 'a11yLevel.all' },
];

const disability = [
    { id: 0, name: 'disability.none' },
    { id: 1, name: 'disability.hearing' },
    { id: 2, name: 'disability.visual' },
    { id: 3, name: 'disability.all' },
];

export {
    a11yBase,
    a11yLevel,
    disability,
}
