import React from 'react';
import { Edit } from 'react-admin';
import StationTitle from './StationTitle';
import StationEditActions from './StationEditActions';
import StationForm from './StationForm';

const StationEdit = (props) => (
    <Edit actions={<StationEditActions />} title={<StationTitle />} {...props}>
        <StationForm {...props } />
    </Edit>
);

export default StationEdit;
