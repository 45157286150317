import SurveyIcon from '@material-ui/icons/QuestionAnswer';
import SurveyCreate from './SurveyCreate';
import SurveyList from './SurveyList';
import SurveyShow from './SurveyShow';

export default {
    create: SurveyCreate,
    list: SurveyList,
    show: SurveyShow,
    icon: SurveyIcon
};
