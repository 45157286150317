import React from 'react';
import Typography from "@material-ui/core/Typography";
import SelectCategoryInput from "../../component/SelectCategoryInput";
import {makeStyles} from "@material-ui/core/styles";
import {
    ArrayInput,
    BooleanInput,
    DateTimeInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    minLength,
    minValue,
    maxLength,
    maxValue,
    required,
    useTranslate,
} from 'react-admin';
import {a11yLevel} from "../../util/a11y";

const useStyles = makeStyles({
    text: {
        width: '100%',
    },
});

const validateScan = function(values) {
    const errors = {};

                
    return errors;
};

const validateUser = [required()];
const validateSubject = [required(), minLength(2), maxLength(255)];
const validateCode = [required(), minLength(2), maxLength(255)];
const validateLocationId = [maxLength(64)];
const validateLocation = [maxLength(255)];
const validateLat = [minValue(0.0), maxValue(90.0)];
const validateLng = [minValue(-180.0), maxValue(180.0)];
const validateA11yLevel = [minValue(0), maxValue(3)];

const ScanForm = (props) => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <SimpleForm redirect="show" validate={ validateScan } {...props}>
            <ReferenceInput source="user.id" reference="user" allowEmpty>
                <SelectInput optionText="username" resettable validate={validateUser} />
            </ReferenceInput>
            <TextInput source="subject" type="text" fullWidth helperText={translate('scan.help.subject')} validate={validateSubject} />
            <TextInput source="code" type="text" fullWidth helperText={translate('scan.help.code')} validate={validateCode} />
            <TextInput source="locationId" type="text" fullWidth helperText={translate('scan.help.locationId')} validate={validateLocationId} />
            <TextInput source="location" type="text" fullWidth helperText={translate('scan.help.location')} validate={validateLocation} />
            <SelectCategoryInput
                fullWidth
                source="setting"
                label={translate('scan.field.setting')}
                helperText={translate('scan.help.setting')}
            />
            <SelectInput
                source="a11yLevel"
                choices={ a11yLevel }
                fullWidth
                label={translate('scan.field.a11yLevel')}
                helperText={translate('scan.help.a11yLevel')}
                validate={validateA11yLevel}
                translateChoices
            />
            <NumberInput source="lat" textAlign="right" min={0} max={90} validate={validateLat} />
            <NumberInput source="lng" textAlign="right" min={-180} max={180} validate={validateLng} />
            <Typography color="textSecondary" variant="body2" className={classes.text}>
                {translate('scan.help.create')}
            </Typography>
            <ArrayInput source="experiences">
                <SimpleFormIterator>
                    { !! (props.record && props.record.id) ? <TextInput disabled source="experienceId" /> : null }
                    { !! (props.record && props.record.id) ? <TextInput disabled source="type" /> : null }
                    <TextInput source="poster" fullWidth type="text" />
                    <TextInput source="link" fullWidth type="text" />
                    <TextInput source="mediaType" fullWidth type="text" />
                    <TextInput source="author" fullWidth type="text" resettable={true} />
                    <TextInput source="license" fullWidth type="text" resettable={true} />
                    <TextInput source="category" fullWidth type="text" resettable={true} />
                    <TextInput source="subcategory" fullWidth type="text" resettable={true} />
                    <TextInput source="tags" fullWidth type="text" resettable={true} />
                    <DateTimeInput source="expiresAt" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}  />
                    <NumberInput source="lat" textAlign="right" min={0} max={90}/>
                    <NumberInput source="lng" textAlign="right" min={-180} max={180}/>
                    <NumberInput source="zoomLevel" textAlign="right" min={0} max={25} />
                    <TextInput source="location" fullWidth type="text" resettable={true} />
                    <NumberInput source="rating" textAlign="right" min={0} max={5}/>
                    <DateTimeInput source="updatedAt" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}  />
                    <BooleanInput source="commentsOpen" />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    );
};

export default ScanForm;
