import React from 'react';
import { SaveButton, Toolbar } from 'react-admin';

const IngestCreateToolbar = function(props) {
    return <Toolbar {...props} >
        <SaveButton label="Ingest" redirect="list" submitOnEnter={true} />
    </Toolbar>
};

export default IngestCreateToolbar;
