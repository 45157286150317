import React from 'react';
import {
    AutocompleteInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    minLength,
    minValue,
    maxLength,
    maxValue,
    required,
    useTranslate
} from 'react-admin';

const validateUser = [required()];
const validateExperience = [required()];
const validateSubject = [required(), minLength(2), maxLength(255)];
const validateRating = [required(), minValue(0.0), maxValue(5.0)];

const RatingForm = (props) => {
    const translate = useTranslate();

    return (
        <SimpleForm redirect="show" {...props}>
            <ReferenceInput source="user.id" reference="user" isRequired={true}>
                <SelectInput optionText="username" validate={validateUser} />
            </ReferenceInput>
            <ReferenceInput source="experience.id" reference="experience" isRequired={true} fullWidth>
                <AutocompleteInput optionText="nameCA" validate={validateExperience} />
            </ReferenceInput>
            <TextInput source="subject" type="text" fullWidth helperText={translate('rating.help.subject')} validate={validateSubject} />
            <NumberInput source="rating" textAlign="right" min={0} max={5} validate={validateRating} />
        </SimpleForm>
    );
};

export default RatingForm;
