import spanishMessages from 'ra-language-spanish';

export default {
    ...spanishMessages,
    a11y: {
        all: 'Accesible para usuarios con discapacidad auditiva o visual',
        hearing: 'Accesible para usuarios con discapacidad auditiva',
        none: 'No accesible para usuarios con discapacidad auditiva y/o visual',
        visual: 'Accesible para usuarios con discapacidad visual',
    },
    a11yLevel: {
        all: 'Usuario con discapacidad auditiva y visual (omitir audio, imágenes e imágenes en movimiento)',
        hearing: 'Usuario con discapacidad auditiva (omitir audio)',
        none: 'Sin restricciones de accesibilidad (devolver cualquier tipo de contenido)',
        visual: 'Usuario con disacapacidad visual (omitir imágenes e imágenes en movimiento)',
    },
    admin: {
        dashboard: 'Dashboard',
        language: 'Lenguaje',
        lastRatings: 'Últimas valoraciones',
        lastScans: 'Últimos escaneos',
        lastUpdates: 'Últimas actualizaciones',
        logout: 'Cerrar sesión',
        settings: 'Ajustes',
        action: {
            back: 'Atrás',
        },
        menu: {
            experience: 'Experiences',
            user: 'Users',
        },
        theme: {
            dark: 'Oscuro',
            light: 'Claro',
            name: 'Tema'
        }
    },
    ingest: {
        field: {
            drop: 'Deja los ficheros aquí o haz click para seleccionar',
            file: 'fichero',
        },
    },
    banner: {
        help: {
            acceptButtonCA: 'Máximo 255 caracteres',
            acceptButtonES: 'Máximo 255 caracteres',
            acceptLinkCA: 'Una URL',
            acceptLinkES: 'Una URL',
            active: 'Activo',
            alias: 'Alias para este banner',
            bgImageCA: 'Imagen de fondo del banner (CA)',
            bgImageES: 'Imagen de fondo del banner (ES)',
            bgImageLink: 'Enlace para la imagen de fondo del banner',
            closeButtonCA: 'Máximo 32 caracteres',
            closeButtonES: 'Máximo 32 caracteres',
            empty: 'Colección vacía',
            fgImageCA: 'Imagen del banner (CA)',
            fgImageES: 'Imagen del banner (ES)',
            htmlCA: 'Máximo 4096 caracteres',
            htmlES: 'Máximo 4096 caracteres',
            useHtml: 'Utilizar HTML o especificar atributos del banner',
        },
        field: {
            acceptButtonCA: 'Etiqueta del botón aceptar (CA)',
            acceptButtonES: 'Etiqueta del botón aceptar (ES)',
            acceptLinkCA: 'Enlace para el botón aceptar (CA)',
            acceptLinkES: 'Enlace para el botón aceptar (ES)',
            active: 'Deja los ficheros aquí o haz click para seleccionar',
            alias: 'Alias',
            bgImageCA: 'Imagen de fondo (CA)',
            bgImageES: 'Imagen de fondo (ES)',
            bgImageLink: 'Enlace para la imagen de fondo',
            closeButtonCA: 'Etiqueta del botón cerrar (CA)',
            closeButtonES: 'Etiqueta del botón cerrar (ES)',
            empty: 'No hay ningún documento en esta colección',
            fgImageCA: 'Imagen principal (CA)',
            fgImageES: 'Imagen principal (ES)',
            htmlCA: 'HTML (CA)',
            htmlES: 'HTML (ES)',
            useHtml: 'Usar HTML',
        },
    },
    comment: {
        field: {
            empty: 'Colección vacía',
            cacheInfo: 'cacheInfo',
            count: 'count',
            geolocation: 'geolocation',
            georules: 'georules',
            supportedHints: 'supportedHints',
            supportedLinks: 'supportedLinks',
            supportedFormats: 'supportedFormats',
            supportedPreferences: 'supportedPreferences',
            rateLimit: 'rateLimit',
            rateLimitInfo: 'rateLimitInfo',
            trackingStatus: 'trackingStatus',
            supportedTunnels: 'supportedTunnels',
            id: 'id',
            clientIp: 'clientIp',
            userAgent: 'userAgent',
            fingerprint: 'fingerprint',
            subject: 'subject',
            comment: 'comment',
            status: 'status',
            createdAt: 'createdAt',
        },
        help: {
            empty: 'No hay ningún documento en esta colección',
            cacheInfo: 'Requerido',
            count: 'Requerido',
            geolocation: 'Requerido',
            georules: 'Requerido',
            supportedHints: 'Requerido',
            supportedLinks: 'Requerido',
            supportedFormats: 'Requerido',
            supportedPreferences: 'Requerido',
            rateLimit: 'Requerido',
            rateLimitInfo: 'Requerido',
            trackingStatus: 'Requerido',
            supportedTunnels: 'Requerido',
            id: 'Requerido',
            clientIp: 'Requerido. Hasta 45 caracteres',
            userAgent: 'Requerido. Hasta 4096 caracteres',
            fingerprint: 'Requerido. Hasta 255 caracteres',
            subject: 'Identificador del usuario de la aplicación (usuario TMB). Requerido. Hasta 255 caracteres',
            comment: 'Requerido. Hasta 255 caracteres',
            status: 'Requerido',
            createdAt: 'Opcional',
        },
    },
    disability: {
        all: 'Auditiva y visual',
        hearing: 'Auditiva',
        none: 'Ninguna',
        visual: 'Visual',
    },
    experience: {
        field: {
            empty: 'Colección vacía',
            cacheInfo: 'cacheInfo',
            count: 'count',
            geolocation: 'geolocation',
            georules: 'georules',
            supportedHints: 'supportedHints',
            supportedLinks: 'supportedLinks',
            supportedFormats: 'supportedFormats',
            supportedPreferences: 'supportedPreferences',
            rateLimit: 'rateLimit',
            rateLimitInfo: 'rateLimitInfo',
            trackingStatus: 'trackingStatus',
            supportedTunnels: 'supportedTunnels',
            id: 'id',
            rnd: 'rnd',
            type: 'type',
            status: 'status',
            link: 'link',
            mediaType: 'mediaType',
            providerId: 'ID específico del proveedor',
            phone: 'phone',
            author: 'author',
            license: 'license',
            poster: 'poster',
            posterCA: 'poster (CA)',
            posterES: 'poster (ES)',
            nameCA: 'name (CA)',
            nameES: 'name (ES)',
            descriptionCA: 'description (CA)',
            descriptionES: 'description (ES)',
            category: 'category',
            notBefore: 'notBefore',
            expiresAt: 'expiresAt',
            weekdays: 'weekdays',
            monthdays: 'monthdays',
            nextDate: 'nextDate',
            coordinates: 'coordinates',
            zoomLevel: 'zoomLevel',
            venue: 'venue',
            address: 'address',
            district: 'district',
            city: 'city',
            rating: 'rating',
            ratingOverride: 'ratingOverride',
            topRated: 'topRated',
            createdAt: 'createdAt',
            updatedAt: 'updatedAt',
            commentsOpen: 'commentsOpen',
            commentsOn: 'Comentarios On',
            commentsOff: 'Comentarios Off',
            approve: 'Aprobar',
            reject: 'Rechazar',
            releaseConstrained: 'Sólo se puede liberar con determinadas etiquetas/en determinad@s distritos/estaciones/líneas',
            releaseWithTags: 'Liberar con estos códigos',
            releaseInDistricts: 'Liberar en estos distritos',
            releaseInLines: 'Liberar en estas líneas',
            releaseInStations: 'Liberar en estas estaciones',
            forceRelease: 'Forzar liberación',
            linkLabelCA: 'Etiqueta para el botón (CA)',
            linkLabelES: 'Etiqueta para el botón (ES)',
            shareUrl: 'URL para compartir',
            numLikes: 'Número de "Me Gusta"',
            numDislikes: 'Número de "No me gusta"',
            externalLink: 'Enlace externo',
            noExternalUrl: 'URL no definida',
        },
        group: {
            availability: 'Disponibilidad',
            release: 'Liberación',
            links: 'Enlaces',
            content: 'Contenido',
            location: 'Ubicación',
            metadata: 'Metadatos',
            rating: 'Valoración',
        },
        help: {
            empty: 'No hay ningún documento en esta colección',
            cacheInfo: 'Requerido',
            count: 'Requerido',
            geolocation: 'Requerido',
            georules: 'Requerido',
            supportedHints: 'Requerido',
            supportedLinks: 'Requerido',
            supportedFormats: 'Requerido',
            supportedPreferences: 'Requerido',
            rateLimit: 'Requerido',
            rateLimitInfo: 'Requerido',
            trackingStatus: 'Requerido',
            supportedTunnels: 'Requerido',
            id: 'Requerido',
            rnd: 'Requerido',
            type: 'Requerido',
            status: 'Requerido',
            link: 'Enlace al contenido real. Requerido. Hasta 1024 caracteres',
            externalLink: 'Enlace adicional con información sobre la experiencia. Opcional. Hasta 1024 caracteres',
            mediaType: 'Tipo de medio del contenido real. Requerido. Hasta 255 caracteres',
            providerId: 'Requerido. Hasta 64 caracteres',
            phone: 'Opcional',
            author: 'Información de autoría. Opcional (debería ser requerido para contenido de terceros). Hasta 255 caracteres',
            license: 'Información de licencia. Opcional (debería ser requerido para contenido de terceros). Hasta 255 caracteres',
            poster: 'Una imagen (JPEG, PNG o WebP), hasta 8 MB, resolución 1125x2436 px',
            media: 'Medios adicionales, hasta 20 MB',
            nameCA: 'Nombre de la experiencia (CA). Hasta 255 caracteres',
            nameES: 'Nombre de la experiencia (ES). Hasta 255 caracteres',
            descriptionCA: 'Descripción de la experiencia (CA). Hasta 255 caracteres',
            descriptionES: 'Descripción de la experiencia (ES). Hasta 255 caracteres',
            category: 'Requerido. La categoría de visualización en la app',
            notBefore: 'Opcional',
            expiresAt: 'Opcional',
            weekdays: 'Una lista separada por comas de días de la semana (1-7). Opcional',
            monthdays: 'Una lista separada por comas de días del mes (1-31). Opcional',
            nextDate: 'Próximo evento. Opcional',
            coordinates: 'Opcional',
            zoomLevel: 'Nivel de zoom (0-25). Opcional',
            venue: 'Opcional. Hasta 127 caracteres',
            address: 'Opcional. Hasta 127 caracteres',
            district: 'Opcional',
            city: 'Opcional. Hasta 127 caracteres',
            rating: 'Opcional',
            ratingOverride: 'Opcional',
            topRated: 'Requerido',
            createdAt: 'Opcional',
            updatedAt: 'Opcional',
            commentsOpen: 'Requerido',
            releaseConstrained: 'Opcional',
            releaseWithTags: 'Opcional',
            releaseInDistricts: 'Opcional',
            releaseInLines: 'Opcional',
            releaseInStations: 'Opcional',
            typeTag: 'Introduce in código de etiqueta alfanumérico de 5 dígitos',
            forceRelease: 'Opcionalmente, forzar la liberación de esta experiencia para todos los usuarios en el próximo escaneo, sin importar el código ni la ubicación',
            numLikes: '',
            numDislikes: '',
        },
        label: {
            poster: 'Seleccionar poster',
            media: 'Seleccionar medio',
            noName: 'El nombre está vacío',
            noDescription: 'La descripción está vacía',
        },
        notification: {
            approveSuccess: 'Experiencias aprobadas',
            approveError: 'Ocurrió un error aprobando las experiencias',
            rejectSuccess: 'Experiencias rechazadas',
            rejectError: 'Ocurrió un error rechazando las experiencias',
            commentsOnSuccess: 'Comentarios habilitados',
            commentsOnError: 'Ocurrió un error habilitando los comentarios',
            commentsOffSuccess: 'Comentarios deshabilitados',
            commentsOffError: 'Ocurrió un error deshabilitando los comentarios ',
        },
    },
    menu: {
        setting: 'Categories',
    },
    option: {
        field: {
            key: 'Clave',
            group: 'Grupo',
            value: 'Valor',
            empty: 'Ninguna opción definida',
            numInitialExperiences: 'Número de experiencias a liberar inicialmente',
            numScanExperiences: 'Número de experiencias a liberar en cada escaneo',
            releaseOptions: 'Opciones de liberación de contenidos',
            title: 'Configuración de la app',
            validationMode: 'Modo de validación',
            validationOptions: 'Opciones de validación',
        },
        help: {
            key: 'El nombre de la opción',
            group: 'El grupo para esta opción',
            value: 'El valor de la opción, como una cadena de texto',
            empty: 'Puedes añadir una opción utilizando el botón Crear',
            numInitialExperiences: 'El número de experiencias a liberar cuando se realiza la solicitud inicial. Como siempre se libera una promo y un evento, este número debe ser mayor o igual a 3.',
            numScanExperiences: 'El número de experiencias a liberar cuando se realiza un escaneo. Como siempre se libera una promo y un evento, este número debe ser mayor o igual a 3.',
            validationMode: 'En el modo de validación no se entregarán enlaces externos',
        },
    },
    poster: {
        field: {
            empty: 'Colección vacía',
            cacheInfo: 'cacheInfo',
            count: 'count',
            geolocation: 'geolocation',
            georules: 'georules',
            supportedHints: 'supportedHints',
            supportedLinks: 'supportedLinks',
            supportedFormats: 'supportedFormats',
            supportedPreferences: 'supportedPreferences',
            rateLimit: 'rateLimit',
            rateLimitInfo: 'rateLimitInfo',
            trackingStatus: 'trackingStatus',
            supportedTunnels: 'supportedTunnels',
            id: 'id',
            src: 'src',
            type: 'type',
            size: 'size',
            name: 'name',
            keywords: 'keywords',
            createdAt: 'createdAt',
        },
        help: {
            empty: 'No hay ningún documento en esta colección',
            cacheInfo: 'Requerido',
            count: 'Requerido',
            geolocation: 'Requerido',
            georules: 'Requerido',
            supportedHints: 'Requerido',
            supportedLinks: 'Requerido',
            supportedFormats: 'Requerido',
            supportedPreferences: 'Requerido',
            rateLimit: 'Requerido',
            rateLimitInfo: 'Requerido',
            trackingStatus: 'Requerido',
            supportedTunnels: 'Requerido',
            id: 'Requerido',
            src: 'Requerido. Hasta 512 caracteres',
            type: 'Requerido. Hasta 255 caracteres',
            size: 'Requerido',
            name: 'Requerido. Hasta 127 caracteres',
            keywords: 'Opcional',
            createdAt: 'Requerido',
        },
        tabs: {
            select: 'Seleccionar poster',
            search: 'Buscar',
            upload: 'Subir',
        },
    },
    provider: {
        field: {
            empty: 'Colección vacía',
            cacheInfo: 'cacheInfo',
            count: 'count',
            geolocation: 'geolocation',
            georules: 'georules',
            supportedHints: 'supportedHints',
            supportedLinks: 'supportedLinks',
            supportedFormats: 'supportedFormats',
            supportedPreferences: 'supportedPreferences',
            rateLimit: 'rateLimit',
            rateLimitInfo: 'rateLimitInfo',
            trackingStatus: 'trackingStatus',
            supportedTunnels: 'supportedTunnels',
            id: 'id',
            enabled: 'enabled',
            name: 'name',
            description: 'description',
            type: 'type',
            status: 'status',
            source: 'source',
            sourceUpdateFrequency: 'sourceUpdateFrequency',
            categories: 'categories',
            updateMode: 'updateMode',
            updateFrequency: 'updateFrequency',
            updateWorker: 'updateWorker',
            experienceCount: 'experienceCount',
            createdAt: 'createdAt',
            updatedAt: 'updatedAt',
            lastUpdatedAt: 'lastUpdatedAt',
            lastUpdateMode: 'lastUpdateMode',
            lastUpdateCount: 'lastUpdateCount',
        },
        help: {
            empty: 'No hay ningún documento en esta colección',
            cacheInfo: 'Requerido',
            count: 'Requerido',
            geolocation: 'Requerido',
            georules: 'Requerido',
            supportedHints: 'Requerido',
            supportedLinks: 'Requerido',
            supportedFormats: 'Requerido',
            supportedPreferences: 'Requerido',
            rateLimit: 'Requerido',
            rateLimitInfo: 'Requerido',
            trackingStatus: 'Requerido',
            supportedTunnels: 'Requerido',
            id: 'Requerido',
            enabled: 'Los proveedores deshabilitados no se actualizan.',
            name: 'Nombre del proveedor. Requerido. Hasta 32 caracteres alfanuméricos.',
            description: 'Descripción textual. Requerido. Hasta 255 caracteres.',
            type: 'Requerido',
            status: 'Estado inicial para la experiencias extraídas de este proveedor.',
            source: 'URL fuente. Requerido si el proveedor se actualiza automáticamente.',
            sourceUpdateFrequency: 'Expresión tipo cron para la frecuencia de actualización de la fuente. La frecuencia de actualización del proveedor más abajo no debería exceder este valor. Dejar en blanco si se desconoce.',
            categories: 'Opcional',
            updateMode: 'Requerido',
            updateFrequency: 'Expresión tipo cron para la frecuencia de actualización del proveedor. Este valor debe ser mayor que la frecuencia de actualización de la fuente. Requerido si el proveedor se actualiza automáticamente.',
            updateWorker: 'Opcional. Hasta 64 caracteres.',
            experienceCount: 'Requerido',
            createdAt: 'Opcional',
            updatedAt: 'Opcional',
            lastUpdatedAt: 'Opcional',
            lastUpdateMode: 'Opcional',
            lastUpdateCount: 'Opcional',
        },
    },
    rating: {
        field: {
            empty: 'Colección vacía',
            cacheInfo: 'cacheInfo',
            count: 'count',
            geolocation: 'geolocation',
            georules: 'georules',
            supportedHints: 'supportedHints',
            supportedLinks: 'supportedLinks',
            supportedFormats: 'supportedFormats',
            supportedPreferences: 'supportedPreferences',
            rateLimit: 'rateLimit',
            rateLimitInfo: 'rateLimitInfo',
            trackingStatus: 'trackingStatus',
            supportedTunnels: 'supportedTunnels',
            id: 'id',
            clientIp: 'clientIp',
            userAgent: 'userAgent',
            fingerprint: 'fingerprint',
            subject: 'subject',
            rating: 'rating',
            createdAt: 'createdAt',
        },
        help: {
            empty: 'No hay ningún documento en esta colección',
            cacheInfo: 'Requerido',
            count: 'Requerido',
            geolocation: 'Requerido',
            georules: 'Requerido',
            supportedHints: 'Requerido',
            supportedLinks: 'Requerido',
            supportedFormats: 'Requerido',
            supportedPreferences: 'Requerido',
            rateLimit: 'Requerido',
            rateLimitInfo: 'Requerido',
            trackingStatus: 'Requerido',
            supportedTunnels: 'Requerido',
            id: 'Requerido',
            clientIp: 'Requerido. Hasta 45 caracteres',
            userAgent: 'Requerido. Hasta 4096 caracteres',
            fingerprint: 'Requerido. Hasta 255 caracteres',
            subject: 'Identificador del usuario de la aplicación (usuario TMB). Requerido. Hasta 255 caracteres',
            rating: 'Requerido',
            createdAt: 'Opcional',
        },
    },
    releaseCause: {
        field: {
            match_scan_code: 'Por etiqueta',
            match_scan_district: 'Por distrito',
            match_scan_line: 'Por línea',
            match_scan_location: 'Por ubicación',
            random: 'Al azar',
            release_forced: 'Forzado',
            top_rated: 'Destacado',
        },
    },
    scan: {
        field: {
            a11yLevel: 'Nivel de accesibilidad',
            empty: 'Colección vacía',
            cacheInfo: 'cacheInfo',
            count: 'count',
            geolocation: 'geolocation',
            georules: 'georules',
            supportedHints: 'supportedHints',
            supportedLinks: 'supportedLinks',
            supportedFormats: 'supportedFormats',
            supportedPreferences: 'supportedPreferences',
            rateLimit: 'rateLimit',
            rateLimitInfo: 'rateLimitInfo',
            trackingStatus: 'trackingStatus',
            supportedTunnels: 'supportedTunnels',
            id: 'id',
            clientIp: 'clientIp',
            userAgent: 'userAgent',
            fingerprint: 'fingerprint',
            setting: 'category',
            subject: 'subject',
            code: 'code',
            locationId: 'locationId',
            location: 'location',
            lat: 'lat',
            lng: 'lng',
            createdAt: 'createdAt',
            expiresAt: 'expiresAt',
            linkToExperience: 'Experiencia Original',
        },
        help: {
            a11yLevel: 'Nivel de accesibilidad',
            empty: 'No hay ningún documento en esta colección',
            cacheInfo: 'Requerido',
            count: 'Requerido',
            create: 'Si no especificas ninguna experiencia a continuación, las experiencias se liberarán automáticamente, de acuerdo al algoritmo de liberación de experiencias (esto es, un evento, una promoción y dos experiencias de entretenimiento). Si especificas alguna experiencia a continuación, no se utilizará el algoritmo de liberación y se liberarán las experiencias especificadas. Si no sabes qué hacer, deja el campo a continuación en blanco.',
            geolocation: 'Requerido',
            georules: 'Requerido',
            supportedHints: 'Requerido',
            supportedLinks: 'Requerido',
            supportedFormats: 'Requerido',
            supportedPreferences: 'Requerido',
            rateLimit: 'Requerido',
            rateLimitInfo: 'Requerido',
            trackingStatus: 'Requerido',
            supportedTunnels: 'Requerido',
            id: 'Requerido',
            clientIp: 'Requerido. Hasta 45 caracteres',
            userAgent: 'Requerido. Hasta 4096 caracteres',
            fingerprint: 'Requerido. Hasta 255 caracteres',
            subject: 'Identificador del usuario de la aplicación (usuario TMB). Requerido. Hasta 255 caracteres',
            code: 'Código DDTag de la etiqueta escaneada. Requerido. Hasta 255 caracteres',
            locationId: 'El identificador de la ubicación (generalmente un identificador de Estación). Hasta 64 caracteres',
            location: 'Una descripción de la ubicación (generalmente, un nombre de Estación). Hasta 255 caracteres',
            lat: 'Opcional',
            lng: 'Opcional',
            setting: 'Opcionalmente, una categoría para la selección de las experiencias de entretenimiento',
            createdAt: 'Opcional',
            expiresAt: 'Opcional',
        },
    },
    selectedExperience: {
        field: {
            empty: 'Colección vacía',
            id: 'id',
            experienceId: 'experienceId',
            type: 'type',
            poster: 'poster',
            link: 'link',
            mediaType: 'mediaType',
            author: 'author',
            license: 'license',
            category: 'category',
            subcategory: 'subcategory',
            tags: 'tags',
            expiresAt: 'expiresAt',
            lat: 'lat',
            lng: 'lng',
            zoomLevel: 'zoomLevel',
            location: 'location',
            rating: 'rating',
            updatedAt: 'updatedAt',
            commentsOpen: 'commentsOpen',
        },
        help: {
            empty: 'No hay ningún documento en esta colección',
            id: 'Requerido',
            experienceId: 'Requerido',
            type: 'Requerido',
            poster: 'Requerido. Hasta 1024 caracteres',
            link: 'Requerido. Hasta 1024 caracteres',
            mediaType: 'Requerido. Hasta 255 caracteres',
            author: 'Opcional. Hasta 255 caracteres',
            license: 'Opcional. Hasta 32 caracteres',
            category: 'Opcional. Hasta 127 caracteres',
            subcategory: 'Opcional. Hasta 127 caracteres',
            tags: 'Opcional',
            expiresAt: 'Opcional',
            lat: 'Opcional',
            lng: 'Opcional',
            zoomLevel: 'Opcional',
            location: 'Opcional. Hasta 127 caracteres',
            rating: 'Opcional',
            updatedAt: 'Opcional',
            commentsOpen: 'Requerido',
        },
    },
    setting: {
        field: {
            a11yLevel: 'Nivel de accesibilidad',
            key: 'Key',
            order: 'Orden',
            nameCA: 'Name (CA)',
            nameES: 'Name (ES)',
            poster: 'Poster',
            upload: 'Deja tus ficheros aquí',
        },
        help: {
            a11yLevel: 'Nivel de accesibilidad de los contenidos de esta categoría',
            key: 'La clave para esta categoría',
            nameCA: 'Requerido. Hasta 255 caracteres',
            nameES: 'Requerido. Hasta 255 caracteres',
            poster: 'Requerido. Una imagen (JPEG, PNG o WebP), hasta 8 MB, resolución 1125x2436 px',
        },
    },
    station: {
        field: {
            empty: 'Colección vacía',
            cacheInfo: 'cacheInfo',
            count: 'count',
            district: 'Distrito',
            geolocation: 'geolocation',
            georules: 'georules',
            supportedHints: 'supportedHints',
            supportedLinks: 'supportedLinks',
            supportedFormats: 'supportedFormats',
            supportedPreferences: 'supportedPreferences',
            rateLimit: 'rateLimit',
            rateLimitInfo: 'rateLimitInfo',
            trackingStatus: 'trackingStatus',
            supportedTunnels: 'supportedTunnels',
            id: 'id',
            code: 'code',
            type: 'type',
            station: 'station',
            description: 'description',
            coordinates: 'coordinates',
            createdAt: 'createdAt',
            updatedAt: 'updatedAt',
            codes: 'Códigos de escaneo',
            line: 'line',
            location: 'location',
        },
        help: {
            empty: 'No hay ningún documento en esta colección',
            cacheInfo: 'Requerido',
            count: 'Requerido',
            district: 'Requerido',
            geolocation: 'Requerido',
            georules: 'Requerido',
            supportedHints: 'Requerido',
            supportedLinks: 'Requerido',
            supportedFormats: 'Requerido',
            supportedPreferences: 'Requerido',
            rateLimit: 'Requerido',
            rateLimitInfo: 'Requerido',
            trackingStatus: 'Requerido',
            supportedTunnels: 'Requerido',
            id: 'Requerido',
            code: 'Requerido. Hasta 64 caracteres',
            type: 'Requerido',
            station: 'Nombre de la estación. Requerido. Hasta 127 caracteres',
            description: 'Descripción textual de la estación. Opcional. Hasta 255 caracteres',
            coordinates: 'Coordenadas geográficas de la estación. Opcional',
            createdAt: 'Opcional',
            updatedAt: 'Opcionalmente, puedes modificar la fecha de edición, con lo que la estación se puede refrescar en la siguiente actualización de la app',
            codes: 'Opcional',
            line: 'Opcional',
            location: 'Opcional',
        },
    },
    survey: {
        field: {
            empty: 'Colección vacía',
            id: 'Id',
            title: 'Título',
            hash: 'Hash',
            updatedAt: 'Última actualización',
        },
        help: {
            empty: 'No hay ningún documento en esta colección',
            create: 'Puedes forzar una actualización manual activando el campo superior y enviando el formulario.',
            id: 'Requerido',
            title: 'Requerido. Hasta 1024 caracteres',
            hash: 'Requerido. Hasta 255 caracteres',
            updatedAt: 'Requerido',
        },
    },
    update: {
        field: {
            empty: 'Colección vacía',
            cacheInfo: 'cacheInfo',
            count: 'count',
            geolocation: 'geolocation',
            georules: 'georules',
            supportedHints: 'supportedHints',
            supportedLinks: 'supportedLinks',
            supportedFormats: 'supportedFormats',
            supportedPreferences: 'supportedPreferences',
            rateLimit: 'rateLimit',
            rateLimitInfo: 'rateLimitInfo',
            trackingStatus: 'trackingStatus',
            supportedTunnels: 'supportedTunnels',
            id: 'id',
            startedAt: 'startedAt',
            endAt: 'endAt',
            mode: 'mode',
            status: 'status',
            statusText: 'statusText',
            experienceCount: 'experienceCount',
            experienceSkipped: 'experienceSkipped',
            experienceFailed: 'experienceFailed',
            runningTime: 'runningTime',
            peakMemory: 'peakMemory',
        },
        help: {
            empty: 'No hay ningún documento en esta colección',
            cacheInfo: 'Requerido',
            count: 'Requerido',
            create: 'Los proveedores anteriores se actualizan automáticamente, pero aquí puedes forzar una actualización manual',
            geolocation: 'Requerido',
            georules: 'Requerido',
            supportedHints: 'Requerido',
            supportedLinks: 'Requerido',
            supportedFormats: 'Requerido',
            supportedPreferences: 'Requerido',
            rateLimit: 'Requerido',
            rateLimitInfo: 'Requerido',
            trackingStatus: 'Requerido',
            supportedTunnels: 'Requerido',
            id: 'Requerido',
            startedAt: 'Opcional',
            endAt: 'Opcional',
            mode: 'Requerido',
            status: 'Requerido',
            statusText: 'Requerido. Hasta 255 caracteres',
            experienceCount: 'Requerido',
            experienceSkipped: 'Requerido',
            experienceFailed: 'Requerido',
            runningTime: 'Opcional',
            peakMemory: 'Opcional',
        },
    },
    weekday: {
        long: {
            mon: 'Lunes',
            tue: 'Martes',
            wed: 'Miércoles',
            thu: 'Jueves',
            fri: 'Viernes',
            sat: 'Sábado',
            sun: 'Domingo',
        },
        short: {
            mon: 'Lu',
            tue: 'Ma',
            wed: 'Mi',
            thu: 'Ju',
            fri: 'Vi',
            sat: 'Sa',
            sun: 'Su',
        },
        min: {
            mon: 'M',
            tue: 'T',
            wed: 'X',
            thu: 'J',
            fri: 'V',
            sat: 'S',
            sun: 'D',
        },
    },
}
