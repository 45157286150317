import React from 'react';
import config from "../../config/config";
import {
    BooleanInput,
    FileInput,
    FormDataConsumer,
    ImageField,
    SimpleForm,
    TextInput,
    maxLength,
    required,
    useTranslate,
} from 'react-admin';

const validateAlias = [required(), maxLength(255)];
const validateButton = [maxLength(32)];
const validateHtml = [maxLength(4096)];
const validateURL = [maxLength(4096)];

const storageUrlToFile = (src) => typeof src === 'string' ? ({ src, title: src }) : src;

const BannerForm = (props) => {
    const translate = useTranslate();

    return (
        <SimpleForm redirect="show" {...props}>
            <BooleanInput
                source="active"
                label={translate("banner.field.active")}
                helperText={translate("banner.help.active")}
            />
            <TextInput 
                source="alias"
                type="text"
                fullWidth
                label={translate('banner.field.alias')}
                helperText={translate('banner.help.alias')}
                validate={validateAlias}
            />
            <BooleanInput
                source="useHtml"
                fullWidth
                label={translate("banner.field.useHtml")}
                helperText={translate("banner.help.useHtml")}
            />
            <FormDataConsumer subscription={{ values: true }}>
                {
                    ({ formData, ...rest}) => ! formData.useHtml && (
                        <>
                            <FileInput
                                source="fgImageCA"
                                {...config.fgImageCA}
                                helperText={translate('banner.help.fgImageCA')}
                                format={storageUrlToFile}
                            >
                                <ImageField
                                    source="src"
                                    title="title"
                                />
                            </FileInput>
                            <FileInput
                                source="fgImageES"
                                {...config.fgImageES}
                                helperText={translate('banner.help.fgImageES')}
                                format={storageUrlToFile}
                            >
                                <ImageField
                                    source="src"
                                    title="title"
                                />
                            </FileInput>
                            <FileInput
                                source="bgImageCA"
                                {...config.image}
                                helperText={translate('banner.help.bgImageCA')}
                                format={storageUrlToFile}
                            >
                                <ImageField
                                    source="src"
                                    title="title"
                                />
                            </FileInput>
                            <FileInput
                                source="bgImageES"
                                {...config.image}
                                helperText={translate('banner.help.bgImageES')}
                                format={storageUrlToFile}
                            >
                                <ImageField
                                    source="src"
                                    title="title"
                                />
                            </FileInput>
                            <TextInput
                                source="bgImageLink"
                                type="text"
                                fullWidth
                                label={translate('banner.field.bgImageLink')}
                                helperText={translate('banner.help.bgImageLink')}
                                validate={validateButton}
                            />
                            <TextInput
                                source="closeButtonCA"
                                type="text"
                                fullWidth
                                label={translate('banner.field.closeButtonCA')}
                                helperText={translate('banner.help.closeButtonCA')}
                                validate={validateButton}
                            />
                            <TextInput
                                source="closeButtonES"
                                type="text"
                                fullWidth
                                label={translate('banner.field.closeButtonES')}
                                helperText={translate('banner.help.closeButtonES')}
                                validate={validateButton}
                            />
                            <TextInput
                                source="acceptButtonCA"
                                type="text"
                                fullWidth
                                label={translate('banner.field.acceptButtonCA')}
                                helperText={translate('banner.help.acceptButtonCA')}
                                validate={validateButton}
                            />
                            <TextInput
                                source="acceptButtonES"
                                type="text"
                                fullWidth
                                label={translate('banner.field.acceptButtonES')}
                                helperText={translate('banner.help.acceptButtonES')}
                                validate={validateButton}
                            />
                            <TextInput
                                source="acceptLinkCA"
                                type="text"
                                fullWidth
                                label={translate('banner.field.acceptLinkCA')}
                                helperText={translate('banner.help.acceptLinkCA')}
                                validate={validateURL}
                            />
                            <TextInput
                                source="acceptLinkES"
                                type="text"
                                fullWidth
                                label={translate('banner.field.acceptLinkES')}
                                helperText={translate('banner.help.acceptLinkES')}
                                validate={validateURL}
                            />
                        </>
                    )
                }
            </FormDataConsumer>
            <FormDataConsumer subscription={{ values: true }}>
                {
                    ({ formData, ...rest}) => formData.useHtml && (
                        <>
                            <TextInput
                                source="htmlCA"
                                type="text"
                                fullWidth
                                multiline
                                label={translate('banner.field.htmlCA')}
                                helperText={translate('banner.help.htmlCA')}
                                validate={validateHtml}
                            />
                            <TextInput
                                source="htmlES"
                                type="text"
                                fullWidth
                                multiline
                                label={translate('banner.field.htmlES')}
                                helperText={translate('banner.help.htmlES')}
                                validate={validateHtml}
                            />
                        </>
                    )
                }
            </FormDataConsumer>
        </SimpleForm>
    );
}

export default BannerForm;
