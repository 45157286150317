import React from 'react';
import UpdateCreateToolbar from './UpdateCreateToolbar';
import UpdateForm from './UpdateForm';
import {
    Create
} from 'react-admin';

const updateDefaultValue = {
    id: null,
    startedAt: new Date(),
    endAt: new Date(),
    mode: null,
    status: null,
    statusText: null,
    experienceCount: 0,
};

const UpdateCreate = (props) => (
    <Create {...props}>
        <UpdateForm initialValues={updateDefaultValue} redirect="list" toolbar={<UpdateCreateToolbar />} { ...props } />
    </Create>
);

export default UpdateCreate;
