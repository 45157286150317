import React from "react";
import PropTypes from "prop-types";
import {
    Avatar,
    Chip,
    Divider,
    Link,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Typography,
    makeStyles
} from "@material-ui/core";
import ScanIcon from '@material-ui/icons/CropFree';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import {blue, blueGrey} from "@material-ui/core/colors";
import {useTranslate} from "react-admin";
import CardWithIcon from "./CardWithIcon";
import {timeAgo} from "../util/time";
import IconButton from "@material-ui/core/IconButton";
import {gmapUrl} from "../util/geo";
import LaunchIcon from "@material-ui/icons/Launch";

const useStyles = makeStyles((theme) => ({
    chip: {
        fontFamily: 'monospace',
    },
    listItemText: {
        overflowY: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
    },
    avatarGroup: {
        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    blue: {
        color: '#fff',
        backgroundColor: blue[500],
    },
    red: {
        color: '#fff',
        backgroundColor: blueGrey[500],
    }
}));

/*
const StyledBadge = withStyles((theme) => ({
    badge: {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}))(Badge);
*/

const scanSecondaryLabel = record => ', ' + record.location;
const scanPrimaryLabel = record => record ? (
    <Chip label={record.code} />
) : null;


const RecentlyScanned = ({ scans = {}, lastScan }) => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <CardWithIcon
            to="/scan"
            icon={ScanIcon}
            title={translate('admin.lastScans')}
            subtitle={timeAgo(lastScan)}
        >
            {
                scans.length ? (
                    <List>
                        {scans.map((record, offset) => (
                            <div>
                                {
                                    offset ? <Divider /> : null
                                }
                                <ListItem
                                    key={record.id}
                                    button
                                    component={Link}
                                    to={`/scan/${record.id}`}
                                    alignItems="flex-start"
                                >
                                    <ListItemText
                                        className={classes.listItemText}
                                        primary={scanPrimaryLabel(record)}
                                        secondary={
                                            <div>
                                                <Typography
                                                    className={classes.inline}
                                                    color="textPrimary"
                                                    component="span"
                                                    variant="body2">
                                                    {record ? record.subject : null}
                                                </Typography>
                                                {scanSecondaryLabel(record)}
                                                <AvatarGroup className={classes.avatarGroup} max={4}>
                                                    {
                                                        record.experiences.map(experience => {
                                                            return (
                                                                <Avatar
                                                                    src={experience.posterCA}
                                                                    alt={experience.type.charAt(0).toUpperCase()}
                                                                />
                                                            );
                                                        })
                                                    }
                                                </AvatarGroup>
                                            </div>
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            edge="end"
                                            aria-label={translate('See on Google Maps')}
                                            href={gmapUrl(record.lat, record.lng)}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <LaunchIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </div>
                        ))}
                    </List>
                ) : null
            }
        </CardWithIcon>
    );
};

RecentlyScanned.propTypes = {
    scans: PropTypes.array,
    lastScan: PropTypes.object,
};

export default RecentlyScanned;
