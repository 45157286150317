import React from 'react';
import { Edit } from 'react-admin';
import PosterTitle from './PosterTitle';
import PosterEditActions from './PosterEditActions';
import PosterForm from './PosterForm';

const PosterEdit = (props) => (
    <Edit actions={<PosterEditActions />} title={<PosterTitle />} {...props}>
        <PosterForm {...props } />
    </Edit>
);

export default PosterEdit;
