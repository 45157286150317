const stationType = [
    { id: 'BUS', name: 'BUS' },
    { id: 'METRO', name: 'METRO' },
];

const bcnDistrict = [
    { id: 'Ciutat Vella', name: 'Ciutat Vella' },
    { id: 'Eixample', name: 'Eixample' },
    { id: 'Gracia', name: 'Gracia' },
    { id: 'Horta-Guinardó', name: 'Horta-Guinardó' },
    { id: 'Les Corts', name: 'Les Corts' },
    { id: 'Nou Barris', name: 'Nou Barris' },
    { id: 'Sant Andreu', name: 'Sant Andreu' },
    { id: 'Sant Martí', name: 'Sant Martí' },
    { id: 'Sants-Montjuïc', name: 'Sants-Montjuïc' },
    { id: 'Sarrià-Sant Gervasi', name: 'Sarrià-Sant Gervasi' },
    { id: 'Sant Adrià de Besòs', name: 'Sant Adrià de Besòs' },
    { id: 'Santa Coloma de Gramenet', name: 'Santa Coloma de Gramenet' },
    { id: 'Cornellà de Llobregat', name: 'Cornellà de Llobregat' },
    { id: 'Badalona', name: 'Badalona' },
    { id: 'Sant Just Desvern', name: 'Sant Just Desvern' },
    { id: 'Sant Joan Despí', name: 'Sant Joan Despí' },
    { id: 'El Prat de Llobregat', name: 'El Prat de Llobregat' },
    { id: 'Montcada i Reixac', name: 'Montcada i Reixac' },
    { id: 'Hospitalet de Llobregat', name: 'Hospitalet de Llobregat' },
    { id: 'Cerdanyola del Vallès', name: 'Cerdanyola del Vallès' },
    { id: 'Sant Cugat del Vallès', name: 'Sant Cugat del Vallès' },
    { id: 'Viladecans', name: 'Viladecans' },
    { id: 'Esplugues de Llobregat', name: 'Esplugues de Llobregat' },
];

export default {
    stationType,
    bcnDistrict,
};
