import React from 'react';
import { Edit } from 'react-admin';
import CommentTitle from './CommentTitle';
import CommentEditActions from './CommentEditActions';
import CommentForm from './CommentForm';

const CommentEdit = (props) => (
    <Edit actions={<CommentEditActions />} title={<CommentTitle />} {...props}>
        <CommentForm {...props } />
    </Edit>
);

export default CommentEdit;
