import React from 'react';
import AvatarField from '../../component/AvatarField';
import PosterShowActions from './PosterShowActions';
import PosterTitle from './PosterTitle.js';
import {
    DateField,
    NumberField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField
} from 'react-admin';

const PosterShow = (props) => (
    <Show actions={<PosterShowActions/>} title={<PosterTitle />} {...props}>
        <SimpleShowLayout>
            <ReferenceField label="user" source="user.id" reference="user" link="show" allowEmpty>
                <AvatarField source="username" />
            </ReferenceField>
            <TextField source="src" />
            <TextField source="type" />
            <NumberField source="size" textAlign="right" />
            <TextField source="name" />
            <TextField source="keywords" />
            <DateField source="createdAt" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}  />
        </SimpleShowLayout>
    </Show>
);

export default PosterShow;
