import React from 'react';
import {
    ListButton,
    RefreshButton,
    TopToolbar,
} from 'react-admin';

const SurveyShowActions = ({ basePath, data }) => (
    <TopToolbar>
        <RefreshButton />
        <ListButton basePath={basePath} />
    </TopToolbar>
);

export default SurveyShowActions;
