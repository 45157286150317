import React from 'react';
import { connect } from 'react-redux';
import { Layout, Sidebar } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import { darkTheme, lightTheme } from '../config/themes';

const CustomSidebar = function(props) {
    return <Sidebar {...props} size={200} />;
};

const CustomLayout = function(props) {
    return <Layout {...props} appBar={AppBar} sidebar={CustomSidebar} menu={Menu} />;
};

export default connect(function(state) {
    return {
        theme: state.theme === 'dark' ? darkTheme : lightTheme
    };
}, {})(CustomLayout);
