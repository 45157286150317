const updateMode = [
    { id: 'AUTO', name: 'AUTO' },
    { id: 'MANUAL', name: 'MANUAL' },
];

const updateStatus = [
    { id: 'STARTED', name: 'STARTED' },
    { id: 'SKIPPED', name: 'SKIPPED' },
    { id: 'FAILED', name: 'FAILED' },
    { id: 'SUCCESS', name: 'SUCCESS' },
];

export default {
updateMode,
updateStatus,
};
