import englishMessages from 'ra-language-english';

export default {
    ...englishMessages,
    a11y: {
        all: 'Accessible for users with hearing or visual disabilities',
        hearing: 'Accessible for users with hearing disabilities',
        none: 'Not accessible for users with hearing and/or visual disabilities',
        visual: 'Accessible for users with visual disabilities',
    },
    a11yLevel: {
        all: 'User with both hearing and visual disabilities (skip audio, image and moving image content)',
        hearing: 'User with hearing disabilities (skip audio content)',
        none: 'No accessibility constraints (return any type of content)',
        visual: 'User with visual disabilities (skip image and moving image content)',
    },
    admin: {
        dashboard: 'Dashboard',
        language: 'Language',
        lastRatings: 'Last User Ratings',
        lastScans: 'Last User Scans',
        lastUpdates: 'Last Provider Updates',
        logout: 'Logout',
        settings: 'Settings',
        action: {
            back: 'Back',
        },
        menu: {
            experience: 'Experiences',
            user: 'Users',
        },
        theme: {
            dark: 'Dark',
            light: 'Light',
            name: 'Theme'
        }
    },
    ingest: {
        field: {
            drop: 'Drop your files here or click to upload',
            file: 'file',
        },
    },
    banner: {
        help: {
            acceptButtonCA: 'Max. 255 characters',
            acceptButtonES: 'Max. 255 characters',
            acceptLinkCA: 'An URL',
            acceptLinkES: 'An URL',
            active: 'Enables or disables this banner',
            alias: 'Alias for this banner',
            bgImageCA: 'Background image (CA)',
            bgImageES: 'Background image (ES)',
            bgImageLink: 'Background image link',
            closeButtonCA: 'Max. 32 characters',
            closeButtonES: 'Max. 32 characters',
            empty: 'No documents in this collection',
            fgImageCA: 'Background image (CA)',
            fgImageES: 'Background image (ES)',
            htmlCA: 'Max. 4096 characters',
            htmlES: 'Max. 4096 characters',
            useHtml: 'Toggle between raw HTML or edit the banner on a field basis',
        },
        field: {
            acceptButtonCA: 'Accept button label (CA)',
            acceptButtonES: 'Accept button label (ES)',
            acceptLinkCA: 'Accept button link (CA)',
            acceptLinkES: 'Accept button link (ES)',
            active: 'Active',
            alias: 'Alias',
            bgImageCA: 'Background Image (CA)',
            bgImageES: 'Background Image (ES)',
            bgImageLink: 'Background Image Link',
            closeButtonCA: 'Close button label (CA)',
            closeButtonES: 'Close button label (ES)',
            empty: 'Empty collection',
            fgImageCA: 'Foreground Image (CA)',
            fgImageES: 'Foreground Image (ES)',
            htmlCA: 'HTML (CA)',
            htmlES: 'HTML (ES)',
            useHtml: 'Use HTML',
        },
    },
    comment: {
        field: {
            empty: 'Empty collection',
            cacheInfo: 'cacheInfo',
            count: 'count',
            geolocation: 'geolocation',
            georules: 'georules',
            supportedHints: 'supportedHints',
            supportedLinks: 'supportedLinks',
            supportedFormats: 'supportedFormats',
            supportedPreferences: 'supportedPreferences',
            rateLimit: 'rateLimit',
            rateLimitInfo: 'rateLimitInfo',
            trackingStatus: 'trackingStatus',
            supportedTunnels: 'supportedTunnels',
            id: 'id',
            clientIp: 'clientIp',
            userAgent: 'userAgent',
            fingerprint: 'fingerprint',
            subject: 'subject',
            comment: 'comment',
            status: 'status',
            createdAt: 'createdAt',
        },
        help: {
            empty: 'No documents in this collection',
            cacheInfo: 'Required',
            count: 'Required',
            geolocation: 'Required',
            georules: 'Required',
            supportedHints: 'Required',
            supportedLinks: 'Required',
            supportedFormats: 'Required',
            supportedPreferences: 'Required',
            rateLimit: 'Required',
            rateLimitInfo: 'Required',
            trackingStatus: 'Required',
            supportedTunnels: 'Required',
            id: 'Required',
            clientIp: 'Required. Up to 45 characters',
            userAgent: 'Required. Up to 4096 characters',
            fingerprint: 'Required. Up to 255 characters',
            subject: 'App user identifier (TMB user, not the CMS user). Required. Up to 255 characters',
            comment: 'Required. Up to 255 characters',
            status: 'Required',
            createdAt: 'Optional',
        },
    },
    disability: {
        all: 'Hearing & Visual Disability',
        hearing: 'Hearing Disability',
        none: 'None',
        visual: 'Visual Disability',
    },
    experience: {
        field: {
            empty: 'Empty collection',
            cacheInfo: 'cacheInfo',
            count: 'count',
            geolocation: 'geolocation',
            georules: 'georules',
            supportedHints: 'supportedHints',
            supportedLinks: 'supportedLinks',
            supportedFormats: 'supportedFormats',
            supportedPreferences: 'supportedPreferences',
            rateLimit: 'rateLimit',
            rateLimitInfo: 'rateLimitInfo',
            trackingStatus: 'trackingStatus',
            supportedTunnels: 'supportedTunnels',
            id: 'id',
            rnd: 'rnd',
            type: 'type',
            status: 'status',
            link: 'Link',
            mediaType: 'Media Type',
            providerId: 'Provider-specific ID',
            phone: 'Phone',
            author: 'Author',
            license: 'License',
            poster: 'Poster',
            posterCA: 'Poster (CA)',
            posterES: 'Poster (ES)',
            nameCA: 'Name (CA)',
            nameES: 'Name (ES)',
            descriptionCA: 'Description (CA)',
            descriptionES: 'Description (ES)',
            category: 'Category',
            notBefore: 'Not Before',
            expiresAt: 'Expires At',
            weekdays: 'Weekdays',
            monthdays: 'Monthdays',
            nextDate: 'Next Date',
            coordinates: 'Coordinates',
            zoomLevel: 'ZoomLevel',
            venue: 'Venue',
            address: 'Address',
            district: 'District',
            city: 'City',
            rating: 'Rating',
            ratingOverride: 'Rating Override',
            topRated: 'Top Rated',
            createdAt: 'Created At',
            updatedAt: 'Updated At',
            commentsOpen: 'Comments Open',
            commentsOn: 'Comments On',
            commentsOff: 'Comments Off',
            approve: 'Approve',
            reject: 'Reject',
            releaseConstrained: 'Experience release is constrained to tags, districts, stations, and lines',
            releaseWithTags: 'Release with these tags',
            releaseInDistricts: 'Release in these districts',
            releaseInLines: 'Release in these lines',
            releaseInStations: 'Release in these stations',
            forceRelease: 'Force release',
            linkLabelCA: 'Link label (CA)',
            linkLabelES: 'Link label (ES)',
            shareUrl: 'Share URL',
            numLikes: 'Number of likes',
            numDislikes: 'Number of dislikes',
            externalLink: 'External link',
            noExternalUrl: '(Blank)',
        },
        group: {
            availability: 'Availability',
            links: 'Links',
            release: 'Release',
            content: 'Content',
            location: 'Location',
            metadata: 'Metadata',
            rating: 'Rating',
        },
        help: {
            empty: 'No documents in this collection',
            cacheInfo: 'Required',
            count: 'Required',
            geolocation: 'Required',
            georules: 'Required',
            supportedHints: 'Required',
            supportedLinks: 'Required',
            supportedFormats: 'Required',
            supportedPreferences: 'Required',
            rateLimit: 'Required',
            rateLimitInfo: 'Required',
            trackingStatus: 'Required',
            supportedTunnels: 'Required',
            id: 'Required',
            rnd: 'Required',
            type: 'Required',
            status: 'Required',
            link: 'The link to the actual content. Required. Up to 1024 characters',
            externalLink: 'An external link with additional info for this experience. Optional. Up to 1024 characters',
            mediaType: 'The actual content media type. Required. Up to 255 characters',
            providerId: 'Required. Up to 64 characters',
            phone: 'Optional',
            author: 'Authoring info. Optional (should be required for third-party content). Up to 255 characters',
            license: 'License info. Optional (should be required for third-party content). Up to 255 characters',
            poster: 'An image file (JPEG, PNG or WebP), up to 8 MB, minimum resolution 1125x2436 px',
            posterCA: 'An image file (JPEG, PNG or WebP), up to 8 MB, minimum resolution 1125x2436 px',
            posterES: 'An image file (JPEG, PNG or WebP), up to 8 MB, minimum resolution 1125x2436 px',
            media: 'A media file, up to 20 MB, up to you',
            nameCA: 'Experience name (CA). Up to 255 characters',
            nameES: 'Experience name (ES). Up to 255 characters',
            descriptionCA: 'Experience description (CA). Up to 1024 characters',
            descriptionES: 'Experience description (ES). Up to 1024 characters',
            category: 'Required. The display category for this experience',
            notBefore: 'Optional',
            expiresAt: 'Optional',
            weekdays: 'A comma-separated list of weekday numbers (1-7). Optional',
            monthdays: 'A comma-separated list of day numbers (1-31). Optional',
            nextDate: 'Next event date. Optional',
            coordinates: 'Optional',
            zoomLevel: 'Zoom level (0-25). Optional',
            venue: 'Optional. Up to 127 characters',
            address: 'Optional. Up to 127 characters',
            district: 'Optional',
            city: 'Optional. Up to 127 characters',
            rating: 'Optional',
            ratingOverride: 'Optional',
            topRated: 'Required',
            createdAt: 'Optional',
            updatedAt: 'Optional',
            commentsOpen: 'Required',
            releaseConstrained: 'Optionally, constraint the release of this experience to a list of tags, districts, stations or lines. Release by district is only available for BUS stations. Release by line is only available for METRO stations',
            releaseWithTags: 'Optional',
            releaseInDistricts: 'Optional',
            releaseInLines: 'Optional',
            releaseInStations: 'Optional',
            typeTag: 'Type a 5 letter alphanumeric tag code',
            forceRelease: 'Optionally, force release on next scan for all users, no matter the scan code nor the location',
            numLikes: '',
            numDislikes: '',
        },
        label: {
            poster: 'Select poster image',
            media: 'Select experience media',
            noName: 'Name is empty',
            noDescription: 'Description is empty',
        },
        notification: {
            approveSuccess: 'All experiences approved',
            approveError: 'An error occurred while approving experiences',
            rejectSuccess: 'All experiences rejected',
            rejectError: 'An error occurred while rejecting experiences',
            commentsOnSuccess: 'Comments enabled',
            commentsOnError: 'An error occurred while enabling comments',
            commentsOffSuccess: 'Comments disabled',
            commentsOffError: 'An error occurred while disabling comments',
        },
    },
    menu: {
        setting: 'Categories',
    },
    option: {
        field: {
            key: 'Key',
            group: 'Group',
            value: 'Value',
            empty: 'No options defined',
            numInitialExperiences: 'Number of experiences to release in the initial grant',
            numScanExperiences: 'Number of experiences to release per scan',
            releaseOptions: 'Release options',
            title: 'App configuration',
            validationMode: 'Validation mode',
            validationOptions: 'Validation options',
        },
        help: {
            key: 'The key for this option',
            group: 'The option group',
            value: 'The option value, as a string',
            empty: 'You can create a new option using the Create button below',
            numInitialExperiences: 'The number of experiences to release when the initial grant request is made. . As an event and a promo are always released, this number should be greater or equal to 3',
            numScanExperiences: 'The number of experiences to release in each scan. As an event and a promo are always released, this number should be greater or equal to 3',
            validationMode: 'No links will be delivered when validation mode is on',
        },
    },
    poster: {
        field: {
            empty: 'Empty collection',
            cacheInfo: 'cacheInfo',
            count: 'count',
            geolocation: 'geolocation',
            georules: 'georules',
            supportedHints: 'supportedHints',
            supportedLinks: 'supportedLinks',
            supportedFormats: 'supportedFormats',
            supportedPreferences: 'supportedPreferences',
            rateLimit: 'rateLimit',
            rateLimitInfo: 'rateLimitInfo',
            trackingStatus: 'trackingStatus',
            supportedTunnels: 'supportedTunnels',
            id: 'id',
            src: 'src',
            type: 'type',
            size: 'size',
            name: 'name',
            keywords: 'keywords',
            createdAt: 'createdAt',
        },
        help: {
            empty: 'No documents in this collection',
            cacheInfo: 'Required',
            count: 'Required',
            geolocation: 'Required',
            georules: 'Required',
            supportedHints: 'Required',
            supportedLinks: 'Required',
            supportedFormats: 'Required',
            supportedPreferences: 'Required',
            rateLimit: 'Required',
            rateLimitInfo: 'Required',
            trackingStatus: 'Required',
            supportedTunnels: 'Required',
            id: 'Required',
            src: 'Required. Up to 512 characters',
            type: 'Required. Up to 255 characters',
            size: 'Required',
            name: 'Required. Up to 127 characters',
            keywords: 'Optional',
            createdAt: 'Required',
        },
        tabs: {
            select: 'Select poster',
            search: 'Search',
            upload: 'Upload',
        },
    },
    provider: {
        field: {
            empty: 'Empty collection',
            cacheInfo: 'cacheInfo',
            count: 'count',
            geolocation: 'geolocation',
            georules: 'georules',
            supportedHints: 'supportedHints',
            supportedLinks: 'supportedLinks',
            supportedFormats: 'supportedFormats',
            supportedPreferences: 'supportedPreferences',
            rateLimit: 'rateLimit',
            rateLimitInfo: 'rateLimitInfo',
            trackingStatus: 'trackingStatus',
            supportedTunnels: 'supportedTunnels',
            id: 'id',
            enabled: 'enabled',
            name: 'name',
            description: 'description',
            type: 'type',
            status: 'status',
            source: 'source',
            sourceUpdateFrequency: 'sourceUpdateFrequency',
            categories: 'categories',
            updateMode: 'updateMode',
            updateFrequency: 'updateFrequency',
            updateWorker: 'updateWorker',
            experienceCount: 'experienceCount',
            createdAt: 'createdAt',
            updatedAt: 'updatedAt',
            lastUpdatedAt: 'lastUpdatedAt',
            lastUpdateMode: 'lastUpdateMode',
            lastUpdateCount: 'lastUpdateCount',
        },
        help: {
            empty: 'No documents in this collection',
            cacheInfo: 'Required',
            count: 'Required',
            geolocation: 'Required',
            georules: 'Required',
            supportedHints: 'Required',
            supportedLinks: 'Required',
            supportedFormats: 'Required',
            supportedPreferences: 'Required',
            rateLimit: 'Required',
            rateLimitInfo: 'Required',
            trackingStatus: 'Required',
            supportedTunnels: 'Required',
            id: 'Required',
            enabled: 'Whether provider is enabled. Disabled providers won\'t be updated',
            name: 'Provider name. Required. Up to 32 alphanumeric characters',
            description: 'Provider human-readable description. Required. Up to 255 characters',
            type: 'Required',
            status: 'Initial status for experiences retrieved from this provider',
            source: 'Source URL. Required if updated automatically (update mode is AUTO)',
            sourceUpdateFrequency: 'Cron-like expression specifying the source update frequency. The provider update frequency below should not exceed this value. Leave this blank if unknown.',
            categories: 'Optional',
            updateMode: 'Required',
            updateFrequency: 'Cron-like expression specifying the provider update frequency. This value should be greater than the source update frequency. Required if updated automatically (update mode is AUTO)',
            updateWorker: 'Optional. Up to 64 characters',
            experienceCount: 'Required',
            createdAt: 'Optional',
            updatedAt: 'Optional',
            lastUpdatedAt: 'Optional',
            lastUpdateMode: 'Optional',
            lastUpdateCount: 'Optional',
        },
    },
    rating: {
        field: {
            empty: 'Empty collection',
            cacheInfo: 'cacheInfo',
            count: 'count',
            geolocation: 'geolocation',
            georules: 'georules',
            supportedHints: 'supportedHints',
            supportedLinks: 'supportedLinks',
            supportedFormats: 'supportedFormats',
            supportedPreferences: 'supportedPreferences',
            rateLimit: 'rateLimit',
            rateLimitInfo: 'rateLimitInfo',
            trackingStatus: 'trackingStatus',
            supportedTunnels: 'supportedTunnels',
            id: 'id',
            clientIp: 'clientIp',
            userAgent: 'userAgent',
            fingerprint: 'fingerprint',
            subject: 'subject',
            rating: 'rating',
            createdAt: 'createdAt',
        },
        help: {
            empty: 'No documents in this collection',
            cacheInfo: 'Required',
            count: 'Required',
            geolocation: 'Required',
            georules: 'Required',
            supportedHints: 'Required',
            supportedLinks: 'Required',
            supportedFormats: 'Required',
            supportedPreferences: 'Required',
            rateLimit: 'Required',
            rateLimitInfo: 'Required',
            trackingStatus: 'Required',
            supportedTunnels: 'Required',
            id: 'Required',
            clientIp: 'Required. Up to 45 characters',
            userAgent: 'Required. Up to 4096 characters',
            fingerprint: 'Required. Up to 255 characters',
            subject: 'App user identifier (TMB user, not the CMS user). Required. Up to 255 characters',
            rating: 'Required',
            createdAt: 'Optional',
        },
    },
    releaseCause: {
        field: {
            match_scan_code: 'Matches scan code',
            match_scan_district: 'Matches scan district',
            match_scan_line: 'Matches scan station line',
            match_scan_location: 'Matches location',
            random: 'Choosen at random',
            release_forced: 'Release was forced',
            top_rated: 'Top Rated',
        },
    },
    scan: {
        field: {
            a11yLevel: 'Accessibility level',
            empty: 'Empty collection',
            cacheInfo: 'cacheInfo',
            count: 'count',
            geolocation: 'geolocation',
            georules: 'georules',
            supportedHints: 'supportedHints',
            supportedLinks: 'supportedLinks',
            supportedFormats: 'supportedFormats',
            supportedPreferences: 'supportedPreferences',
            rateLimit: 'rateLimit',
            rateLimitInfo: 'rateLimitInfo',
            trackingStatus: 'trackingStatus',
            supportedTunnels: 'supportedTunnels',
            id: 'id',
            clientIp: 'clientIp',
            userAgent: 'userAgent',
            fingerprint: 'fingerprint',
            setting: 'category',
            subject: 'subject',
            code: 'code',
            locationId: 'locationId',
            location: 'location',
            lat: 'lat',
            lng: 'lng',
            createdAt: 'createdAt',
            expiresAt: 'expiresAt',
            linkToExperience: 'See Original Experience',
        },
        help: {
            a11yLevel: 'Accessibility level',
            empty: 'No documents in this collection',
            cacheInfo: 'Required',
            count: 'Required',
            create: 'If you do not specify any experience below, experiences will be automatically granted and released for this scan, according to the experience grating algorithm (that is, an event, a promotion, and two entertainment experiences). If you specify any experience below, the experience granting algorithm won\'t be called, and the specified experiences will be released instead. If you don\'t know what to do, leave the following field blank.',
            geolocation: 'Required',
            georules: 'Required',
            supportedHints: 'Required',
            supportedLinks: 'Required',
            supportedFormats: 'Required',
            supportedPreferences: 'Required',
            rateLimit: 'Required',
            rateLimitInfo: 'Required',
            trackingStatus: 'Required',
            supportedTunnels: 'Required',
            id: 'Required',
            clientIp: 'Required. Up to 45 characters',
            userAgent: 'Required. Up to 4096 characters',
            fingerprint: 'Required. Up to 255 characters',
            subject: 'App user identifier (TMB user, not the CMS user). Required. Up to 255 characters',
            code: 'The code for the scanned DDTag. Required. Up to 255 characters',
            locationId: 'The location identifier (most often, a Station identifier). Up to 64 characters',
            location: 'The location descriptive name (most often, a Station name). Up to 255 characters',
            lat: 'Optional',
            lng: 'Optional',
            setting: 'Optionally, a category to select entertainment experiences from',
            createdAt: 'Optional',
            expiresAt: 'Optional',
        },
    },
    selectedExperience: {
        field: {
            empty: 'Empty collection',
            id: 'id',
            experienceId: 'experienceId',
            type: 'type',
            poster: 'poster',
            link: 'link',
            mediaType: 'mediaType',
            author: 'author',
            license: 'license',
            category: 'category',
            subcategory: 'subcategory',
            tags: 'tags',
            expiresAt: 'expiresAt',
            lat: 'lat',
            lng: 'lng',
            zoomLevel: 'zoomLevel',
            location: 'location',
            rating: 'rating',
            updatedAt: 'updatedAt',
            commentsOpen: 'commentsOpen',
        },
        help: {
            empty: 'No documents in this collection',
            id: 'Required',
            experienceId: 'Required',
            type: 'Required',
            poster: 'Required. Up to 1024 characters',
            link: 'Required. Up to 1024 characters',
            mediaType: 'Required. Up to 255 characters',
            author: 'Optional. Up to 255 characters',
            license: 'Optional. Up to 32 characters',
            category: 'Optional. Up to 127 characters',
            subcategory: 'Optional. Up to 127 characters',
            tags: 'Optional',
            expiresAt: 'Optional',
            lat: 'Optional',
            lng: 'Optional',
            zoomLevel: 'Optional',
            location: 'Optional. Up to 127 characters',
            rating: 'Optional',
            updatedAt: 'Optional',
            commentsOpen: 'Required',
        },
    },
    setting: {
        field: {
            a11yLevel: 'A11y Level',
            key: 'Key',
            order: 'Order',
            nameCA: 'Name (CA)',
            nameES: 'Name (ES)',
            poster: 'Poster',
            upload: 'Drop your files here',
        },
        help: {
            a11yLevel: 'Accessibility level for experiences in this category',
            key: 'The key associated with an app section',
            nameCA: 'Required. Up to 255 characters',
            nameES: 'Required. Up to 255 characters',
            poster: 'Required. An image file (JPEG, PNG or WebP), up to 8 MB, minimum resolution 1125x2436 px',
        },
    },
    station: {
        field: {
            empty: 'Empty collection',
            cacheInfo: 'cacheInfo',
            count: 'count',
            district: 'District',
            geolocation: 'geolocation',
            georules: 'georules',
            supportedHints: 'supportedHints',
            supportedLinks: 'supportedLinks',
            supportedFormats: 'supportedFormats',
            supportedPreferences: 'supportedPreferences',
            rateLimit: 'rateLimit',
            rateLimitInfo: 'rateLimitInfo',
            trackingStatus: 'trackingStatus',
            supportedTunnels: 'supportedTunnels',
            id: 'id',
            code: 'code',
            type: 'type',
            station: 'station',
            description: 'description',
            coordinates: 'coordinates',
            createdAt: 'createdAt',
            updatedAt: 'updatedAt',
            codes: 'Scan Codes',
            line: 'line',
            location: 'location',
        },
        help: {
            empty: 'No documents in this collection',
            cacheInfo: 'Required',
            count: 'Required',
            district: 'Required',
            geolocation: 'Required',
            georules: 'Required',
            supportedHints: 'Required',
            supportedLinks: 'Required',
            supportedFormats: 'Required',
            supportedPreferences: 'Required',
            rateLimit: 'Required',
            rateLimitInfo: 'Required',
            trackingStatus: 'Required',
            supportedTunnels: 'Required',
            id: 'Required',
            code: 'Required. Up to 64 characters',
            type: 'Required',
            station: 'The station name. Required. Up to 127 characters',
            description: 'The station description. Optional. Up to 255 characters',
            coordinates: 'The geographic coordinates for this station. Optional',
            createdAt: 'Optional',
            updatedAt: 'Optionally, you can change the station last update time, so it may be refreshed on the next app update',
            codes: 'Optional',
            line: 'Optional',
            location: 'Optional',
        },
    },
    survey: {
        field: {
            empty: 'Empty collection',
            id: 'Id',
            title: 'Title',
            hash: 'Hash',
            updatedAt: 'Updated At',
        },
        help: {
            empty: 'No documents in this collection',
            create: 'You can force a manual update activating the switch above and sending the form.',
            id: 'Required',
            title: 'Required. Up to 1024 characters',
            hash: 'Required. Up to 255 characters',
            updatedAt: 'Required',
        },
    },
    update: {
        field: {
            empty: 'Empty collection',
            cacheInfo: 'cacheInfo',
            count: 'count',
            geolocation: 'geolocation',
            georules: 'georules',
            supportedHints: 'supportedHints',
            supportedLinks: 'supportedLinks',
            supportedFormats: 'supportedFormats',
            supportedPreferences: 'supportedPreferences',
            rateLimit: 'rateLimit',
            rateLimitInfo: 'rateLimitInfo',
            trackingStatus: 'trackingStatus',
            supportedTunnels: 'supportedTunnels',
            id: 'id',
            startedAt: 'startedAt',
            endAt: 'endAt',
            mode: 'mode',
            status: 'status',
            statusText: 'statusText',
            experienceCount: 'experienceCount',
            experienceSkipped: 'experienceSkipped',
            experienceFailed: 'experienceFailed',
            runningTime: 'runningTime',
            peakMemory: 'peakMemory',
        },
        help: {
            empty: 'No documents in this collection',
            cacheInfo: 'Required',
            count: 'Required',
            create: 'Providers above are updated automatically, but you can request/force a manual update here',
            geolocation: 'Required',
            georules: 'Required',
            supportedHints: 'Required',
            supportedLinks: 'Required',
            supportedFormats: 'Required',
            supportedPreferences: 'Required',
            rateLimit: 'Required',
            rateLimitInfo: 'Required',
            trackingStatus: 'Required',
            supportedTunnels: 'Required',
            id: 'Required',
            startedAt: 'Optional',
            endAt: 'Optional',
            mode: 'Required',
            status: 'Required',
            statusText: 'Required. Up to 255 characters',
            experienceCount: 'Required',
            experienceSkipped: 'Required',
            experienceFailed: 'Required',
            runningTime: 'Optional',
            peakMemory: 'Optional',
        },
    },
    weekday: {
        long: {
            mon: 'Monday',
            tue: 'Tuesday',
            wed: 'Wednestday',
            thu: 'Thurdsay',
            fri: 'Friday',
            sat: 'Saturday',
            sun: 'Sunday',
        },
        short: {
            mon: 'Mon',
            tue: 'Tue',
            wed: 'Wed',
            thu: 'Thu',
            fri: 'Fri',
            sat: 'Sat',
            sun: 'Sun',
        },
        min: {
            mon: 'Mo',
            tue: 'Tu',
            wed: 'We',
            thu: 'Th',
            fri: 'Fr',
            sat: 'Sa',
            sun: 'Su',
        },
    },
}
