import IngestIcon from '@material-ui/icons/Label';
import IngestCreate from './IngestCreate';
import IngestList from './IngestList';
import IngestShow from './IngestShow';

export default {
    create: IngestCreate,
    list: IngestList,
    show: IngestShow,
    icon: IngestIcon
};
