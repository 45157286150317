import React from 'react';
import CommentCreateToolbar from './CommentCreateToolbar';
import CommentForm from './CommentForm';
import {
    Create
} from 'react-admin';

const commentDefaultValue = {
    id: null,
    fingerprint: null,
    subject: null,
    comment: null,
    status: "PENDING_MODERATION",
    createdAt: new Date(),
};

const CommentCreate = (props) => (
    <Create {...props}>
        <CommentForm initialValues={commentDefaultValue} toolbar={<CommentCreateToolbar />} { ...props } />
    </Create>
);

export default CommentCreate;
