import React from 'react';
import config from '../../config/config.js';
import IngestCreateToolbar from './IngestCreateToolbar.js';
import {
    ArrayInput,
    Create,
    FileField,
    FileInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    useTranslate,
} from 'react-admin';

const validateIngest = function(values) {
    const errors = {};


    return errors;
};

const IngestCreate = function(props) {
    const translate = useTranslate();

    return (
        <Create {...props}>
            <SimpleForm toolbar={<IngestCreateToolbar />} redirect="show" validate={ validateIngest }>
                <FileInput source="files" accept="image/png" maxSize={config.ingest.maxFileSize} minSize={config.ingest.minFileSize} multiple={true} placeholder={<p>{translate('ingest.field.drop')}</p>} isRequired={true}>
                    <FileField source="src" title="title" />
                </FileInput>
                <ArrayInput source="meta">
                    <SimpleFormIterator>
                        <TextInput source="key" />
                        <TextInput source="value" />
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Create>
    );
};

export default IngestCreate;
