import React from 'react';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {BooleanInput, SimpleForm, useTranslate} from 'react-admin';

const useStyles = makeStyles({
    text: {
        width: '100%',
    },
});

const SurveyForm = (props) => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <SimpleForm redirect="list" {...props}>
            <BooleanInput source="forceUpdate" />
            <Typography color="textSecondary" variant="body2" className={classes.text}>
                {translate('survey.help.create')}
            </Typography>
        </SimpleForm>
    );
}

export default SurveyForm;
