import React from 'react';
import { Edit } from 'react-admin';
import ScanTitle from './ScanTitle';
import ScanEditActions from './ScanEditActions';
import ScanForm from './ScanForm';

const ScanEdit = (props) => (
    <Edit actions={<ScanEditActions />} title={<ScanTitle />} {...props}>
        <ScanForm {...props } />
    </Edit>
);

export default ScanEdit;
