import React from 'react';
import UpdateEmpty from './UpdateEmpty';
import UpdateFilter from './UpdateFilter';
import AvatarField from '../../component/AvatarField';
import {
    ChipField,
    Datagrid,
    List,
    NumberField,
    ReferenceField,
    Responsive,
    ShowButton,
    SimpleList,
    TextField
} from 'react-admin';

const defaults = {
    offset: 0,
    limit: 10,
    sort: {
        field: 'id',
        order: 'DESC'
    }
};

const UpdateList = (props) => (
    <List empty={<UpdateEmpty/>} filters={<UpdateFilter />} perPage={defaults.limit} sort={defaults.sort} {...props}>
        <Responsive
            small={
                <SimpleList
                    primaryText={ record => record ? record.id : null }
                    secondaryText={ record => record ? record.id : null }
                    tertiaryText={ record => record ? record.id : null }
                />
            }
            medium={
                <Datagrid>
                    <ReferenceField source="user.id" reference="user" link="show" allowEmpty>
                        <AvatarField source="username" />
                    </ReferenceField>
                    <ReferenceField source="provider.id" reference="provider" link="show">
                        <TextField source="name" />
                    </ReferenceField>
                    <ChipField source="mode" />
                    <ChipField source="status" />
                    <NumberField source="experienceCount" textAlign="right" />
                    <ShowButton />
                </Datagrid>
            }
        />
    </List>
);

export default UpdateList;
