import React from 'react';
import SettingShowActions from './SettingShowActions';
import SettingTitle from './SettingTitle.js';
import {
    ImageField,
    Show,
    SimpleShowLayout,
    TextField
} from 'react-admin';

const SettingShow = (props) => (
    <Show actions={<SettingShowActions/>} title={<SettingTitle />} {...props}>
        <SimpleShowLayout>
            <TextField source="key" />
            <TextField source="order" />
            <TextField source="nameCA" />
            <TextField source="nameES" />
            <ImageField source="poster" />
        </SimpleShowLayout>
    </Show>
);

export default SettingShow;
