import * as firebase from "firebase";
import firebaseConfig from "../config/firebase";
import firebaseOptions from "../config/options";
import {resolveResourceAlias} from "../util/uri";

const fieldsByResource = {
    banner: [
        'bgImage',
        'bgImageCA',
        'bgImageES',
        'fgImage',
        'fgImageCA',
        'fgImageES',
    ],
    experience: [
        'poster',
        'posterCA',
        'posterES',
        'media',
        'mediaES',
    ],
    setting: [
        'poster',
    ],
};

const fileRef = (resource, file) => `${resource}/${file.name}`;

const getFields = (resource, params) => {
    const actualResource = resolveResourceAlias(resource);

    if (! fieldsByResource[actualResource] || ! params || ! params.data) {
        return null;
    }

    const fields = {};

    for (const name of fieldsByResource[actualResource]) {
        const data = params.data[name];

        if (! data || typeof data !== 'object') {
            // Skip if already an URL
            continue;
        }

        const files = [];

        // Check for a single file or a list of files
        if (data.rawFile) {
            files.push(data.rawFile);
        } else {
            files.push(...data);
        }

        fields[name] = files;
    }

    return fields;
};

const putFiles = async (storage, resource, params, fields) => {
    const promises = [];

    for (const [field, files] of Object.entries(fields)) {
        const isCollection = files.length > 1;

        promises.push(...files.map(async (file, offset) => {
            const reference = fileRef(resource, file);

            return await storage.ref(reference).put(file).then(async (snapshot) => {
                const downloadURL = await snapshot.ref.getDownloadURL();

                if (isCollection) {
                    params.data[field][offset] = downloadURL;
                } else {
                    params.data[field] = downloadURL;
                }
            });
        }));
    }

    return Promise.all(promises);
};

const deleteFiles = async (storage, resource, params, fields) => {
    const promises = [];

    for (const [field, files] of Object.entries(fields)) {
        const isCollection = files.length > 1;

        promises.push(...files.map((file, offset) => {
            const reference = fileRef(resource, file);

            return storage.ref(reference).delete(file).then(() => {
                if (isCollection) {
                    delete params[field][offset];
                } else {
                    delete params[field];
                }
            });
        }));
    }

    return Promise.all(promises);
};

const createFirebaseApp = (firebaseConfig, firebaseOptions) => {
    if (firebaseOptions.app) {
        return firebaseOptions.app;
    }

    if (!! firebase.apps.length) {
        return firebase.app();
    }

    return firebase.initializeApp(firebaseConfig);
};

const firebaseProvider = (dataProvider) => {
    const app = createFirebaseApp(firebaseConfig, firebaseOptions);
    const storage = app.storage();

    const getList = (resource, params) => {
        return dataProvider.getList(resource, params);
    };

    const getOne = (resource, params) => {
        return dataProvider.getOne(resource, params);
    };

    const getMany = (resource, params) => {
        return dataProvider.getMany(resource, params);
    };

    const getManyReference = (resource, params) => {
        return dataProvider.getManyReference(resource, params);
    };

    const create = async (resource, params) => {
        const fields = getFields(resource, params);

        if (fields) {
            await putFiles(storage, resource, params, fields);
        }

        return dataProvider.create(resource, params);
    };

    const update = async (resource, params) => {
        const fields = getFields(resource, params);

        if (fields) {
            await putFiles(storage, resource, params, fields);
        }

        return dataProvider.update(resource, params);
    };

    const updateMany = (resource, params) => {
        return dataProvider.updateMany(resource, params);
    };

    const delete_ = async (resource, params) => {
        const fields = getFields(resource, params);

        if (fields) {
            await deleteFiles(storage, resource, params, fields);
        }

        return dataProvider.delete(resource, params);
    };

    const deleteMany = (resource, params) => {
        return dataProvider.deleteMany(resource, params);
    };

    return {
        getList,
        getOne,
        getMany,
        getManyReference,
        create,
        update,
        updateMany,
        delete: delete_,
        deleteMany,
    };
};

export default firebaseProvider;
