import React from 'react';
import { Admin, Resource } from 'react-admin';

// Dashboard
import Dashboard from './component/Dashboard.js';
import Layout from './component/Layout';

// Providers
import authProvider from './provider/auth';
import dataProvider from './provider/data';
import firebaseProvider from './provider/firebase';
import i18nProvider from './provider/i18n';

// Services
import audio from './service/audio';
import banner from './service/banner';
import comment from './service/comment';
import event from './service/event';
import experience from './service/experience';
import gif from './service/gif';
import ingest from './service/ingest';
import meme from './service/meme';
import option from './service/option';
import pdf from './service/pdf';
import poster from './service/poster';
import promo from './service/promo';
import provider from './service/provider';
import rating from './service/rating';
import scan from './service/scan';
import setting from './service/setting';
import station from './service/station';
import survey from './service/survey';
import update from './service/update';
import user from './service/user';
import video from './service/video';

// Custom routes
import customRoutes from './config/routes';
import themeReducer from './config/themeReducer';

const App = function() {
    return <Admin
        locale="en"
        layout={Layout}
        dashboard={Dashboard}
        authProvider={authProvider}
        dataProvider={firebaseProvider(dataProvider)}
        i18nProvider={i18nProvider}
        customRoutes={customRoutes}
        customReducers={{ theme: themeReducer }}
    >
        <Resource name="audio" {...audio} />
        <Resource name="banner" {...banner} />
        <Resource name="comment" {...comment} />
        <Resource name="event" {...event} />
        <Resource name="experience" {...experience} />
        <Resource name="gif" {...gif} />
        <Resource name="ingest" {...ingest} />
        <Resource name="meme" {...meme} />
        <Resource name="option" {...option} />
        <Resource name="pdf" {...pdf} />
        <Resource name="poster" {...poster} />
        <Resource name="promo" {...promo} />
        <Resource name="provider" {...provider} />
        <Resource name="rating" {...rating} />
        <Resource name="scan" {...scan} />
        <Resource name="setting" {...setting} />
        <Resource name="station" {...station} />
        <Resource name="survey" {...survey} />
        <Resource name="update" {...update} />
        <Resource name="user" {...user} />
        <Resource name="video" {...video} />
    </Admin>;
};

export default App;
