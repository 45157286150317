import React from 'react';
import enums from '../experience/enums';
import {
    Filter,
    SearchInput,
    SelectInput
} from 'react-admin';
import QuickFilter from "../../component/QuickFilter";

const PdfFilter = function(props) {
    return (
        <Filter {...props}>
            <SearchInput source="q" alwaysOn />
            <QuickFilter label="PDF" source="type" defaultValue={ "DOCUMENT" } />
            <SelectInput source="status" choices={ enums.experienceStatus } />
        </Filter>
    );
};

export default PdfFilter;
