import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import AudioIcon from '@material-ui/icons/LocalActivity';
import BannerIcon from '@material-ui/icons/ViewCarousel';
import CommentIcon from '@material-ui/icons/Chat';
import EventIcon from '@material-ui/icons/LocalActivity';
import GifIcon from '@material-ui/icons/LocalActivity';
import ImageIcon from '@material-ui/icons/Image';
import LabelIcon from '@material-ui/icons/Label';
import PdfIcon from '@material-ui/icons/InsertDriveFile';
import PromoIcon from '@material-ui/icons/LocalActivity';
import ProviderIcon from '@material-ui/icons/VerifiedUser';
import ScanIcon from '@material-ui/icons/CropFree';
import SettingsIcon from '@material-ui/icons/Settings';
import StationIcon from '@material-ui/icons/Subway';
import SurveyIcon from '@material-ui/icons/QuestionAnswer';
import UpdateIcon from '@material-ui/icons/Update';
import UserIcon from '@material-ui/icons/Person';
import VideoIcon from '@material-ui/icons/LocalActivity';
import {
    DashboardMenuItem,
    MenuItemLink,
    Responsive,
} from 'react-admin';
import SubMenu from './SubMenu';
import i18nProvider from "../provider/i18n";

class Menu extends Component {

    static propTypes = {
        onMenuClick: PropTypes.func,
        logout: PropTypes.object
    };

    state = {
        experience: false,
        station: false,
        survey: false,
        user: false,
    };

    handleToggle = function(menu) {
        this.setState(function(state) {
            return { [menu]: ! state[menu] };
        });
    };

    render() {
        const { onMenuClick, open, logout } = this.props;
        const canEditConfig = localStorage.getItem('authn.rol')
            && JSON.parse(localStorage.getItem('authn.rol')).indexOf('ROLE_SUPERADMIN') > -1;

        return (
            <div>
                {' '}
                <DashboardMenuItem onClick={onMenuClick} />
                {
                    canEditConfig ? (
                        <MenuItemLink
                            to={`/option`}
                            primaryText={'Settings'}
                            onClick={onMenuClick}
                            leftIcon={<SettingsIcon/>}
                        />
                    ) : null
                }
                <MenuItemLink
                    to={`/banner`}
                    primaryText={'Banners'}
                    onClick={onMenuClick}
                    leftIcon={<BannerIcon/>}
                />
                <SubMenu
                    handleToggle={ () => this.handleToggle('experience') }
                    isOpen={ this.state.experience }
                    sidebarIsOpen={open}
                    name="experience"
                    icon={<LabelIcon />}
                >
                    <MenuItemLink
                        to={`/setting`}
                        primaryText={'Categories'}
                        onClick={onMenuClick}
                        leftIcon={<SettingsIcon />}
                    />
                    <MenuItemLink
                        to={`/audio`}
                        primaryText="Audio"
                        onClick={onMenuClick}
                        leftIcon={<AudioIcon />}
                    />
                    <MenuItemLink
                        to={`/event`}
                        primaryText="Event"
                        onClick={onMenuClick}
                        leftIcon={<EventIcon />}
                    />
                    <MenuItemLink
                        to={`/gif`}
                        primaryText="GIF"
                        onClick={onMenuClick}
                        leftIcon={<GifIcon />}
                    />
                    <MenuItemLink
                        to={`/meme`}
                        primaryText="JPEG"
                        onClick={onMenuClick}
                        leftIcon={<PromoIcon />}
                    />
                    <MenuItemLink
                        to={`/pdf`}
                        primaryText="PDF"
                        onClick={onMenuClick}
                        leftIcon={<PdfIcon />}
                    />
                    <MenuItemLink
                        to={`/promo`}
                        primaryText="Promo"
                        onClick={onMenuClick}
                        leftIcon={<PromoIcon />}
                    />
                    <MenuItemLink
                        to={`/video`}
                        primaryText="Video"
                        onClick={onMenuClick}
                        leftIcon={<VideoIcon />}
                    />
                    <MenuItemLink
                        to={`/poster`}
                        primaryText="Media"
                        onClick={onMenuClick}
                        leftIcon={<ImageIcon />}
                    />
                    <MenuItemLink
                        to={`/provider`}
                        primaryText="Provider"
                        onClick={onMenuClick}
                        leftIcon={<ProviderIcon />}
                    />
                    <MenuItemLink
                        to={`/update`}
                        primaryText="Update"
                        onClick={onMenuClick}
                        leftIcon={<UpdateIcon />}
                    />
                </SubMenu>
                <SubMenu
                    handleToggle={ () => this.handleToggle('station') }
                    isOpen={ this.state.station }
                    sidebarIsOpen={open}
                    name="station"
                    icon={<LabelIcon />}
                >
                    <MenuItemLink
                        to={`/station`}
                        primaryText="Station"
                        onClick={onMenuClick}
                        leftIcon={<StationIcon />}
                    />
                </SubMenu>
                <SubMenu
                    handleToggle={ () => this.handleToggle('survey') }
                    isOpen={ this.state.survey }
                    sidebarIsOpen={open}
                    name="survey"
                    icon={<LabelIcon />}
                >
                    <MenuItemLink
                        to={`/survey`}
                        primaryText="Survey"
                        onClick={onMenuClick}
                        leftIcon={<SurveyIcon />}
                    />
                </SubMenu>
                <SubMenu
                    handleToggle={ () => this.handleToggle('user') }
                    isOpen={ this.state.user }
                    sidebarIsOpen={open}
                    name="user"
                    icon={<LabelIcon />}
                >
                    <MenuItemLink
                        to={`/user`}
                        primaryText="User"
                        onClick={onMenuClick}
                        leftIcon={<UserIcon />}
                    />
                    <MenuItemLink
                        to={`/comment`}
                        primaryText="Comment"
                        onClick={onMenuClick}
                        leftIcon={<CommentIcon />}
                    />
                    <MenuItemLink
                        to={`/rating`}
                        primaryText="Rating"
                        onClick={onMenuClick}
                        leftIcon={<LabelIcon />}
                    />
                    <MenuItemLink
                        to={`/scan`}
                        primaryText="Scan"
                        onClick={onMenuClick}
                        leftIcon={<ScanIcon />}
                    />
                </SubMenu>
                <Responsive
                    xsmall={
                        <MenuItemLink
                            to="/configuration"
                            primaryText="Categories"
                            leftIcon={<SettingsIcon />}
                            onClick={onMenuClick}
                        />
                    }
                    medium={null}
                />
                <Responsive
                    small={logout}
                    medium={null} // Pass null to render nothing on larger devices
                />
            </div>
        );
    }
}

const mapStateToProps = function(state) {
    return {
        open: state.admin.ui.sidebarOpen,
        theme: state.theme,
        locale: i18nProvider.getLocale(),
    };
};

const enhance = compose(withRouter, connect(mapStateToProps, {}));

export default enhance(Menu);
