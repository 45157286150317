import StationIcon from '@material-ui/icons/Subway';
import StationCreate from './StationCreate';
import StationEdit from './StationEdit';
import StationList from './StationList';
import StationShow from './StationShow';

export default {
    create: StationCreate,
    edit: StationEdit,
    list: StationList,
    show: StationShow,
    icon: StationIcon
};
