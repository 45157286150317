import React from 'react';
import ProviderEmpty from './ProviderEmpty';
import ProviderFilter from './ProviderFilter';
import {
    BooleanField,
    ChipField,
    Datagrid,
    EditButton,
    List,
    NumberField,
    Responsive,
    ShowButton,
    SimpleList,
    TextField
} from 'react-admin';

const defaults = {
    offset: 0,
    limit: 10,
    sort: {
        field: 'id',
        order: 'DESC'
    }
};

const ProviderList = (props) => (
    <List empty={<ProviderEmpty/>} filters={<ProviderFilter />} perPage={defaults.limit} sort={defaults.sort} {...props}>
        <Responsive
            small={
                <SimpleList
                    primaryText={ record => record ? record.id : null }
                    secondaryText={ record => record ? record.id : null }
                    tertiaryText={ record => record ? record.id : null }
                />
            }
            medium={
                <Datagrid>
                    <BooleanField source="enabled" />
                    <TextField source="name" />
                    <ChipField source="type" />
                    <ChipField source="updateMode" />
                    <NumberField source="experienceCount" textAlign="right" />
                    <EditButton />
                    <ShowButton />
                </Datagrid>
            }
        />
    </List>
);

export default ProviderList;
