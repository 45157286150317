import React from 'react';
import {
    ChipField,
    Datagrid,
    List,
    Responsive,
    SimpleList,
    TextField
} from 'react-admin';
import LatLngField from "../../component/LatLngField";
import StationEmpty from './StationEmpty';
import StationFilter from './StationFilter';
import ScalarArrayField from "../../component/ScalarArrayField";

const defaults = {
    offset: 0,
    limit: 10,
    sort: {
        field: 'id',
        order: 'DESC'
    }
};

const StationList = (props) => (
    <List empty={<StationEmpty/>} filters={<StationFilter />} perPage={defaults.limit} sort={defaults.sort} {...props}>
        <Responsive
            small={
                <SimpleList
                    primaryText={ record => record ? record.station : null }
                    secondaryText={ record => record ? record.code : null }
                    tertiaryText={ record => record ? record.type : null }
                />
            }
            medium={
                <Datagrid
                    rowClick="show"
                >
                    <TextField source="code" />
                    <ChipField source="type" />
                    <TextField source="station" />
                    <TextField source="district" />
                    <ChipField source="line" />
                    <ScalarArrayField source="lines" maxItems={3} />
                    <ScalarArrayField source="codes"code={true} maxItems={3} />
                    <LatLngField source="coordinates" addLabel={false} />
                </Datagrid>
            }
        />
    </List>
);

export default StationList;
