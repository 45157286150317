import React from 'react';
import BannerEmpty from './BannerEmpty';
import {
    BooleanField,
    Datagrid,
    EditButton,
    List,
    Responsive,
    ShowButton,
    SimpleList,
    TextField
} from 'react-admin';

const defaults = {
    offset: 0,
    limit: 10,
    sort: {
        field: 'id',
        order: 'DESC'
    }
};

const BannerList = (props) => (
    <List
        empty={<BannerEmpty/>}
        perPage={defaults.limit}
        sort={defaults.sort}
        {...props}
    >
        <Responsive
            small={
                <SimpleList primaryText={ record => record ? record.alias : null } />
            }
            medium={
                <Datagrid>
                    <TextField source="alias" />
                    <BooleanField source="active" />
                    <BooleanField source="useHtml" />
                    <EditButton />
                    <ShowButton />
                </Datagrid>
            }
        />
    </List>
);

export default BannerList;
