export default {
    env: process.env.REACT_APP_ENV || 'prod',
    debug: process.env.REACT_APP_DEBUG || false,
    serviceWorker: process.env.REACT_APP_SW || true,
    caching: {
        clientCache: false,
        ignoreCacheControl: process.env.REACT_APP_IGNORE_CACHE_CONTROL || false,
    },
    clientHints: {
        enable: false,
    },
    collection: {
        defaultOffset: 0,
        defaultLimit: 32,
        limitOptions: [8, 16, 32],
    },
    fingerprint: {
        enabled: false,
    },
    http: {
        rootURL: process.env.REACT_APP_NS_URL_SERVICE,
    },
    image: {
        minSize: 0,
        maxSize: 8388608,
        accept: [
            "image/gif",
            "image/jpeg",
            "image/png",
        ].join(','),
    },
    ingest: {
        header: "Ingest-Token",
        methods: ["POST", "PUT"],
        allowMediaTypes: ["application/x-www-form-urlencoded", "multipart/form-data", "multipart/mixed"],
        allowResume: true,
        minFileSize: 0,
        maxFileSize: 268435456,
        maxFilesPerRequest: 5,
        maxSize: 268435456,
    },
    media: {
        minSize: 0,
        maxSize: 268435456,
        accept: [
            "application/pdf",
            "audio/mp4",
            "audio/mpeg",
            "image/gif",
            "image/jpeg",
            "image/png",
            "video/mp4",
        ].join(','),
    },
    namespace: {
        static: {
            baseURL: process.env.REACT_APP_ASSET_URL,
        },
        share: {
            baseURL: process.env.REACT_APP_SHARE_URL,
        },
        service: {
            baseURL: process.env.REACT_APP_NS_URL_SERVICE,
            strategy: "underscore",
            pluralize: false,
            trailingSlash: true,
        },
        schema: {
            baseURL: process.env.REACT_APP_BS_URL_SCHEMA,
            strategy: "identity",
            pluralize: false,
            trailingSlash: true,
        },
        problem: {
            baseURL: process.env.REACT_APP_BS_URL_PROBLEM,
            strategy: "identity",
            pluralize: false,
            trailingSlash: true,
        },
        doc: {
            baseURL: process.env.REACT_APP_NS_URL_DOC,
            strategy: "identity",
            pluralize: false,
            trailingSlash: true,
        },
    },
    negotiation: {
        accept: 'application/hal+json, application/json; q=0.9, */*; q=0.8',
        acceptCharset: 'utf-8',
        acceptEncoding: 'gzip, deflate, br',
        acceptLanguage: 'es_ES, en_EN; q=0.8',
        digests: ["sha256","sha384","sha512"],
    },
    preferences: {
        enabled: false,
    },
    security: {
        jwt: {
            scheme: "JWT",
        },
    },
    tracking: {
        doNotTrack: true,
    },
    tunneling: {
        header: "X-Http-Method-Override",
        method: "GET",
    },
}
