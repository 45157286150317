import React from 'react';
import ProviderShowActions from './ProviderShowActions';
import ProviderTitle from './ProviderTitle.js';
import {
    BooleanField,
    DateField,
    NumberField,
    SelectField,
    Show,
    SimpleShowLayout,
    TextField
} from 'react-admin';

const ProviderShow = (props) => (
    <Show actions={<ProviderShowActions/>} title={<ProviderTitle />} {...props}>
        <SimpleShowLayout>
            <BooleanField source="enabled" />
            <TextField source="name" />
            <TextField source="description" />
            <SelectField source="type" translateChoice={false} choices={[
                { id: 'MEME', name: 'MEME' },
                { id: 'EVENT', name: 'EVENT' },
                { id: 'PROMO', name: 'PROMO' },
            ]} />
            <SelectField source="status" translateChoice={false} choices={[
                { id: 'PENDING_MODERATION', name: 'PENDING_MODERATION' },
                { id: 'APPROVED', name: 'APPROVED' },
                { id: 'REJECTED', name: 'REJECTED' },
                { id: 'SUSPENDED', name: 'SUSPENDED' },
            ]} />
            <TextField source="source" />
            <TextField source="sourceUpdateFrequency" />
            <TextField source="categories" />
            <SelectField source="updateMode" translateChoice={false} choices={[
                { id: 'AUTO', name: 'AUTO' },
                { id: 'MANUAL', name: 'MANUAL' },
            ]} />
            <TextField source="updateFrequency" />
            <TextField source="updateWorker" />
            <NumberField source="experienceCount" textAlign="right" />
            <DateField source="createdAt" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}  />
            <DateField source="updatedAt" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}  />
            <DateField source="lastUpdatedAt" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}  />
            <SelectField source="lastUpdateMode" translateChoice={false} choices={[
                { id: 'AUTO', name: 'AUTO' },
                { id: 'MANUAL', name: 'MANUAL' },
            ]} />
            <NumberField source="lastUpdateCount" textAlign="right" />
        </SimpleShowLayout>
    </Show>
);

export default ProviderShow;
