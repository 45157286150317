import ExperienceIcon from '@material-ui/icons/Label';
import ExperienceCreate from './ExperienceCreate';
import ExperienceEdit from "./ExperienceEdit";
import ExperienceList from "./ExperienceList";
import ExperienceTabbedShow from "./ExperienceTabbedShow";

export default {
    create: ExperienceCreate,
    edit: ExperienceEdit,
    list: ExperienceList,
    show: ExperienceTabbedShow,
    icon: ExperienceIcon
};
