import RatingIcon from '@material-ui/icons/Label';
import RatingCreate from './RatingCreate';
import RatingEdit from './RatingEdit';
import RatingList from './RatingList';
import RatingShow from './RatingShow';

export default {
    create: RatingCreate,
    edit: RatingEdit,
    list: RatingList,
    show: RatingShow,
    icon: RatingIcon
};
