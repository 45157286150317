import React from 'react';
import AvatarField from '../../component/AvatarField';
import ScanShowActions from './ScanShowActions';
import ScanTitle from './ScanTitle.js';
import {
    ArrayField,
    DateField,
    ReferenceField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField
} from 'react-admin';
import ScanGridList from "./ScanGridList";

const ScanTabbedShow = (props) => (
    <Show actions={<ScanShowActions/>} title={<ScanTitle />} {...props}>
        <TabbedShowLayout>
            <Tab label="scan">
                <ReferenceField label="user" source="user.id" reference="user" link="show" allowEmpty>
                    <AvatarField source="username" />
                </ReferenceField>
                <TextField source="subject" />
                <TextField source="code" />
                <TextField source="locationId" />
                <TextField source="location" />
                <DateField source="createdAt" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}  />
                <DateField source="expiresAt" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}  />
            </Tab>
            <Tab label="experiences">
                <ArrayField addLabel={false} source="experiences">
                    <ScanGridList />
                </ArrayField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default ScanTabbedShow;
