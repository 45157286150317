import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from './i18n/en.js';
import spanishMessages from './i18n/es.js';

const DEFAULT_LOCALE = 'en';

const messages = {
    en: englishMessages,
    es: spanishMessages,
};

const i18nProvider = polyglotI18nProvider(locale => messages[locale], DEFAULT_LOCALE);

export default i18nProvider;
