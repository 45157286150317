import React from "react";
import PropTypes from "prop-types";
import {
    Avatar,
    Link,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles,
} from "@material-ui/core";
import DislikeIcon from '@material-ui/icons/ThumbDown';
import LikeIcon from '@material-ui/icons/ThumbUp';
import RatingIcon from '@material-ui/icons/ThumbsUpDown';
import {useTranslate} from "react-admin";
import CardWithIcon from "./CardWithIcon";
import {timeAgo} from "../util/time";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: `${theme.spacing(2)}px`,
    },
}));

const ratingPrimaryLabel = record => record ? record.subject : null;
const ratingSecondaryLabel = record => record && record.experience ? record.experience.id : null;


const RecentlyRated = ({ ratings = {}, lastRating }) => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <CardWithIcon
            to="/rating"
            icon={RatingIcon}
            title={translate('admin.lastRatings')}
            subtitle={timeAgo(lastRating)}
            className={classes.root}
        >
            {
                ratings.length ? (
                    <List>
                        {ratings.map((record) => (
                            <ListItem
                                key={record.id}
                                button
                                component={Link}
                                to={`/rating/${record.id}`}
                                alignItems="flex-start"
                            >
                                <ListItemAvatar>
                                    <Avatar>
                                        {
                                            record.rating ? (
                                                <LikeIcon />
                                            ) : (
                                                <DislikeIcon />
                                            )
                                        }
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={ratingPrimaryLabel(record)}
                                    secondary={ratingSecondaryLabel(record)}
                                />
                            </ListItem>
                        ))}
                    </List>
                ) : null
            }
        </CardWithIcon>
    );
};

RecentlyRated.propTypes = {
    ratings: PropTypes.array,
    lastRating: PropTypes.object,
};

export default RecentlyRated;
