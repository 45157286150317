import React from "react";
import PropTypes from "prop-types";
import Chip from "@material-ui/core/Chip";
import clsx from "clsx";
import get from "lodash/get";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
    },
    chip: {
        marginBottom: `${theme.spacing(1)}px`,
        marginRight: `${theme.spacing(1)}px`,
    },
    code: {
        fontFamily: "'Roboto Mono',monospace",
    },
    label: {
        display: "block",
    }
}));

const ScalarArrayField = function({ code, label, maxItems, record = {}, source }) {
    const classes = useStyles();
    const data = get(record, source);
    const numItems = data ? data.length : 0;

    let items = data;

    if (items && maxItems > -1) {
        items = items.slice(0, maxItems);
    }

    return items && items.length ? (
        <div className={classes.root}>
            {
                items.map((item, i) => (
                    <Chip
                        key={i}
                        label={`${item}`}
                        className={clsx(classes.chip, {
                            [classes.code]: code,
                        })}
                    />
                ))
            }
            {
                items.length < numItems ? (
                    <Chip
                        className={classes.chip}
                        label={`And ${numItems - maxItems} more`}
                    />
                ) : null
            }
        </div>
    ) : null;
};

ScalarArrayField.propTypes = {
    code: PropTypes.bool,
    label: PropTypes.string,
    source: PropTypes.string.isRequired,
    record: PropTypes.object,
    maxItems: PropTypes.number,
};

ScalarArrayField.defaultProps = {
    code: false,
    maxItems: -1,
};

export default ScalarArrayField;
