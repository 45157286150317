const truncate = (n) => n > 0 ? Math.floor(n) : Math.ceil(n);

const toDegreesMinutesSeconds = (decimal, longOrLat) => {
    let hemisphere = /^[WE]|(?:lon)/i.test(longOrLat) ? decimal < 0 ? "W" : "E" : decimal < 0 ? "S" : "N";

    const a = Math.abs(decimal);
    const d = truncate(a);
    const m = truncate((a - d) * 60);
    const s = ((a - d - m / 60) * Math.pow(60, 2)).toFixed(2);

    return `${d}°${m}'${s}" ${hemisphere}`;
};

export const toLatLngArray = ({ lat, lng }) => [lng, lat];

export const toLatLngObj = (latLng) => {
    const lat = latLng[1];
    const lng = latLng[0];

    return { lat, lng };
};

export const latLngToDms = (latLng) => `${toDegreesMinutesSeconds(latLng[1])} ${toDegreesMinutesSeconds(latLng[0])}`;

export const gmapUrl = (lat, lng) => {
    return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
};
