import React from 'react';
import OptionEmpty from './OptionEmpty';
import { Datagrid, List, Responsive, SimpleList, TextField } from 'react-admin';

const defaults = {
    offset: 0,
    limit: 10,
    sort: {
        field: 'id',
        order: 'DESC'
    }
};

const OptionList = (props) => (
    <List
        empty={<OptionEmpty/>}
        perPage={defaults.limit}
        sort={defaults.sort}
        {...props}
    >
        <Responsive
            small={
                <SimpleList
                    primaryText={ record => record ? record.key : null }
                    secondaryText={ record => record ? record.value : null }
                />
            }
            medium={
                <Datagrid rowClick="edit">
                    <TextField source="key" />
                    <TextField source="value" />
                </Datagrid>
            }
        />
    </List>
);

export default OptionList;
