import UpdateIcon from '@material-ui/icons/Label';
import UpdateCreate from './UpdateCreate';
import UpdateEdit from './UpdateEdit';
import UpdateList from './UpdateList';
import UpdateShow from './UpdateShow';

export default {
    create: UpdateCreate,
    edit: UpdateEdit,
    list: UpdateList,
    show: UpdateShow,
    icon: UpdateIcon
};
