import React from 'react';
import SettingEmpty from './SettingEmpty';
import {
    Datagrid,
    EditButton,
    List,
    Responsive,
    ShowButton,
    SimpleList,
    TextField
} from 'react-admin';

const defaults = {
    offset: 0,
    limit: 10,
    sort: {
        field: 'id',
        order: 'DESC'
    }
};

const SettingList = (props) => (
    <List empty={<SettingEmpty/>} perPage={defaults.limit} sort={defaults.sort} {...props}>
        <Responsive
            small={
                <SimpleList
                    primaryText={ record => record ? record.key : null }
                    secondaryText={ record => record ? record.value : null }
                />
            }
            medium={
                <Datagrid>
                    <TextField source="key" />
                    <TextField source="order" />
                    <EditButton />
                    <ShowButton />
                </Datagrid>
            }
        />
    </List>
);

export default SettingList;
