import Fingerprint2 from 'fingerprintjs2';

let fingerprint;

const options = {
    audio: {
        timeout: 1000,
        // On iOS 11, audio context can only be used in response to user
        // interaction. We require users to explicitly enable audio
        // fingerprinting on iOS 11.
        // See https://stackoverflow.com/questions/46363048/onaudioprocess-not-called-on-ios11#46534088
        excludeIOS11: true
    },
    excludes: {
        // Unreliable on Windows
        // See https://github.com/Valve/fingerprintjs2/issues/375
        enumerateDevices: true,
        // devicePixelRatio depends on browser zoom, and it's impossible
        // to detect browser zoom
        pixelRatio: true,
        // DNT depends on incognito mode for some browsers (Chrome) and
        // it's impossible to detect incognito mode
        doNotTrack: true,
        // uses js fonts already
        fontsFlash: true
    },
    extraComponents: [],
    fonts: {
        swfContainerId: 'fingerprintjs2',
        swfPath: 'flash/compiled/FontList.swf',
        userDefinedFonts: [],
        extendedJsFonts: false
    },
    plugins: {
        sortPluginsFor: [/palemoon/i],
        excludeIE: false
    },
    preprocessor: null,
    screen: {
        // To ensure consistent fingerprints when users rotate their
        // mobile devices
        detectScreenOrientation: true
    }
};

const requestFingerprint = function(resolve, reject) {
    return function() {
        // FP process takes about 80-100ms. If you use extendedJsFonts option
        // this time will increase up to 2000ms (cold font cache). To speed up
        // fingerprint computation, you can exclude font detection (~ 40ms),
        // canvas fingerprint (~ 10ms), WebGL fingerprint (~ 35 ms), and Audio
        // fingerprint (~30 ms)
        return Fingerprint2.getPromise(options).then(function (components) {
            const values = components.map(function (component) { return component.value; });
            fingerprint = Fingerprint2.x64hash128(values.join(''), 31);
            resolve(fingerprint);
        }).catch(function(error) {
            reject(error);
        });
    };
};

const fingerprintProvider = function() {

    return new Promise(function(resolve, reject) {
        if (fingerprint) {
            return resolve(fingerprint);
        }

        const callback = requestFingerprint(resolve, reject);

        // You should not run fingerprinting directly on or after page load.
        // Rather, delay it for a few milliseconds with setTimeout or
        // requestIdleCallback to ensure consistent fingerprints
        if (window.requestIdleCallback) {
            window.requestIdleCallback(callback);
        } else {
            setTimeout(callback, 500);
        }
    });
};

export default fingerprintProvider;
