import React from 'react';
import BannerShowActions from './BannerShowActions';
import BannerTitle from './BannerTitle.js';
import {
    BooleanField,
    ImageField,
    Show,
    SimpleShowLayout,
    TextField,
    useTranslate,
} from 'react-admin';

const BannerShow = (props) => {
    const translate = useTranslate();

    return (
        <Show actions={<BannerShowActions/>} title={<BannerTitle />} {...props}>
            <SimpleShowLayout>
                <BooleanField
                    source="active"
                    label={translate("banner.field.active")}
                />
                <BooleanField
                    source="useHtml"
                    label={translate("banner.field.active")}
                />
                <TextField
                    source="alias"
                    label={translate("banner.field.alias")}
                />
                <TextField
                    source="acceptLinkCA"
                    label={translate("banner.field.acceptLinkCA")}
                />
                <TextField
                    source="acceptLinkES"
                    label={translate("banner.field.acceptLinkES")}
                />
                <TextField
                    source="acceptButtonCA"
                    label={translate("banner.field.acceptButtonCA")}
                />
                <TextField
                    source="acceptButtonES"
                    label={translate("banner.field.acceptButtonES")}
                />
                <TextField
                    source="closeButtonCA"
                    label={translate("banner.field.closeButtonCA")}
                />
                <TextField
                    source="closeButtonES"
                    label={translate("banner.field.closeButtonES")}
                />
                <TextField
                    source="htmlCA"
                    label={translate("banner.field.htmlCA")}
                />
                <TextField
                    source="htmlES"
                    label={translate("banner.field.htmlES")}
                />
                <ImageField
                    source="bgImageCA"
                    label={translate("banner.field.bgImageCA")}
                />
                <ImageField
                    source="bgImageES"
                    label={translate("banner.field.bgImageCA")}
                />
                <TextField
                    source="bgImageLink"
                    label={translate("banner.field.bgImageLink")}
                />
                <ImageField
                    source="fgImage"
                    label={translate("banner.field.fgImage")}
                />
            </SimpleShowLayout>
        </Show>
    );
}

export default BannerShow;
