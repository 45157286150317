import React from 'react';
import {
    CloneButton,
    CreateButton,
    ListButton,
    RefreshButton,
    ShowButton,
    TopToolbar,
} from 'react-admin';

const RatingEditActions = ({ basePath, data }) => (
    <TopToolbar>
        <RefreshButton />
        <ShowButton basePath={basePath} record={data} />
        <CloneButton basePath={basePath} record={data} />
        <CreateButton basePath={basePath} />
        <ListButton basePath={basePath} />
    </TopToolbar>
);

export default RatingEditActions;
