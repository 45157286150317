import React from 'react';
import {
    ListButton,
    RefreshButton,
    TopToolbar
} from 'react-admin';

const UserShowActions = function({ basePath, data }) {
    return (
        <TopToolbar>
            <ListButton basePath={basePath} />
            <RefreshButton />
        </TopToolbar>
    );
};

export default UserShowActions;
