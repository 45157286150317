import React from 'react';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {
    ReferenceInput,
    SelectInput,
    SimpleForm,
    required,
    useTranslate,
} from 'react-admin';

const useStyles = makeStyles({
    text: {
        width: '100%',
    },
});

const validateProvider = [required()];

const UpdateForm = (props) => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <SimpleForm redirect="list" {...props}>
            <ReferenceInput source="provider.id" reference="provider" isRequired={true} filter={{ updateMode: 'AUTO' }}>
                <SelectInput optionText="name" validate={validateProvider} />
            </ReferenceInput>
            <Typography color="textSecondary" variant="body2" className={classes.text}>
                {translate('update.help.create')}
            </Typography>
        </SimpleForm>
    );
}

export default UpdateForm;
