import React from 'react';
import ScanEmpty from './ScanEmpty';
import ScanFilter from './ScanFilter';
import AvatarField from '../../component/AvatarField';
import {
    Datagrid,
    List,
    ReferenceField,
    Responsive,
    ShowButton,
    SimpleList,
    TextField
} from 'react-admin';

const defaults = {
    offset: 0,
    limit: 10,
    sort: {
        field: 'id',
        order: 'DESC'
    }
};

const ScanList = (props) => (
    <List empty={<ScanEmpty/>} filters={<ScanFilter />} perPage={defaults.limit} sort={defaults.sort} {...props}>
        <Responsive
            small={
                <SimpleList
                    primaryText={ record => record ? record.id : null }
                    secondaryText={ record => record ? record.id : null }
                    tertiaryText={ record => record ? record.id : null }
                />
            }
            medium={
                <Datagrid>
                    <ReferenceField label="user" source="user.id" reference="user" link="show" allowEmpty>
                        <AvatarField source="username" />
                    </ReferenceField>
                    <TextField source="location" />
                    <TextField source="subject" />
                    <ShowButton />
                </Datagrid>
            }
        />
    </List>
);

export default ScanList;
