import React from 'react';
import RatingEmpty from './RatingEmpty';
import RatingFilter from './RatingFilter';
import {
    BooleanField,
    Datagrid,
    List,
    ReferenceField,
    Responsive,
    ShowButton,
    SimpleList,
    TextField
} from 'react-admin';

const defaults = {
    offset: 0,
    limit: 10,
    sort: {
        field: 'id',
        order: 'DESC'
    }
};

const RatingList = (props) => (
    <List empty={<RatingEmpty/>} filters={<RatingFilter />} perPage={defaults.limit} sort={defaults.sort} {...props}>
        <Responsive
            small={
                <SimpleList
                    primaryText={ record => record ? record.id : null }
                    secondaryText={ record => record ? record.id : null }
                    tertiaryText={ record => record ? record.id : null }
                />
            }
            medium={
                <Datagrid>
                    <ReferenceField label="experience" source="experience.id" reference="experience" link="show">
                        <TextField source="nameCA" />
                    </ReferenceField>
                    <TextField source="subject" />
                    <BooleanField source="rating" />
                    <ShowButton />
                </Datagrid>
            }
        />
    </List>
);

export default RatingList;
