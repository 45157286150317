import React from 'react';
import UserFilter from './UserFilter';
import UserLinkField from './UserLinkField';
import UserRolesField from './UserRolesField';
import {
    Datagrid,
    EmailField,
    List,
    Responsive,
    ShowButton,
    SimpleList
} from 'react-admin';

const defaultLimit = 10;
const defaultSort = {
    field: 'username',
    order: 'ASC'
};

const UserList = function({ classes, ...props }) {
    return (
        <List filters={<UserFilter />} perPage={defaultLimit} sort={defaultSort} {...props}>
            <Responsive
                small={
                    <SimpleList
                        primaryText={ record => record ? record.username : null }
                        secondaryText={ record => record ? record.email : null }
                        tertiaryText={ record => record.roles.map(role => role.replace(/ROLE_/, '')).join(', ') }
                    />
                }
                medium={
                    <Datagrid>
                        <UserLinkField />
                        <EmailField source="email" />
                        <UserRolesField source="roles" />
                        <ShowButton />
                    </Datagrid>
                }
            />
        </List>
    );
};

export default UserList;
