import React from 'react';
import {
    Filter,
    SearchInput,
    TextInput
} from 'react-admin';

// You can choose whether you use conventional filters or quick filters.
// Users usually dislike using their keyboard to filter a list (especially
// on mobile). A good way to satisfy this user requirement is to turn
// filters into quick filter. A Quick filter is a filter with a non-
// editable defaultValue. Users can only enable or disable them.

// Note it's not possible to use two quick filters for the same source.
// So don't try something like:
//
//  <Filter ...>
//    ...
//  </Filter>

const SurveyFilter = function(props) {
    return (
        <Filter {...props}>
            <SearchInput source="q" alwaysOn />
            <TextInput source="hash" />
        </Filter>
    );
};

export default SurveyFilter;
