import config from '../config/config.js';

export const BASE_URL = new URL(config.namespace.service.baseURL);

const defaultParams = {
    pagination: {
        page: 0,
        perPage: 10
    },
    sort: {
        field: null,
        order: null
    },
    filter: {},
    ids: []
};

const filterTokens = query => {
    const tokens = [];

    for (const token of query.split(/\s+/)) {
        if (token.length > 2) {
            tokens.push(token);
        }
    }

    return tokens;
}

const generateQuery = (params = {}) => {
    const options = { ...defaultParams, ...params };

    let { page, perPage } = options.pagination;
    let { field, order } = options.sort;

    if (params.id) {
        // Disable pagination and sorting when
        // requesting instances
        perPage = 0;
        field = null;
    }

    const query = [];
    const filter = [];

    if (perPage > 0) {
        if (page > 0) {
            query.push(`cursor=${perPage * (page - 1)},${perPage}`);
        } else {
            query.push(`cursor=,${perPage}`);
        }
    }

    if (field) {
        query.push(`sort=${(order.toLowerCase() === 'desc' ? '-' : '+')}${field}`);
    }

    for (const key of Object.keys(options.filter)) {
        let value = options.filter[key];

        if (value) {
            if (key === 'q') {
                const tokens = filterTokens(value);

                if (tokens.length) {
                    filter.push(`in:keywords;${tokens.join(';')}`);
                }
            } else if (key === 'description') {
                if (! (value instanceof Array)) {
                    value = [value];
                }

                filter.push(`where:description;${value.join(';')}`);
            } else {
                if (! (value instanceof Array)) {
                    value = [value];
                }

                filter.push(`in:${key};${value.join(';')}`);
            }
        }
    }

    if (options.ids.length) {
        filter.push('in:id;' + options.ids.map(function(obj) {
            if (Array.isArray(obj)) {
                return obj.join(';');
            }

            return obj.id || obj.toString();
        }).join(';'));
    }

    if (filter.length) {
        query.push(`filter=${filter.join(',')}`);
    }

    return query.join('&');
};

const aliases = {
    audio: 'experience',
    gif: 'experience',
    event: 'experience',
    meme: 'experience',
    pdf: 'experience',
    promo: 'experience',
    video: 'experience',
};

export const resolveResourceAlias = (resource) => aliases[resource] || resource;

export const wellKnownURL = (resource, params = {}) => {
    const path = [];

    path.push('.well-known');
    path.push(resource);
    path.push(params.id || '');

    return new URL(path.join('/'), BASE_URL).toString();
};

export const serviceURL = (resource, params = {}) => {
    const segments = [];

    segments.push('1.0');
    segments.push(resolveResourceAlias(resource));

    if (params.id) {
        segments.push(params.id);
    } else {
        segments.push('');
    }

    let path = segments.join('/');
    const query = generateQuery(params);

    if (query) {
        path += '?' + query;
    }

    return new URL(path, BASE_URL).toString();
};

export const resolveURL = (path) => {
    return new URL(path, BASE_URL).toString();
};
