import React from 'react';
import { Edit } from 'react-admin';
import OptionTitle from './OptionTitle';
import OptionEditActions from './OptionEditActions';
import OptionForm from './OptionForm';

const OptionEdit = (props) => (
    <Edit
        actions={<OptionEditActions />}
        title={<OptionTitle />}
        {...props}
    >
        <OptionForm {...props } />
    </Edit>
);

export default OptionEdit;
