import React from 'react';
import { Edit } from 'react-admin';
import BannerTitle from './BannerTitle';
import BannerEditActions from './BannerEditActions';
import BannerForm from './BannerForm';

const BannerEdit = (props) => (
    <Edit
        actions={<BannerEditActions />}
        title={<BannerTitle />}
        {...props}
    >
        <BannerForm {...props } />
    </Edit>
);

export default BannerEdit;
