import React from 'react';
import { PropTypes } from 'prop-types';
import Typography from "@material-ui/core/Typography";
import LaunchIcon from "@material-ui/icons/Launch";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles} from "@material-ui/core/styles";
import { useTranslate } from "react-admin";
import { gmapUrl, latLngToDms } from "../util/geo";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
});

const LatLngField = ({ addLabel, expanded, record = {}, source }) => {
    const classes = useStyles();
    const translate = useTranslate();
    const coordinates = record[source];

    return expanded ? (
        <div className={classes.root}>
            {
                addLabel ? (
                    <Typography variant="body2">{coordinates ? latLngToDms(coordinates) : 'N/A'}</Typography>
                ) : null
            }
            {
                coordinates ? (
                    <IconButton
                        aria-label={translate('See on Google Maps')}
                        edge="end"
                        href={coordinates ? gmapUrl(coordinates[1], coordinates[0]) : '#'}
                        rel="noreferrer"
                        target="_blank"
                    >
                        <LaunchIcon />
                    </IconButton>
                ) : null
            }
        </div>
    ) : (
        <IconButton edge="end" aria-label={translate('See on Google Maps')} href={coordinates ? gmapUrl(coordinates[1], coordinates[0]) : '#'} target="_blank" rel="noreferrer">
            <LaunchIcon />
        </IconButton>
    );
};

LatLngField.propTypes = {
    addLabel: PropTypes.bool.isRequired,
    expanded: PropTypes.bool.isRequired,
};

LatLngField.defaultProps = {
    addLabel: true,
    expanded: true,
};

export default LatLngField;
