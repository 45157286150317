import BannerIcon from '@material-ui/icons/Subway';
import BannerCreate from './BannerCreate';
import BannerEdit from './BannerEdit';
import BannerList from './BannerList';
import BannerShow from './BannerShow';

export default {
    create: BannerCreate,
    edit: BannerEdit,
    list: BannerList,
    show: BannerShow,
    icon: BannerIcon
};
