import React from "react";
import PropTypes from "prop-types";
import LaunchIcon from '@material-ui/icons/Launch';
import {makeStyles} from "@material-ui/core/styles";
import {IconButton, Typography} from "@material-ui/core";
import {useTranslate} from "react-admin";
import config from "../config/config";

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
    },
    field: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
    },
});

//const BASE_URL = new URL(config.namespace.share.baseURL);

const shareLink = (record, lang) => `${config.namespace.share.baseURL}${record.id}?hl=${lang}`;

const ShareLinkField = function({ lang, record }) {
    const classes = useStyles();
    const translate = useTranslate();
    const shareUrl = shareLink(record, lang);

    return (
        <div className={classes.root}>
            <Typography
                color="textSecondary"
                variant="caption"
            >
                {translate('experience.field.shareUrl') + ` (${lang.toUpperCase()})`}
            </Typography>
            <div className={classes.field}>
                <IconButton
                    aria-label={translate('experience.field.shareUrl')}
                    href={shareUrl}
                    target="_blank"
                    rel="noreferrer"
                >
                    <LaunchIcon />
                </IconButton>
                <Typography
                    color="textPrimary"
                    variant="body1"
                >
                    {shareUrl}
                </Typography>
            </div>
        </div>
    );
};

ShareLinkField.propTypes = {
    lang: PropTypes.string.isRequired,
    record: PropTypes.object,
};

ShareLinkField.defaultProps = {
    record: {},
};

export default ShareLinkField;
