import React from 'react';
import ScanCreateToolbar from './ScanCreateToolbar';
import ScanForm from './ScanForm';
import {
    Create
} from 'react-admin';

const scanDefaultValue = {
    id: null,
    subject: null,
    code: null,
    locationId: null,
    location: null,
    lat: null,
    lng: null,
    createdAt: new Date(),
    expiresAt: new Date(),
};

const ScanCreate = (props) => (
    <Create {...props}>
        <ScanForm initialValues={scanDefaultValue} toolbar={<ScanCreateToolbar />} { ...props } />
    </Create>
);

export default ScanCreate;
