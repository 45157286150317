import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core/styles';
import { List, useListController, useTranslate } from 'react-admin';
import { useHistory } from 'react-router-dom';
import {formatBytes} from "../../util/bytes";
import PosterFilter from "./PosterFilter";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '1em',
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.77)',
    },
    titleBar: {
        background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
}));

const defaults = {
    offset: 0,
    limit: 10,
    pagination: [16, 24, 32],
    sort: {
        field: 'id',
        order: 'DESC'
    }
};

const PosterList = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const history = useHistory();
    const controllerProps = useListController(props);

    const { basePath, cols } = props;
    const { ids, data } = controllerProps;

    const handleClick = useCallback((id, record) => () => {
        history.push(`${basePath}/${id}/show`);
    }, [history, basePath]);

    return (
        <List
            filters={<PosterFilter />}
            perPage={defaults.limit}
            sort={defaults.sort}
            {...props}
        >
            <GridList className={classes.gridList} cols={cols}>
                {
                    ids.map((id) => {
                        const record = data[id];

                        return (
                            <GridListTile key={id} onClick={handleClick(id, record)}>
                                <img src={record.src} alt={record.name} />
                                <GridListTileBar
                                    title={record.name}
                                    subtitle={<span>{formatBytes(record.size)}</span>}
                                    classes={{
                                        root: classes.titleBar
                                    }}
                                    actionIcon={
                                        <IconButton
                                            aria-label={translate(`info about ${record.name}`)}
                                            className={classes.icon}
                                            onClick={handleClick(id, record)}
                                        >
                                            <InfoIcon />
                                        </IconButton>
                                    }
                                />
                            </GridListTile>
                        );
                    })
                }
            </GridList>
        </List>
    );
};

PosterList.propTypes = {
    cols: PropTypes.any.isRequired,
    onPoster: PropTypes.func.isRequired,
};

PosterList.defaultProps = {
    cols: 4,
};

export default PosterList;
