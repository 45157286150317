import ExperienceIcon from '@material-ui/icons/Label';
import ExperienceCreate from '../experience/ExperienceCreate';
import ExperienceEdit from "../experience/ExperienceEdit";
import ExperienceTabbedShow from "../experience/ExperienceTabbedShow";
import GifList from "./GifList";

export default {
    create: ExperienceCreate,
    edit: ExperienceEdit,
    list: GifList,
    show: ExperienceTabbedShow,
    icon: ExperienceIcon
};
