import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Chip } from '@material-ui/core';
import { useTranslate } from 'react-admin';

const useQuickFilterStyles = makeStyles((theme) => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));

const QuickFilter = ({ label }) => {
    const translate = useTranslate();
    const classes = useQuickFilterStyles();

    return <Chip className={classes.chip} label={translate(label)} />;
};

QuickFilter.propTypes = {
    label: PropTypes.string.isRequired,
};

export default QuickFilter;
