import React from 'react';
import SurveyCreateToolbar from './SurveyCreateToolbar';
import SurveyForm from './SurveyForm';
import {Create} from 'react-admin';

const SurveyCreate = (props) => (
    <Create {...props}>
        <SurveyForm toolbar={<SurveyCreateToolbar />} { ...props } />
    </Create>
);

export default SurveyCreate;
