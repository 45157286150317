import React from 'react';
import Chip from '@material-ui/core/Chip';

const UserRolesField = function({ record }) {
    const roles = record.roles || [];

    return (
        <div>
            { roles.map(role => (
                <Chip label={role.replace(/^ROLE_/, '')} />
            )) }
        </div>
    );
};

UserRolesField.defaultProps = {
    addLabel: true,
};

export default UserRolesField;
