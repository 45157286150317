import ProviderIcon from '@material-ui/icons/Label';
import ProviderCreate from './ProviderCreate';
import ProviderEdit from './ProviderEdit';
import ProviderList from './ProviderList';
import ProviderShow from './ProviderShow';

export default {
    create: ProviderCreate,
    edit: ProviderEdit,
    list: ProviderList,
    show: ProviderShow,
    icon: ProviderIcon
};
