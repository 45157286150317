import React from 'react';
import {
    FileInput,
    ImageField,
    NumberInput,
    SelectInput,
    SimpleForm,
    TextInput,
    minValue,
    maxLength,
    maxValue,
    required,
    useTranslate,
} from 'react-admin';
import config from "../../config/config";
import {a11yBase} from "../../util/a11y";

const validateKey = [required()];
const validateOrder = [required(), minValue(0), maxValue(99)];
const validateName = [required(), maxLength(255)];
const validatePoster = [required()];
const validateA11yLevel = [required(), minValue(0), maxValue(3)];

const storageUrlToFile = (src) => typeof src === 'string' ? ({ src, title: src }) : src;

const SettingForm = (props) => {
    const translate = useTranslate();

    return (
        <SimpleForm redirect="show" {...props}>
            <TextInput source="key" type="text" fullWidth helperText={translate('setting.help.key')} validate={validateKey} />
            <NumberInput source="order" fullWidth helperText={translate('setting.help.order')} validate={validateOrder} />
            <TextInput source="nameCA" type="text" fullWidth label={translate('setting.field.nameCA')} helperText={translate('setting.help.nameCA')} validate={validateName} />
            <TextInput source="nameES" type="text" fullWidth label={translate('setting.field.nameES')} helperText={translate('setting.help.nameES')} validate={validateName} />
            <SelectInput
                source="a11yLevel"
                choices={ a11yBase }
                fullWidth
                label={translate('setting.field.a11yLevel')}
                helperText={translate('setting.help.a11yLevel')}
                validate={validateA11yLevel}
                translateChoices
            />
            <FileInput source="poster" {...config.image} helperText={translate('setting.help.poster')} format={storageUrlToFile} validate={validatePoster}>
                <ImageField source="src" title="title" />
            </FileInput>
        </SimpleForm>
    );
}

export default SettingForm;
