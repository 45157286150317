import React from 'react';
import PosterCreateToolbar from './PosterCreateToolbar';
import PosterForm from './PosterForm';
import {
    Create
} from 'react-admin';

const posterDefaultValue = {
    id: null,
    src: null,
    type: null,
    size: 0,
    name: null,
    keywords: null,
    createdAt: new Date(),
};

const PosterCreate = (props) => (
    <Create {...props}>
        <PosterForm initialValues={posterDefaultValue} toolbar={<PosterCreateToolbar />} { ...props } />
    </Create>
);

export default PosterCreate;
