import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    label: {
        paddingLeft: '12px'
    },
    large: {
        width: theme.spacing(6),
        height: theme.spacing(6),
    },
    medium: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
}));

const sizeFor = size => size === 'large' ? 64 :
                        size === 'small' ? 32 : 24;

const providers = {
    adorable: (record, size) => `https://api.adorable.io/avatars/${sizeFor(size)}/${record ? record.id : 'miles'}.png`,
    gravatar: (record, size) => `https://www.gravatar.com/avatar/${record ? record.id : 'miles'}?d=retro`,
};

const AvatarField = function(props) {
    const classes = useStyles();
    const { addLabel, label, record, provider, secondary, size, source } = props;

    return record ? (
        <div className={classes.root}>
            <Avatar className={classes[size]} src={providers[provider](record, size)} />
            {
                addLabel ? (
                    <div className={classes.label}>
                        <Typography variant="body2">{label || record[source]}</Typography>
                        {
                            secondary ? (
                                <Typography color="textSecondary" variant="body2">{label || record[source]}</Typography>
                            ) : null
                        }
                    </div>
                ) : null
            }
        </div>
    ) : null;
};

AvatarField.propTypes = {
    addLabel: PropTypes.bool.isRequired,
    label: PropTypes.any,
    provider: PropTypes.string.isRequired,
    secondary: PropTypes.any,
    size: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
};

AvatarField.defaultProps = {
    addLabel: true,
    provider: 'adorable',
    size: 'medium',
    source: 'username',
};

export default AvatarField;
