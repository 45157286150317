import React from 'react';
import {NumberInput} from 'react-admin';

// Remember Mongo prefers an array with lng as first element
const LatLngInput = () => (
    <span>
        <NumberInput source="coordinates.1" label="latitude" min={0.0} max={90.0} />
        &nbsp;
        <NumberInput source="coordinates.0" label="longitude" min={-180.0} max={180.0} />
    </span>
);

export default LatLngInput;
