import React from 'react';
import AvatarField from '../../component/AvatarField';
import pure from 'recompose/pure';

const FullnameField = function({ record = {}, size }) {
    return (
        <div style={ { display: 'flex', flexWrap: 'nowrap', alignItems: 'center' } }>
            <AvatarField record={record} size={size} />
            &nbsp;{record.username}
        </div>
    );
};

const UserFullnameField = pure(FullnameField);

UserFullnameField.defaultProps = {
    source: 'last_name',
    label: 'resources.user.fields.name'
};

export default UserFullnameField;
