import React from 'react';
import { Edit } from 'react-admin';
import ExperienceTitle from './ExperienceTitle';
import ExperienceEditActions from './ExperienceEditActions';
import ExperienceTabbedForm from './ExperienceTabbedForm';

const ExperienceEdit = (props) => (
    <Edit actions={<ExperienceEditActions />} title={<ExperienceTitle />} {...props}>
        <ExperienceTabbedForm {...props } />
    </Edit>
);

export default ExperienceEdit;
