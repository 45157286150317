import React from 'react';
import BannerCreateToolbar from './BannerCreateToolbar';
import BannerForm from './BannerForm';
import { Create } from 'react-admin';

const bannerDefaultValue = {
};

const BannerCreate = (props) => (
    <Create {...props}>
        <BannerForm
            initialValues={bannerDefaultValue}
            toolbar={<BannerCreateToolbar />}
            { ...props }
        />
    </Create>
);

export default BannerCreate;
