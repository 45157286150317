import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-admin';
import Icon from '@material-ui/icons/List';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

class BackToListButton extends Component {

    handleClick = () => {
        const { push, resource } = this.props;
        push(`/${resource}`);
    };

    render() {
        const { label } = this.props;

        return (
            <Button label={label} onClick={this.handleClick}>
                <Icon />
            </Button>
        );
    }
}

BackToListButton.propTypes = {
    resource: PropTypes.string.isRequired,
    label: PropTypes.string,
    push: PropTypes.func,
};

BackToListButton.defaultProps = {
    label: 'Back to List',
};

export default connect(undefined, { push })(BackToListButton);
