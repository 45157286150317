import SettingIcon from '@material-ui/icons/Subway';
import SettingCreate from './SettingCreate';
import SettingEdit from './SettingEdit';
import SettingList from './SettingList';
import SettingShow from './SettingShow';

export default {
    create: SettingCreate,
    edit: SettingEdit,
    list: SettingList,
    show: SettingShow,
    icon: SettingIcon
};
