import React from 'react';
//import QuickFilter from '../../component/QuickFilter';
import enums from './enums';
import {
    Filter,
    ReferenceInput,
    SearchInput,
    SelectInput
} from 'react-admin';

// You can choose whether you use conventional filters or quick filters.
// Users usually dislike using their keyboard to filter a list (especially
// on mobile). A good way to satisfy this user requirement is to turn
// filters into quick filter. A Quick filter is a filter with a non-
// editable defaultValue. Users can only enable or disable them.

// Note it's not possible to use two quick filters for the same source.
// So don't try something like:
//
//  <Filter ...>
//    ...
//    <StatusFailedQuickFilter />
//    <StatusSuccessQuickFilter />
//  </Filter>

/*
const ModeAutoQuickFilter = (props) => (
    <QuickFilter label="AUTO" source="mode" defaultValue={ "AUTO" } />
);
const ModeManualQuickFilter = (props) => (
    <QuickFilter label="MANUAL" source="mode" defaultValue={ "MANUAL" } />
);
const StatusFailedQuickFilter = (props) => (
    <QuickFilter label="FAILED" source="status" defaultValue={ "FAILED" } />
);
const StatusSuccessQuickFilter = (props) => (
    <QuickFilter label="SUCCESS" source="status" defaultValue={ "SUCCESS" } />
);
 */

const UpdateFilter = function(props) {
    return (
        <Filter {...props}>
            <SearchInput source="q" alwaysOn />
            <SelectInput source="mode" choices={ enums.updateMode } />
            <SelectInput source="status" choices={ enums.updateStatus } />
            <ReferenceInput label="user" source="user" reference="user" allowEmpty>
                <SelectInput optionText="id" />
            </ReferenceInput>
            <ReferenceInput label="provider" source="provider" reference="provider" allowEmpty>
                <SelectInput optionText="id" />
            </ReferenceInput>
        </Filter>
    );
};

export default UpdateFilter;
