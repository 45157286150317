import React from 'react';
import ProviderCreateToolbar from './ProviderCreateToolbar';
import ProviderForm from './ProviderForm';
import {
    Create
} from 'react-admin';

const providerDefaultValue = {
    id: null,
    enabled: true,
    name: null,
    description: null,
    type: "MEME",
    status: "PENDING_MODERATION",
    source: null,
    sourceUpdateFrequency: null,
    categories: null,
    updateMode: "MANUAL",
    updateFrequency: null,
    updateWorker: null,
    experienceCount: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
    lastUpdatedAt: new Date(),
    lastUpdateMode: "MANUAL",
    lastUpdateCount: null,
};

const ProviderCreate = (props) => (
    <Create {...props}>
        <ProviderForm initialValues={providerDefaultValue} toolbar={<ProviderCreateToolbar />} { ...props } />
    </Create>
);

export default ProviderCreate;
