import React, { useState, useEffect, useCallback } from "react";
import { useVersion, useDataProvider } from "react-admin";
import { useMediaQuery } from "@material-ui/core";
import RecentlyUpdated from "./RecentlyUpdated";
import RecentlyScanned from "./RecentlyScanned";
import RecentlyRated from "./RecentlyRated";

const styles = {
    flex: {
        display: "flex",
    },
    flexColumn: {
        display: "flex",
        flexDirection: "column",
    },
    leftCol: {
        flex: 1,
        marginRight: "0.5em",
    },
    rightCol: {
        flex: 1,
        marginLeft: "0.5em",
    },
    singleCol: {
        marginTop: "1em",
        marginBottom: "1em",
    },
};

// const Spacer = () => <span style={{ width: "1em" }} />;

const VerticalSpacer = () => <span style={{ height: "1em" }} />;

const Dashboard = () => {
    const [state, setState] = useState({
        ratings: [],
        scans: [],
        lastRating: null,
        lastScan: null,
        nbUpdates: null,
        updates: [],
    });

    const version = useVersion();
    const dataProvider = useDataProvider();
    const isXsmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));

    const fetchData = useCallback(async () => {
        const { data: scans } = await dataProvider.getList("scan", {
            pagination: { page: 1, perPage: 20 },
            sort: { field: "id", order: "DESC" },
        });

        const { data: updates } = await dataProvider.getList("update", {
            pagination: { page: 1, perPage: 5 },
            sort: { field: "id", order: "DESC" },
        });

        const { data: ratings } = await dataProvider.getList("rating", {
            pagination: { page: 1, perPage: 10 },
            sort: { field: "id", order: "DESC" },
        });

        const lastRating = ratings.length ? ratings[0].createdAt : null;
        const lastScan   = scans.length ? scans[0].createdAt : null;

        setState({ ...state, ratings, scans, updates, lastRating, lastScan, nbUpdates: updates.length });
    }, [dataProvider, setState, state]);

    useEffect(() => {
        fetchData();
    }, [version]);

    const { ratings, scans, lastRating, lastScan, nbUpdates, updates } = state;

    return isXsmall ? (
        <div>
            <div style={styles.flexColumn}>
                <RecentlyScanned scans={scans} lastScan={lastScan} />
                <VerticalSpacer />
                <RecentlyUpdated updates={updates} nbUpdates={nbUpdates} />
            </div>
        </div>
    ) : (
        <>
            <div style={styles.flex}>
                <div style={styles.leftCol}>
                    <div style={styles.singleCol}>
                        <RecentlyScanned scans={scans} lastScan={lastScan} />
                    </div>
                </div>
                <div style={styles.rightCol}>
                    <div style={styles.singleCol}>
                        <RecentlyUpdated updates={updates} nbUpdates={nbUpdates} />
                        <RecentlyRated ratings={ratings} lastRating={lastRating} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dashboard;
