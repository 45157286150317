import React from 'react';
import { Edit } from 'react-admin';
import UpdateTitle from './UpdateTitle';
import UpdateEditActions from './UpdateEditActions';
import UpdateForm from './UpdateForm';

const UpdateEdit = (props) => (
    <Edit actions={<UpdateEditActions />} title={<UpdateTitle />} {...props}>
        <UpdateForm {...props } />
    </Edit>
);

export default UpdateEdit;
