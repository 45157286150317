import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import {Link} from "react-router-dom";
import {useTranslate} from 'react-admin';
import {Button, Chip, Typography} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import LaunchIcon from '@material-ui/icons/Launch';
import LocationIcon from '@material-ui/icons/Room';
import { blue, green, red } from '@material-ui/core/colors';
import {makeStyles} from "@material-ui/core/styles";
import {gmapUrl} from "../../util/geo";

const useStyles = makeStyles({
    root: {},
    media: {
        height: 0,
        paddingTop: '56.25%',
    },
    rightButton: {
        marginLeft: 'auto',
    },
    avatarBlue: {
        backgroundColor: blue[500],
    },
    avatarGreen: {
        backgroundColor: green[500],
    },
    avatarRed: {
        backgroundColor: red[500],
    },
    locationInfo: {
        display: "flex",
        alignItems: "center",
    },
    locationSpan: {
        paddingLeft: "8px",
    }
});

const experienceTypeToClassName = (type) => {
    if (type === 'PROMO') {
        return 'avatarRed';
    }

    if (type === 'EVENT') {
        return 'avatarGreen';
    }

    return 'avatarBlue';
};

const selectMedia = (record) => {
    if (! record) {
        return null;
    }

    return (/^image\//).test(record.mediaType) ? record.media : record.posterCA;
};

const linkTo = (record, lang) => {
    return record ? record.externalLink ? record.externalLink.replace('{lang}', lang) : '#' : '#';
};

const ScanExperienceCard = (props) => {
    const classes = useStyles();
    const translate = useTranslate();

    const { record } = props;
    const title = record.nameCA || translate('experience.label.noName');
    const capitalType = record.type.charAt(0).toUpperCase();
    const link = linkTo(record, 'ca');

    const isAudio = record && record.type === 'AUDIO';
    const isVideo = record && record.type === 'VIDEO';
    //const isImage = record && (record.type === 'GIF' || record.type === 'MEME');

    return record ? (
        <Card className={classes.root}>
            <CardHeader
                avatar={
                    <Avatar
                        aria-label={translate('experience.field.type')}
                        className={classes[experienceTypeToClassName(record.type)]}
                    >{capitalType}</Avatar>
                }
                action={
                    record.externalLink ? (
                        <IconButton
                            aria-label={translate('experience.field.link')}
                            href={link}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <LaunchIcon />
                        </IconButton>
                    ) : null
                }
                title={title}
                subheader={record.author}
            />
            {
                isAudio ? (
                    <audio controls src={record.media} />
                ) : isVideo ? (
                    <video controls src={record.media} />
                ) : (
                    <CardMedia
                        className={classes.media}
                        image={selectMedia(record)}
                        title={record.nameCA}
                    />
                )
            }
            <CardContent>
                {
                    record && typeof record.lat === 'number' && typeof record.lng === 'number' ? (
                        <div className={classes.locationInfo}>
                            <IconButton
                                aria-label="See on Google Maps"
                                href={gmapUrl(record.lat, record.lng)}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <LocationIcon />
                            </IconButton>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                                className={classes.locationSpan}
                            >
                                {record.location}
                            </Typography>
                        </div>
                    ) : null
                }
                <Chip label={translate(`releaseCause.field.${record.releaseCause}`)} />
            </CardContent>
            <CardActions disableSpacing>
                <Button
                    color="primary"
                    component={Link}
                    size="small"
                    to={`/experience/${record.experienceId}`}
                >
                    {translate('scan.field.linkToExperience')}
                </Button>
            </CardActions>
        </Card>
    ) : null;
};

ScanExperienceCard.props = {
    record: PropTypes.object.isRequired,
};

ScanExperienceCard.defaultProps = {
    record: {},
};

export default ScanExperienceCard;
