import React from "react";
import UpdateIcon from "@material-ui/icons/Update";
import {Avatar, Chip, Link, List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, makeStyles} from "@material-ui/core";
import {blue, blueGrey} from "@material-ui/core/colors";
import {useTranslate} from "react-admin";
import CardWithIcon from "./CardWithIcon";

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(2),
    },
    listItemText: {
        overflowY: 'hidden',
        display: '-webkit-box',
        paddingRight: 0,
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
    },
    avatarGroup: {
        paddingRight: theme.spacing(2),
    },
    blue: {
        color: '#fff',
        backgroundColor: blue[500],
    },
    red: {
        color: '#fff',
        backgroundColor: blueGrey[500],
    }
}));

const providerName = (statusText) => {
    const match = (/(\w+) provider/).exec(statusText);

    return match ? match[1] : '';
};

const RecentlyUpdated = ({ updates = {}, nbUpdates }) => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <CardWithIcon
            to="/update"
            icon={UpdateIcon}
            title={translate('admin.lastUpdates')}
            subtitle={nbUpdates || '0'}
            className={classes.root}
        >
            {
                updates.length ? (
                    <List>
                        {updates.map((record) => (
                            <ListItem
                                key={record.id}
                                button
                                component={Link}
                                to={`/update/${record.id}`}
                                alignItems="flex-start"
                            >
                                <ListItemAvatar>
                                    <Avatar>M</Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={providerName(record.statusText)}
                                    secondary={record.statusText}
                                    className={classes.listItemText}
                                />
                                <ListItemSecondaryAction>
                                    <Chip label={record.experienceCount} />
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                ) : null
            }
        </CardWithIcon>
    );
};

export default RecentlyUpdated;
