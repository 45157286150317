import decodeJwt from 'jwt-decode';
import {wellKnownURL} from "../util/uri";

const STORE_KEY_JWT = 'authn.jwt';
const STORE_KEY_IAT = 'authn.iat';
const STORE_KEY_EXP = 'authn.exp';
const STORE_KEY_ROL = 'authn.rol';
const STORE_KEY_SUB = 'authn.sub';

const setCredentials = function(token) {
    const decodedToken = decodeJwt(token);

    localStorage.setItem(STORE_KEY_JWT, token);
    localStorage.setItem(STORE_KEY_IAT, String(1000 * decodedToken.iat));
    localStorage.setItem(STORE_KEY_EXP, String(1000 * decodedToken.exp));
    localStorage.setItem(STORE_KEY_ROL, JSON.stringify(decodedToken.rol));
    localStorage.setItem(STORE_KEY_SUB, decodedToken.sub);
};

const clearCredentials = function() {
    localStorage.removeItem(STORE_KEY_JWT);
    localStorage.removeItem(STORE_KEY_IAT);
    localStorage.removeItem(STORE_KEY_EXP);
    localStorage.removeItem(STORE_KEY_ROL);
    localStorage.removeItem(STORE_KEY_SUB);
};

const authProvider = {
    // Called when the user attempts to log in
    login: async (params) => {
        const { username, password } = params;
        const request = new Request(wellKnownURL('login'), {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });

        const response = await fetch(request);

        if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
        }

        const { token } = await response.json();

        setCredentials(token);
    },
    // Called when the user clicks on the logout button
    logout: (params) => {
        clearCredentials();

        return Promise.resolve();
    },
    // Called when the user navigates to a new location
    checkAuth: (params) => {
        const token = localStorage.getItem(STORE_KEY_JWT);

        if (! token) {
            return Promise.reject();
        }

        const expiresAt = localStorage.getItem(STORE_KEY_EXP);

        if (! expiresAt || Date.now() > parseInt(expiresAt, 10)) {
            clearCredentials();

            return Promise.reject();
        }

        if (! localStorage.getItem(STORE_KEY_SUB) ||
            ! localStorage.getItem(STORE_KEY_ROL)) {
            return Promise.reject();
        }

        return Promise.resolve();
    },
    // Called when the API returns an error
    checkError: (params) => {
        const { status } = params;

        if (status === 403) {
            return Promise.resolve();
        }

        if (status === 401) {
            clearCredentials();

            return Promise.reject('Unauthorized');
        }

        return Promise.resolve();
    },
    // Called when the user navigates to a new location
    getPermissions: (params) => {
        const roleData = localStorage.getItem(STORE_KEY_ROL);

        return roleData ? Promise.resolve(JSON.parse(roleData)) : Promise.reject();
    }
};

export default authProvider;
