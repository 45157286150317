import React from 'react';
import BackToListButton from '../../component/BackToListButton';
import {
    SaveButton,
    Toolbar
} from 'react-admin';

const UpdateCreateToolbar = function(props) {
    return <Toolbar {...props} >
        <SaveButton label="Save" redirect="list" submitOnEnter={true} />
        <BackToListButton label="Back to list" />
    </Toolbar>
};

export default UpdateCreateToolbar;
