import React from 'react';
import SurveyShowActions from './SurveyShowActions';
import SurveyTitle from './SurveyTitle.js';
import {
    DateField,
    Show,
    SimpleShowLayout,
    TextField
} from 'react-admin';

const SurveyShow = (props) => (
    <Show actions={<SurveyShowActions/>} title={<SurveyTitle />} {...props}>
        <SimpleShowLayout>
            <TextField source="title" />
            <TextField source="hash" />
            <DateField source="updatedAt" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}  />
        </SimpleShowLayout>
    </Show>
);

export default SurveyShow;
