import React from 'react';
import AvatarField from '../../component/AvatarField';
import RatingShowActions from './RatingShowActions';
import RatingTitle from './RatingTitle.js';
import {
    DateField,
    NumberField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField
} from 'react-admin';

const RatingShow = (props) => (
    <Show actions={<RatingShowActions/>} title={<RatingTitle />} {...props}>
        <SimpleShowLayout>
            <ReferenceField label="user" source="user.id" reference="user" link="show">
                <AvatarField source="username" />
            </ReferenceField>
            <ReferenceField label="experience" source="experience.id" reference="experience" link="show">
                <TextField source="id" />
            </ReferenceField>
            <TextField source="fingerprint" />
            <TextField source="subject" />
            <NumberField source="rating" textAlign="right" />
            <DateField source="createdAt" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}  />
        </SimpleShowLayout>
    </Show>
);

export default RatingShow;
