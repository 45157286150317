import React from 'react';
import {makeStyles, useTheme} from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";
import ExperienceFilter from "./ExperienceFilter";
import ExperienceEmpty from "./ExperienceEmpty";
import {
    BooleanField,
    ChipField,
    Datagrid,
    DateField,
    List,
    ReferenceArrayField,
    Responsive,
    SimpleList,
    SingleFieldList,
    TextField
} from 'react-admin';
import ExperienceBulkActionButtons from "./ExperienceBulkActionButtons";
import ScalarArrayField from "../../component/ScalarArrayField";

const defaults = {
    offset: 0,
    limit: 32,
    sort: {
        field: 'id',
        order: 'DESC'
    }
};

const rowStyle = (selectedRow, theme) => (record) => {
    let style = {};

    if (selectedRow === record.id) {
        style = {
            ...style,
            backgroundColor: theme.palette.action.selected,
        };
    }

    if (record.status === 'PENDING_MODERATION') {
        return {
            ...style,
            borderLeftColor: blue[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    }

    if (record.status === 'APPROVED') {
        return {
            ...style,
            borderLeftColor: green[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    }

    if (record.status === 'SUSPENDED') {
        return {
            ...style,
            borderLeftColor: orange[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    }

    if (record.status === 'REJECTED') {
        return {
            ...style,
            borderLeftColor: red[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    }

    return style;
};

const useListStyles = makeStyles({
    headerRow: {
        borderLeftColor: 'white',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    },
    headerCell: {
        padding: '6px 8px 6px 8px',
    },
    rowCell: {
        padding: '6px 8px 6px 8px',
    },
    comment: {
        maxWidth: '18em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

const ExperienceList = (props) => {
    const classes = useListStyles();
    const theme = useTheme();

    const { selectedRow } = props;

    return (
        <List
            bulkActionButtons={<ExperienceBulkActionButtons />}
            empty={<ExperienceEmpty />}
            filters={<ExperienceFilter />}
            perPage={defaults.limit}
            sort={defaults.sort}
            {...props}
        >
            <Responsive
                small={
                    <SimpleList
                        primaryText={ record => record ? record.nameCA : null }
                        secondaryText={ record => record ? record.id : null }
                        tertiaryText={ record => record ? (record.type === 'MEME' ? 'JPEG' : record.type) : null }
                    />
                }
                medium={
                    <Datagrid
                        rowClick="show"
                        rowStyle={rowStyle(selectedRow, theme)}
                        classes={{
                            headerRow: classes.headerRow,
                            headerCell: classes.headerCell,
                            rowCell: classes.rowCell,
                        }}
                        optimized
                        {...props}
                    >
                        <DateField source="notBefore" />
                        <TextField source="nameCA" />
                        <BooleanField source="topRated" />
                        <BooleanField source="releaseForced" />
                        <BooleanField source="releaseConstrained" />
                        <ScalarArrayField source="releaseWithTags" />
                        <ScalarArrayField source="releaseInDistricts" />
                        <ReferenceArrayField reference="releaseInStations" source="station">
                            <SingleFieldList>
                                <ChipField source="description" />
                            </SingleFieldList>
                        </ReferenceArrayField>
                        <ScalarArrayField source="releaseInLines" />
                        <TextField source="status" />
                    </Datagrid>
                }
            />
        </List>
    );
};

export default ExperienceList;
