import React from 'react';
import {Filter, SearchInput} from 'react-admin';

const UserFilter = function(props) {
    return (
        <Filter {...props}>
            <SearchInput source="q" alwaysOn />
        </Filter>
    );
};

export default UserFilter;
