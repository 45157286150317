import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import HotTub from '@material-ui/icons/HotTub';
import History from '@material-ui/icons/History';
import classnames from 'classnames';
import { useAuthenticated, useTranslate } from 'ra-core';
import { CreateButton } from 'react-admin';

const useStyles = makeStyles(theme => ({
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            [theme.breakpoints.up('md')]: {
                height: '100%',
            },
            [theme.breakpoints.down('sm')]: {
                height: '100vh',
                marginTop: '-3em',
            },
        },
        icon: {
            width: '9em',
            height: '9em',
        },
        message: {
            textAlign: 'center',
            fontFamily: 'Roboto, sans-serif',
            opacity: 0.5,
            margin: '0 1em',
        },
        toolbar: {
            textAlign: 'center',
            marginTop: '2em',
        },
    }),
    { name: 'Empty' }
);

const sanitizeRestProps = ({
    staticContext,
    history,
    location,
    match,
...rest
}) => rest;

const ExperienceEmpty = props => {
    const classes = useStyles(props);
    const translate = useTranslate();
    const { basePath, className, classes: classesOverride, title, ...rest } = props;

    useAuthenticated();

    const goBack = () => {
        window.history.go(-1);
    };

    return (
        <div className={classnames(classes.container, className)} {...sanitizeRestProps(rest)}>
            <div className={classes.message}>
                <HotTub className={classes.icon} />
                <h1>{translate('experience.field.empty')}</h1>
                <div>{translate('experience.help.empty')}.</div>
            </div>
            <div className={classes.toolbar}>
                <Button variant="contained" icon={<History />} onClick={goBack}>
                    {translate('admin.action.back')}
                </Button>
                &nbsp;
                <CreateButton color="primary" size="medium" variant="contained" basePath={basePath} />
            </div>
        </div>
    );
};

ExperienceEmpty.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    title: PropTypes.string,
    location: PropTypes.object,
};

export default ExperienceEmpty;
