import React from 'react';
import OptionCreateToolbar from './OptionCreateToolbar';
import OptionForm from './OptionForm';
import { Create } from 'react-admin';

const optionDefaultValue = {
    id: null,
    key: null,
    value: null,
};

const OptionCreate = (props) => (
    <Create {...props}>
        <OptionForm
            initialValues={optionDefaultValue}
            toolbar={<OptionCreateToolbar />} 
            { ...props } 
        />
    </Create>
);

export default OptionCreate;
