import React from 'react';
import ExperienceCreateToolbar from './ExperienceCreateToolbar';
import ExperienceTabbedForm from './ExperienceTabbedForm';
import {
    Create
} from 'react-admin';

const experienceDefaultValue = {
    id: null,
    type: "MEME",
    status: "PENDING_MODERATION",
    link: null,
    mediaType: null,
    providerId: null,
    phone: null,
    author: null,
    license: null,
    poster: null,
    nameCA: null,
    nameES: null,
    descriptionCA: null,
    descriptionES: null,
    category: null,
    subcategory: null,
    tags: null,
    notBefore: new Date(),
    expiresAt: null,
    weekdays: null,
    monthdays: null,
    nextDate: null,
    coordinates: null,
    zoomLevel: null,
    venue: null,
    address: null,
    district: null,
    city: null,
    rating: null,
    ratingOverride: null,
    topRated: false,
    createdAt: new Date(),
    updatedAt: new Date(),
    commentsOpen: true,
};

const ExperienceCreate = (props) => (
    <Create {...props}>
        <ExperienceTabbedForm initialValues={experienceDefaultValue} toolbar={<ExperienceCreateToolbar />} { ...props } />
    </Create>
);

export default ExperienceCreate;
