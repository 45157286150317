import React from 'react';
import RatingCreateToolbar from './RatingCreateToolbar';
import RatingForm from './RatingForm';
import {
    Create
} from 'react-admin';

const ratingDefaultValue = {
    id: null,
    fingerprint: null,
    subject: null,
    rating: null,
    createdAt: new Date(),
};

const RatingCreate = (props) => (
    <Create {...props}>
        <RatingForm initialValues={ratingDefaultValue} toolbar={<RatingCreateToolbar />} { ...props } />
    </Create>
);

export default RatingCreate;
