import {HttpError} from "react-admin";

const SUCCESS = [200, 201, 202, 204];

const addHeaders = (options) => {
    if (! options.headers) {
        options.headers = new Headers();
    }

    if (! options.headers.has('Accept')) {
        options.headers.set('Accept', 'application/json');
    }

    if (! options.headers.has('Content-Type') &&
        !( options && (! options.method || options.method === 'GET')) &&
        !( options && options.body && options.body instanceof FormData)) {
        options.headers.set('Content-Type', 'application/json');
    }

    if (options.user && options.user.authenticated && options.user.token) {
        options.headers.set('Authorization', options.user.token);
    }
};

const fetchJson = async (url, options) => {
    if (! options) {
        options = {};
    }

    addHeaders(options);

    const response = await fetch(url, options);
    const responseText = await response.text();

    let json;

    try {
        json = JSON.parse(responseText);
    } catch (e) {
        // not json, no big deal
    }

    if (-1 === SUCCESS.indexOf(response.status)) {
        throw new HttpError((json && json.detail) || response.statusText, response.status, json);
    }

    return {
        status: response.status,
        headers: response.headers,
        body: response.body,
        json: json
    };
};

export {
    fetchJson,
}
