import React, {useCallback, useEffect, useState} from 'react';
import LinearProgress from "@material-ui/core/LinearProgress";
import { SelectInput, useDataProvider, useVersion } from 'react-admin';

let choiceCache;

const SelectCategoryInput = (props) => {
    const dataProvider = useDataProvider();
    const version = useVersion();
    const [choices, setChoices] = useState(choiceCache);

    const fetchSettings = useCallback(async () => {
        const { data } = await dataProvider.getList('setting', {
            sort: { field: 'key', order: 'ASC' }
        });

        choiceCache = data.map(item => ({
            id: item.key,
            name: item.key,
        }));

        setChoices(choiceCache);
    }, [dataProvider]);

    useEffect(() => {
        fetchSettings();
    }, [version, fetchSettings]);

    return choices ? (
        <SelectInput
            resettable
            choices={choices}
            {...props}
        />
    ) : (
        <LinearProgress />
    );
};

SelectCategoryInput.defaultProps = {
    addLabel: true,
};

export default SelectCategoryInput;
