import React from 'react';
import { Edit } from 'react-admin';
import ProviderTitle from './ProviderTitle';
import ProviderEditActions from './ProviderEditActions';
import ProviderForm from './ProviderForm';

const ProviderEdit = (props) => (
    <Edit actions={<ProviderEditActions />} title={<ProviderTitle />} {...props}>
        <ProviderForm {...props } />
    </Edit>
);

export default ProviderEdit;
