import PosterIcon from '@material-ui/icons/Label';
import PosterCreate from './PosterCreate';
import PosterEdit from './PosterEdit';
import PosterList from './PosterList';
import PosterShow from './PosterShow';

export default {
    create: PosterCreate,
    edit: PosterEdit,
    list: PosterList,
    show: PosterShow,
    icon: PosterIcon
};
