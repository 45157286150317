import React from 'react';
import { AppBar, UserMenu, MenuItemLink, translate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import { withStyles } from '@material-ui/core/styles';

import Logo from './Logo';

const styles = {
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    spacer: {
        flex: 1
    }
};

const CustomUserMenu = translate(function({ translate, ...props }) {
    return <UserMenu {...props}>
        <MenuItemLink to="/settings" primaryText={translate('admin.settings')} leftIcon={<SettingsIcon />} />
    </UserMenu>
});

const CustomAppBar = function({ classes, ...props }) {
    return <AppBar {...props} userMenu={<CustomUserMenu />}>
        <Typography variant="h6" color="inherit" className={classes.title} id="react-admin-title" />
        <Logo />
        <span className={classes.spacer} />
    </AppBar>
};

export default withStyles(styles)(CustomAppBar);
