import React from 'react';
import enums from './enums';
import {
    BooleanInput,
    NumberInput,
    RadioButtonGroupInput,
    SelectInput,
    SimpleForm,
    TextInput,
    required,
    minLength,
    maxLength,
    useTranslate
} from 'react-admin';

const validateProvider = (values) => {
    const errors = {};

    if (values.updateMode === 'AUTO') {
        if (! values.source) {
            errors.source = ['Required if provider is updated automatically (update mode is AUTO)'];
        }
        if (! values.updateFrequency) {
            errors.updateFrequency = ['Required if provider is updated automatically (update mode is AUTO)'];
        }
    }

    return errors;
};

const validateName = [required(), minLength(2), maxLength(32)];
const validateDescription = [required(), maxLength(255)];
const validateWorker = [maxLength(64)];

const ProviderForm = (props) => {
    const translate = useTranslate();

    return (
        <SimpleForm redirect="show" validate={validateProvider} {...props}>
            <BooleanInput source="enabled" helperText={translate('provider.help.enabled')} />
            <TextInput source="name" type="text" fullWidth validate={validateName} />
            <TextInput source="description" type="text" fullWidth validate={validateDescription} />
            <RadioButtonGroupInput source="type" fullWidth choices={ enums.experienceType } />
            <SelectInput source="status" helperText={translate('provider.help.status')} choices={ enums.experienceStatus } />
            <TextInput source="source" type="text" fullWidth helperText={translate('provider.help.source')} />
            <TextInput source="sourceUpdateFrequency" type="text" fullWidth helperText={translate('provider.help.sourceUpdateFrequency')} />
            <TextInput source="categories" type="text" fullWidth />
            <RadioButtonGroupInput source="updateMode" fullWidth choices={ enums.updateMode } />
            <TextInput source="updateFrequency" type="text" fullWidth helperText={translate('provider.help.updateFrequency')} />
            <TextInput source="updateWorker" type="text" fullWidthhelperText={translate('provider.help.worker')} validate={validateWorker} />
            <NumberInput source="experienceCount" textAlign="right"  />
        </SimpleForm>
    );
};

export default ProviderForm;
