import React, {useCallback, useEffect, useState} from "react";
import {SelectInput, useDataProvider, useVersion} from "react-admin";

const SelectLineInput = (props) => {
    const dataProvider = useDataProvider();
    const version = useVersion();
    const [choices, setChoices] = useState([]);

    const fetchLines = useCallback(async () => {
        const { data: lines } = await dataProvider.getList('line', {
            sort: { field: 'key', order: 'ASC' },
        });

        setChoices(
            lines.map(line => ({
                id: line,
                name: line,
            }))
        );
    }, [dataProvider]);

    useEffect(() => {
        fetchLines();
    }, [version, fetchLines]);

    return (
        <SelectInput
            choices={choices}
            translateChoices={false}
            {...props}
        />
    );
};

SelectLineInput.defaultProps = {
    source: 'line',
};

export default SelectLineInput;
