import React from 'react';
import PropTypes from 'prop-types';
import {ImageField, UrlField} from 'react-admin';

const ExperienceMediaField = (props) => {
    const { source, record }  = props;

    const isAudio = record && record.type === 'AUDIO';
    const isVideo = record && record.type === 'VIDEO';
    const isImage = record && (record.type === 'GIF' || record.type === 'MEME');

    if (isAudio) {
        return <audio controls src={record[source]} />;
    }

    if (isVideo) {
        return <video controls src={record[source]} />;
    }

    if (isImage) {
        return <ImageField source={source} {...props} />;
    }

    return <UrlField source={source} {...props} />;
};

ExperienceMediaField.propTypes = {
    addLabel: PropTypes.bool,
    source: PropTypes.string,
};

ExperienceMediaField.defaultProps = {
    addLabel: true,
    source: 'media',
};

export default ExperienceMediaField;
