import React from 'react';
import SettingCreateToolbar from './SettingCreateToolbar';
import SettingForm from './SettingForm';
import { Create } from 'react-admin';

const settingDefaultValue = {
    id: null,
    key: null,
    value: null,
    a11yLevel: 3,
};

const SettingCreate = (props) => (
    <Create {...props}>
        <SettingForm initialValues={settingDefaultValue} toolbar={<SettingCreateToolbar />} { ...props } />
    </Create>
);

export default SettingCreate;
