import React from 'react';
import compose from 'recompose/compose';
import MuiGridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { Link } from 'react-router-dom';
import { linkToRecord } from 'ra-core';
import {formatBytes} from "../util/bytes";

const styles = function(theme) {
    return ({
        root: {
            margin: '-2px',
        },
        gridList: {
            width: '100%',
            margin: 0,
        },
        tileBar: {
            background:
                'linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)',
        },
        placeholder: {
            backgroundColor: theme.palette.grey[300],
            height: '100%',
        },
        price: {
            display: 'inline',
            fontSize: '1em',
        },
        link: {
            color: '#fff',
        },
    });
};

const getColsForWidth = width => {
    if (width === 'xs') {
        return 2;
    }
    if (width === 'sm') {
        return 3;
    }
    if (width === 'md') {
        return 4;
    }
    if (width === 'lg') {
        return 5;
    }
    return 6;
};

const times = function(nbChildren, fn) {
    return Array.from({ length: nbChildren }, (_, key) => fn(key));
};

const LoadingGridList = function({ width, classes, nbItems = 10 }) {
    return (
        <div className={classes.root}>
            <MuiGridList cellHeight={180} cols={getColsForWidth(width)} className={classes.gridList}>
                {' '}
                {
                    times(nbItems, key => (
                        <GridListTile key={key}>
                    <div className={classes.placeholder} />
                    </GridListTile>
                    ))
                }
            </MuiGridList>
        </div>
    );
};

const LoadedGridList = function({ classes, ids, data, basePath, width }) {
    return (
        <div className={classes.root}>
            <MuiGridList cellHeight={180} cols={getColsForWidth(width)} className={classes.gridList}>
                {ids.map(id => (
                    <GridListTile component={Link} key={id} to={linkToRecord(basePath, data[id].id)}>
                    <img src={data[id].preview} alt="" />
                    <GridListTileBar className={classes.tileBar} title={data[id].file.name} subtitle={
                    <span>
                {data[id].file.type},{' '}{formatBytes(data[id].file.size)}
                    </span>
                } />
                    </GridListTile>
                    ))}
            </MuiGridList>
        </div>
    );
};

const GridList = function({ loaded, loading, ...props }) {
    return loaded ? <LoadedGridList {...props} /> : <LoadingGridList {...props} />;
};

const enhance = compose(withWidth(), withStyles(styles));

export default enhance(GridList);
