import OptionIcon from '@material-ui/icons/Tune';
import OptionCreate from "./OptionCreate";
import OptionEdit from './OptionEdit';
import OptionList from './OptionList';

export default {
    create: OptionCreate,
    edit: OptionEdit,
    list: OptionList,
    icon: OptionIcon
};
