import React from 'react';
import BackToListButton from '../../component/BackToListButton';
import {
    SaveButton,
    Toolbar
} from 'react-admin';

const BannerCreateToolbar = function(props) {
    return <Toolbar {...props} >
        <SaveButton label="Save" redirect="show" submitOnEnter={true} />
        <SaveButton label="Save and add" redirect={false} submitOnEnter={false} variant="flat" />
        <BackToListButton label="Back to list" />
    </Toolbar>
};

export default BannerCreateToolbar;
