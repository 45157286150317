import React from 'react';
import { TextInput, SimpleForm, required, useTranslate } from 'react-admin';

const validateInput = [required()];

const OptionForm = (props) => {
    const translate = useTranslate();

    return (
        <SimpleForm redirect="list" {...props}>
            <TextInput
                fullWidth
                source="key"
                label={translate('option.field.key')}
                helperText={translate('option.help.key')}
                validate={validateInput}
            />
            <TextInput
                fullWidth
                source="group"
                label={translate('option.field.group')}
                helperText={translate('option.help.group')}
                validate={validateInput}
            />
            <TextInput
                fullWidth
                source="value"
                label={translate('option.field.value')}
                helperText={translate('option.help.value')}
                validate={validateInput}
            />
        </SimpleForm>
    );
}

export default OptionForm;
