import React from "react";
import {SelectArrayInput} from "react-admin";

const WeekdaysInput = (props) => (
    <SelectArrayInput {...props} translateChoices={true} />
);

WeekdaysInput.defaultProps = {
    source: 'weekdays',
    choices: [
        { id: 1, name: 'weekday.short.mon' },
        { id: 2, name: 'weekday.short.tue' },
        { id: 3, name: 'weekday.short.wed' },
        { id: 4, name: 'weekday.short.thu' },
        { id: 5, name: 'weekday.short.fri' },
        { id: 6, name: 'weekday.short.sat' },
        { id: 0, name: 'weekday.short.sun' },
    ]
};

export default WeekdaysInput;
