import React from 'react';
import {
    Filter,
    ReferenceInput,
    SearchInput,
    SelectInput
} from 'react-admin';


const RatingFilter = function(props) {
    return (
        <Filter {...props}>
            <SearchInput source="q" alwaysOn />
            <ReferenceInput label="user" source="user" reference="user" allowEmpty>
                <SelectInput optionText="id" />
            </ReferenceInput>
            <ReferenceInput label="experience" source="experience" reference="experience" allowEmpty>
                <SelectInput optionText="id" />
            </ReferenceInput>
        </Filter>
    );
};

export default RatingFilter;
