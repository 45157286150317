import React from 'react';
import { Edit } from 'react-admin';
import RatingTitle from './RatingTitle';
import RatingEditActions from './RatingEditActions';
import RatingForm from './RatingForm';

const RatingEdit = (props) => (
    <Edit actions={<RatingEditActions />} title={<RatingTitle />} {...props}>
        <RatingForm {...props } />
    </Edit>
);

export default RatingEdit;
