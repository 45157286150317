import React, {useCallback, useEffect, useState} from "react";
import {SelectArrayInput, useDataProvider, useVersion} from "react-admin";

const SelectLineArrayInput = (props) => {
    const dataProvider = useDataProvider();
    const version = useVersion();
    const [choices, setChoices] = useState([]);

    const fetchLines = useCallback(async () => {
        const response = await dataProvider.getList('line', {
            sort: { field: 'key', order: 'ASC' },
        });

        const lines = response && response.data ? response.data : [];

        setChoices(
            lines.map(line => ({ 
                id: line, 
                name: line, 
            }))
        );
    }, [dataProvider]);

    useEffect(() => {
        fetchLines();
    }, [version, fetchLines]);

    return (
        <SelectArrayInput
            choices={choices}
            translateChoices={false}
            {...props}
        />
    );
};

SelectLineArrayInput.defaultProps = {
    source: 'lines',
};

export default SelectLineArrayInput;
