import React from 'react';
import { Link } from 'react-admin';
import UserFullnameField from './UserFullnameField';

const UserLinkField = function(props) {
    return (
        <Link to={`/user/${props.record.id}`}>
            <UserFullnameField {...props} />
        </Link>
    );
}

UserLinkField.defaultProps = {
    source: 'user.id',
    addLabel: true,
};

export default UserLinkField;
