import React, { Fragment } from 'react';
import { BulkDeleteButton } from 'react-admin';
import RejectBulkActionButton from "./RejectBulkActionButton";
import ApproveBulkActionButton from "./ApproveBulkActionButton";
import CommentsOffBulkActionButton from "./CommentsOffBulkActionButton";
import CommentsOnBulkActionButton from "./CommentsOnBulkActionButton";

const ExperienceBulkActionButtons = (props) => (
    <Fragment>
        <CommentsOffBulkActionButton {...props} />
        <CommentsOnBulkActionButton {...props} />
        <RejectBulkActionButton {...props} />
        <ApproveBulkActionButton {...props} />
        <BulkDeleteButton {...props} />
    </Fragment>
);

export default ExperienceBulkActionButtons;
