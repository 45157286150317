import React from 'react';
import enums from '../experience/enums';
import {
    Filter,
    ReferenceInput,
    SearchInput,
    SelectInput
} from 'react-admin';
import QuickFilter from "../../component/QuickFilter";

const PromoFilter = function(props) {
    return (
        <Filter {...props}>
            <SearchInput source="q" alwaysOn />
            <QuickFilter label="PROMO" source="type" defaultValue={ "PROMO" } />
            <SelectInput source="status" choices={ enums.experienceStatus } />
            <SelectInput source="district" choices={ enums.bcnDistrict } />
            <ReferenceInput label="Provider" source="provider" reference="provider" allowEmpty>
                <SelectInput optionText="name" />
            </ReferenceInput>
        </Filter>
    );
};

export default PromoFilter;
