import React from 'react';
import PropTypes from 'prop-types';
import ThumbUp from '@material-ui/icons/ThumbUp';
import {
    Button,
    useUpdateMany,
    useNotify,
    useRedirect,
    useTranslate,
    useUnselectAll,
    CRUD_UPDATE_MANY,
} from 'react-admin';

const CommentsOffBulkActionButton = ({ selectedIds, redirect }) => {
    const notify = useNotify();
    const translate = useTranslate();
    const redirectTo = useRedirect();
    const unselectAll = useUnselectAll('experience');

    const [action, { loading }] = useUpdateMany('experience', selectedIds, {
        commentsEnabled: false
    }, {
        action: CRUD_UPDATE_MANY,
        undoable: true,
        onSuccess: () => {
            notify(translate('experience.notification.commentsOffSuccess'), 'info', {}, true);
            redirectTo('/experience/' + redirect);
            unselectAll();
        },
        onFailure: () => {
            notify(translate('experience.notification.commentsOffError'), 'warning');
        },
    });

    return (
        <Button label={translate('experience.field.commentsOff')} onClick={action} disabled={loading}>
            <ThumbUp />
        </Button>
    );
};

CommentsOffBulkActionButton.propTypes = {
    redirect: PropTypes.string.isRequired,
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default CommentsOffBulkActionButton;
