import React from 'react';
import { useTranslate } from "react-admin";

const SettingTitle = () => {
    const translate = useTranslate();

    return (
        <span>{translate('option.field.title')}</span>
    );
};

export default SettingTitle;
